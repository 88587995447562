import { TouchableOpacity, StyleSheet } from "react-native";

import Theme from "../constants/Theme";
import Colors from "../constants/Colors";
import { Trip } from "../types/Trip";
import LoadingIndicator from "./LoadingIndicator";
import { Text, View } from "./Themed";
import TripCard from "./TripCard";

type TripListProps = {
  trips: Trip[] | undefined;
  onPress: (trip: Trip) => void;
  header?: string;
  headerEmpty?: string;
};

export default function TripList({
  trips,
  header = "",
  headerEmpty = "",
  onPress,
}: TripListProps) {
  const headerText = trips && trips.length === 0 ? headerEmpty : header;

  return (
    <View>
      <Text style={styles.header}>{headerText}</Text>
      {!trips && <LoadingIndicator />}
      {trips && trips.length > 0 && (
        <>
          {trips.map((t) => (
            <TouchableOpacity
              key={t.id}
              style={{ marginBottom: 8 }}
              onPress={() => onPress(t)}
            >
              <TripCard trip={t} />
            </TouchableOpacity>
          ))}
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  header: {
    ...Theme.h2,
    marginBottom: 8,
  },
  upcomingTripsHeader: {
    ...Theme.h2,
    color: Colors.theme.primary,
  },
});
