import * as React from "react";
import { StyleSheet } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useNavigation } from "@react-navigation/native";
import { useForm, Controller, FieldError } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import Style from "../../constants/CreateAccountStyles";
import Theme from "../../constants/Theme";
import { RootState } from "../../store";
import TextInput from "../../components/TextInput";
import { Button } from "../../components/Button";
import { BaseHeader } from "../../components/BaseHeader";
import { View, Text } from "../../components/Themed";
import {
  CreateAccountNavigationProp,
  CreateAccountScreenProps,
  LoginScreenProps,
} from "../../navigation/types";
import { BasicInfo, setBasicInfo } from "../../store/createAccount";
import useCheckInit from "../../hooks/useCheckInit";
import PhotoInput from "../../components/PhotoInput";
import { required } from "../../util/formRules";

export default function BasicInfoScreen({
  navigation,
}: CreateAccountScreenProps) {
  useCheckInit();
  const basicInfo = useSelector(
    (state: RootState) => state.createAccount.basicInfo
  );
  const disableEmail = useSelector(
    (state: RootState) => state.createAccount.disableEmail
  );
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: basicInfo,
  });

  const submit = (data: BasicInfo) => {
    dispatch(setBasicInfo(data));
    navigation.push("SetPassword");
  };

  return (
    <KeyboardAwareScrollView
      style={Style.page}
      contentContainerStyle={Style.pageContent}
    >
      <View style={Style.headerContainer}>
        <Text style={Style.header}>Basic Information</Text>
        <Text style={Style.subheading}>Tell us a little about yourself.</Text>
        <View style={Style.spacer} />
        <Controller
          name="photo"
          control={control}
          rules={required}
          render={({ field }) => (
            <PhotoInput
              value={field.value}
              onChange={field.onChange}
              label="Add a photo"
              error={errors.photo as FieldError | undefined}
            />
          )}
        />
      </View>
      <View style={styles.spacer} />
      <Controller
        name="firstName"
        control={control}
        rules={required}
        render={({ field }) => (
          <TextInput label="First Name" {...field} error={errors.firstName} />
        )}
      />
      <View style={styles.spacer} />
      <Controller
        name="lastName"
        control={control}
        rules={required}
        render={({ field }) => (
          <TextInput label="Last Name" {...field} error={errors.lastName} />
        )}
      />
      <View style={styles.spacer} />
      <Controller
        name="address1"
        control={control}
        rules={required}
        render={({ field }) => (
          <TextInput label="Address 1" {...field} error={errors.address1} />
        )}
      />
      <View style={styles.spacer} />
      <Controller
        name="address2"
        control={control}
        render={({ field }) => (
          <TextInput
            label="Address 2"
            optional
            {...field}
            error={errors.address2}
          />
        )}
      />
      <View style={styles.spacer} />
      <Controller
        name="city"
        control={control}
        rules={required}
        render={({ field }) => (
          <TextInput label="City" {...field} error={errors.city} />
        )}
      />
      <View style={styles.spacer} />
      <Controller
        name="state"
        control={control}
        rules={required}
        render={({ field }) => (
          <TextInput label="State" {...field} error={errors.state} />
        )}
      />
      <View style={styles.spacer} />
      <Controller
        name="phone"
        control={control}
        rules={required}
        render={({ field }) => (
          <TextInput label="Phone" {...field} error={errors.phone} />
        )}
      />
      <View style={styles.spacer} />
      <Controller
        name="email"
        control={control}
        rules={required}
        render={({ field }) => (
          <TextInput
            label="Email"
            {...field}
            editable={!disableEmail}
            error={errors.email}
          />
        )}
      />
      <View>
        <Button
          title="Set Password"
          onPress={handleSubmit(submit)}
          style={styles.nextBtn}
        />
        <View style={Style.spacer} />
      </View>
    </KeyboardAwareScrollView>
  );
}

export function CreateAccountScreenHeader() {
  const navigation = useNavigation<CreateAccountNavigationProp>();
  const onBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate("Login");
    }
  };

  return <BaseHeader leftIcon="back" onBack={onBack} />;
}

const styles = StyleSheet.create({
  container: {
    ...Theme.page,
  },
  contentContainer: {
    alignItems: "center",
  },
  spacer: {
    height: 20,
    backgroundColor: "none",
  },
  nextBtn: {
    marginLeft: "auto",
    marginTop: 24,
  },
});
