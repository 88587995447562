import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useMutation, useQueryClient } from "react-query";

import Theme from "../constants/Theme";
import { api } from "../api";
import { BaseHeader } from "../components/BaseHeader";
import TripDetailsForm from "../components/TripDetailsForm";
import { View } from "../components/Themed";
import TripChangeForm from "../components/TripChangeForm";
import { useTrip, useTripPilots } from "../hooks/trips";
import { ApproveTripDetailsScreenProps } from "../navigation/types";
import LoadingIndicator from "../components/LoadingIndicator";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { UpdateTripDto } from "../types/Trip";
import { useUser } from "../hooks";

export default function ApproveTripDetailsScreen({
  route,
  navigation,
}: ApproveTripDetailsScreenProps) {
  const { user } = useUser();
  const { tripId } = route.params;
  const { trip, isLoading: tripLoading } = useTrip(tripId);
  const { tripPilots, isLoading: tripPilotsLoading } = useTripPilots(tripId);
  const queryClient = useQueryClient();
  const acceptMutation = useMutation(
    (dto: UpdateTripDto) => {
      if (!trip) throw new Error();
      return api.acceptTrip(trip.id, dto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["trips", tripId]);
        queryClient.invalidateQueries( ['notifs', user?.id] );
      },
    }
  );

  const isLoading = tripLoading || tripPilotsLoading;

  const onSave = async (d: UpdateTripDto) => {
    try {
      await acceptMutation.mutateAsync(d);
      navigation.navigate("TripScheduled", { tripId });
    } catch (err) {
      // TODO: Toast message here.
    }
  };

  const onCancel = () => {
    navigation.push( "ConfirmCancelTrip", { tripId } );
  };

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {trip && tripPilots && (
        <TripChangeForm
          trip={trip}
          tripPilots={tripPilots}
          saveBtnLabel="Approve Trip Details"
          cancelBtnLabel="I'm no longer available for this trip."
          onSave={onSave}
          onCancel={onCancel}
          disableBtns={acceptMutation.isLoading}
        />
      )}
    </>
  );
}

export function ApproveTripDetailsScreenHeader({
  navigation,
}: NativeStackHeaderProps) {
  const onBack = () => {
    if (navigation.canGoBack()) navigation.goBack();
    else navigation.navigate("Home");
  };

  return (
    <BaseHeader title="Approve Trip Details" leftIcon="back" onBack={onBack} />
  );
}
