export class InternalServerError extends Error {
  response: any;
  constructor(message = "", response?: any) {
    super(message);
    this.response = response;
  }
}

export class ClientRequestError extends Error {
  response: any;
  constructor(message = "", response?: any) {
    super(message);
    this.response = response;
  }
}

export class UnauthenticatedError extends ClientRequestError {
  constructor(message = "", response?: any) {
    super(message, response);
  }
}

export class ForbiddenError extends ClientRequestError {
  constructor(message = "", response?: any) {
    super(message, response);
  }
}

export class NotFoundError extends ClientRequestError {
  constructor(message = "", response?: any) {
    super(message, response);
  }
}
