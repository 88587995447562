import { StyleSheet } from "react-native";
import { useQuery } from "react-query";

import { api } from "../../api";
import { BaseHeader } from "../../components/BaseHeader";
import { Button } from "../../components/Button";
import LoadingIndicator from "../../components/LoadingIndicator";
import PreviewPicture from "../../components/PreviewPicture";
import ScreenContainer from "../../components/ScreenContainer";
import { Text, View } from "../../components/Themed";
import Theme from "../../constants/Theme";
import { useUser } from "../../hooks";
import { useMember } from "../../hooks/useUser";
import { ViewAircraftScreenProps } from "../../navigation/types";
import { MemberRole } from "../../types/User";

export default function ViewAircraftScreen({
    route,
    navigation,
}: ViewAircraftScreenProps) {
    const { user } = useMember();
    const aircraftId = route.params.id;
    const { data: aircraft, isLoading } = useQuery(["aircraft", aircraftId], () =>
        api.getAircraft(aircraftId)
    );

    const removeAircraft = () => {
        navigation.push("ConfirmDelete", { id: aircraftId });
    };

    const editAircraft = () => {
        navigation.push("EditAircraft", { id: aircraftId });
    };

    return (
        <ScreenContainer>
            {isLoading && <LoadingIndicator />}
            {aircraft && (
                <View>
                    <Text style={styles.header}>{aircraft.tailNumber}</Text>
                    <Text style={styles.subheader}>
                        This aircraft is currently assigned to your organization.
                    </Text>
                    {aircraft.pilot && (
                        <PreviewPicture
                            url={aircraft.pilot.photoUrl}
                            title={`${aircraft.pilot.firstName} ${aircraft.pilot.lastName}`}
                            subtitle="Assigned Pilot"
                        />
                    )}
                    <Text style={styles.label}>
                        Home Airport:{" "}
                        <Text style={styles.bold}>{aircraft.homeAirportCode || "N/A"}</Text>
                    </Text>
                    <Text style={styles.label}>
                        Current Airport:{" "}
                        <Text style={styles.bold}>
                            {aircraft.currentAirportCode || "N/A"}
                        </Text>
                    </Text>
                    { ( user && [MemberRole.ADMIN, MemberRole.SUPERADMIN].includes(user.role) && (
                        <Button
                            title="Edit Aircraft"
                            style={styles.editBtn}
                            onPress={editAircraft}
                        />
                    ) ) || null }
                </View>
            )}
            { ( user && user.role !== MemberRole.GENERAL && (
                <Button
                    title="Remove Aircraft"
                    color="danger"
                    style={styles.removeBtn}
                    variant="large"
                    onPress={removeAircraft}
                />
            ) ) || null }
        </ScreenContainer>
    );
}

export function ViewAircraftScreenHeader() {
    return (
        <BaseHeader title="Aircraft" leftIcon="back" rightIcon="notifications" />
    );
}

const styles = StyleSheet.create({
    header: {
        ...Theme.h1,
        textAlign: "center",
    },
    subheader: {
        maxWidth: 240,
        marginHorizontal: "auto",
        textAlign: "center",
        marginTop: 8,
        marginBottom: 16,
    },
    label: {
        marginTop: 8,
    },
    bold: {
        fontWeight: "600",
    },
    editBtn: {
        alignSelf: "center",
        marginTop: 24,
    },
    removeBtn: {
        marginTop: "auto",
        alignSelf: "center",
    },
});
