import { GooglePlaceDetail } from "react-native-google-places-autocomplete";

// https://developers.google.com/maps/documentation/javascript/places-autocomplete#get-place-information

export type Address = {
  city: string;
  state: string;
  country: string;
  street: string;
  zip: string;
};

/**
 *
 * @param {Object} place Returned object from Google Autocomplete.
 */
export function getAddressFromPlace(place: GooglePlaceDetail) {
  // Street and postcode are built up from multiple address_components,
  // so need to define them before looping.
  let street = "";
  let postcode = "";

  let address: Partial<Address> = {}; // return object

  // place.address_components are google.maps.GeocoderAddressComponent objects
  // which are documented at http://goo.gle/3l5i5Mr
  for (const component of place.address_components) {
    const componentType = component.types[0];

    switch (componentType) {
      case "street_number": {
        street = `${component.long_name} ${street}`;
        break;
      }

      case "route": {
        street += component.short_name;
        break;
      }

      case "postal_code": {
        postcode = `${component.long_name}${postcode}`;
        break;
      }

      case "postal_code_suffix": {
        postcode = `${postcode}-${component.long_name}`;
        break;
      }

      // aka, city
      case "locality":
        address.city = component.long_name;
        break;

      // aka, state
      case "administrative_area_level_1": {
        address.state = component.short_name;
        break;
      }
      case "country":
        address.country = component.long_name;
        break;
    }
  }
  address.street = street;
  address.zip = postcode;

  /*
  latlng coordinates:
  const { lat, lng } = {
    lat: place.geometry.location.lat,
    lng: place.geometry.location.lng,
  };
  address.latitude = lat;
  address.longitude = lng;
  */

  return address as Address;
}

export const getFresnoCoordinates = () => ({ lat: 36.7378, lng: -119.7871 });
