import useCachedResources from "./useCachedResources";
import useColorScheme from "./useColorScheme";
import useVersion from "./useVersion";
import useUser from "./useUser";
import { useTrip, useTripPilots } from "./trips";
import useCheckInit from "./useCheckInit";
import { useAuth } from "../providers/auth";
import {
  // en,
  // nl,
  // de,
  // pl,
  // pt,
  enGB,
  registerTranslation,
} from 'react-native-paper-dates'
// registerTranslation('en', en)
// registerTranslation('nl', nl)
// registerTranslation('pl', pl)
// registerTranslation('pt', pt)
// registerTranslation('de', de)
registerTranslation('en-GB', enGB)
export {
  useCachedResources,
  useColorScheme,
  useVersion,
  useAuth,
  useUser,
  useTrip,
  useTripPilots,
  useCheckInit,
};
