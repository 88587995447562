import * as React from "react";
import { StyleSheet } from "react-native";
import { useForm } from "react-hook-form";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import parse from "date-fns/parse";

import { Text, View } from "../../components/Themed";
import Theme from "../../constants/Theme";
import { RootState } from "../../store";
import { Button } from "../../components/Button";
import { styles } from "../RequestTripScreen";
import { api } from "../../api";
import { DetailFormData, RequestTripState, clearRequestTrip} from "../../store/requestTrip";
import { SendTripRequestPayload } from "../../api/api";
import { RequestTripScreenProps } from "../../navigation/types";
import { useUser } from "../../hooks";
import CtlTextInput from "../../components/ControlledInputs/CtlTextInput";

export default function MoreDetailsScreen({
  navigation,
}: RequestTripScreenProps) {
  const { user } = useUser();
  const requestTrip = useSelector((state: RootState) => state.requestTrip);
  const { control, handleSubmit } = useForm({
    defaultValues: requestTrip.moreDetails,
  });

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (d: SendTripRequestPayload) => api.sendTripRequest(d),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users", user?.id, "trips"]);
        queryClient.invalidateQueries( ['notifs', user?.id] );
      },
    }
  );
  const [errMsg, setErrMsg] = React.useState("");

  const makeTripRequestPayload = (d: RequestTripState, formProps: any) => {
    const detailToDto = (data: DetailFormData) => ({
      ...data,
      
      date: data.date ? parse(data.date, "MM/dd/yyyy", new Date()).toISOString() : "",
      
    });

    return {
      ...d.tripInfo,
      notes: formProps.notes,
      bags: parseInt(formProps.bags) || 0,
      passengers: parseInt(formProps.passengers) || 0,
      departureInfo: detailToDto(d.departureInfo),
      arrivalInfo: detailToDto(d.arrivalInfo),
      returnInfo: detailToDto(d.returnInfo),
    };
  };

  const submit = async ( props: any ) => {

    try {
      const payload = makeTripRequestPayload(requestTrip, props);
      await mutation.mutateAsync(payload);
      dispatch( clearRequestTrip() );
      navigation.push("Success");
      
      

    } catch (err) {
      setErrMsg("There was a problem creating this trip request.");
    }
  };

  return (
    <KeyboardAwareScrollView
      style={Theme.page}
      contentContainerStyle={Theme.pageContent}
    >
      <View>
        <Text style={styles.header}>
          Please provide some more{"\n"}details for the pilot.
        </Text>
        <View style={styles.spacer} />
        <View style={pageStyles.numberInputs}>
          <CtlTextInput
            name="passengers"
            control={control}
            containerStyle={pageStyles.input}
            keyboardType="numeric"
            label="Passengers"
            optional
            placeholder="3"
          />
          <View style={pageStyles.numberInputsSpacer} />
          <CtlTextInput
            name="bags"
            control={control}
            containerStyle={pageStyles.input}
            keyboardType="numeric"
            label="Bags"
            optional
            placeholder="3"
          />
        </View>
        <View style={styles.spacer} />
        <CtlTextInput
          name="notes"
          control={control}
          label="Notes for the pilot:"
          placeholder="We will be flying with our dog, etc."
          optional
          multiline
          numberOfLines={5}
        />
        <Text style={Theme.errorMessage}>{errMsg}</Text>
      </View>
      <View>
        <Button
          title="Send Trip Request"
          color="success"
          disabled={mutation.isLoading}
          onPress={handleSubmit(submit)}
        />
        <View style={styles.spacer} />
      </View>
    </KeyboardAwareScrollView>
  );
}

const pageStyles = StyleSheet.create({
  numberInputs: {
    flexDirection: "row",
  },
  input: {
    flex: 8,
  },
  numberInputsSpacer: {
    flex: 1,
  },
});
