import {
	DrawerContentComponentProps,
	DrawerContentScrollView,
	DrawerItem
} from "@react-navigation/drawer";
import {
	StyleSheet
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { View, Text } from "../components/Themed";
import { useUser } from "../hooks";
import Colors from "../constants/Colors";
import { useNotifs } from "../hooks/notifications";
import { useMutation, useQueryClient } from "react-query";
import { api } from "../api";
import { useNavigation, CommonActions } from "@react-navigation/native";
import * as React from 'react';

export function NotificationDrawerContent({ ...props }: DrawerContentComponentProps) {
	const queryClient = useQueryClient();
	const { user } = useUser();
	const { notifs: allNotifs } = useNotifs();
	const newNotifs = allNotifs?.filter(n => !n.read);
	const readNotifs = allNotifs?.filter(n => n.read);
	const navigation = useNavigation();

	const clearNotificationMutation = useMutation(
		(id: string) => api.readNotification(id),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['notifs', user?.id])
			}
		}
	)

	const onPressNotif = (id: string, context: string) => {
		try {
			const contextParsed = JSON.parse(context);
			clearNotificationMutation.mutate(id)
			// *Emit dispatch event, first matching screen will catch
			navigation.dispatch(
				CommonActions.navigate({
					name: 'Trip',
					params: { tripId: contextParsed.tripId }
				})
			)
		}
		catch (err) {
			throw err;
		}
	}

	// *Dont't need to clear notification if already read
	const onPressReadNotif = (id: string, context: string) => {
		try {
			const contextParsed = JSON.parse(context);
			// *Emit dispatch event, first matching screen will catch
			navigation.dispatch(
				CommonActions.navigate({
					name: 'Trip',
					params: { tripId: contextParsed.tripId }
				})
			)
		}
		catch (err) {
			throw err;
		}
	}

	const markAllRead = async () => {
		if (newNotifs) {
			for (let i = 0; i < newNotifs?.length; i++) {
				try {
					const notifIDToSet = newNotifs[i].id?.toString();
					if (notifIDToSet) await clearNotificationMutation.mutateAsync(notifIDToSet)

				}
				catch (err) {
					throw err;
				}
			}
		}
	}

	return (
		<DrawerContentScrollView {...props} style={styles.container}>

			<View style={styles.headerContainer}>
				<Text style={styles.headerText}>Notifications</Text>
				{(newNotifs && (newNotifs?.length > 0)) && (
					<Text onPress={markAllRead} style={styles.markAllRead}>Mark all as read</Text>
				)}
			</View>

			{(newNotifs && (newNotifs?.length > 0)) && (
				<>
					{newNotifs?.map(({ id, actorName, action, context }: any) => (
						<DrawerItem
							key={id}
							icon={() => <Ionicons name="person-circle" size={40} color="#fff" />}
							label={() => <Text>
								<span style={{ color: Colors.theme.lightBlue }}>{actorName}: </span>
								<span style={{ color: "#fff" }}>{action} </span>
								<Ionicons name="arrow-forward" size={15} color={Colors.theme.lightBlue} />
							</Text>}
							onPress={() => onPressNotif(id, context)}
						/>
					))}
				</>
			)}
			{(newNotifs && (newNotifs?.length <= 0)) && <Text style={styles.noNewText}>You have no new notifications</Text>}

			<View style={styles.headerContainer}>
				<Text style={styles.headerText}>Past Notifications</Text>
			</View>

			{(readNotifs && (readNotifs?.length > 0)) && (
				<>
					{readNotifs?.map(({ id, actorName, action, context }: any) => (
						<DrawerItem
							key={id}
							icon={() => <Ionicons name="person-circle" size={40} color="#fff" />}
							label={() => <Text>
								<span style={{ color: Colors.theme.lightBlue }}>{actorName}: </span>
								<span style={{ color: "#fff" }}>{action} </span>
								<Ionicons name="arrow-forward" size={15} color={Colors.theme.lightBlue} />
							</Text>}
							onPress={() => onPressReadNotif(id, context)}
						/>
					))}
				</>
			)}
		</DrawerContentScrollView>
	)
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignContent: "center",
		backgroundColor: Colors.theme.blueishBlack
	},
	image: {
		// flex: 1,
	},
	headerContainer: {
		padding: 12,
		backgroundColor: Colors.theme.blueishBlack,
		alignItems: "flex-end"
	},
	headerText: {
		fontWeight: "600",
		color: "#fff",
		fontSize: 20,
		marginLeft: 8,
	},
	noNewText: {
		textAlign: "center",
		color: '#fff',
		fontSize: 16,
	},
	markAllRead: {
		color: Colors.theme.lightBlue,
		fontSize: 12
	},
});