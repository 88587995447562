import { useNavigation } from "@react-navigation/native";
import { Member, Permissions, User, MemberRole } from "../types/User";

export function hasPermission(
  user: Member | null | undefined,
  perm: keyof Permissions
): boolean {
  if (!user) {
    return false;
  }
  if ([MemberRole.SUPERADMIN].includes(user.role)) {
    return true;
  }
  return user.permissions[perm];
}
