import * as React from "react";
import { useWindowDimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  TabView,
  SceneMap,
  TabBar as DefaultTabBar,
  NavigationState,
  SceneRendererProps,
} from "react-native-tab-view";
import { useQuery } from "react-query";

import { BaseHeader } from "../components/BaseHeader";
import { View, Text } from "../components/Themed";
import PreviewPicture from "../components/PreviewPicture";
import { useUser } from "../hooks";
import { TouchableOpacity } from "react-native-gesture-handler";
import Colors from "../constants/Colors";
import { ZephurVersion } from "../components/ZephurVersion";
import { api } from "../api";
import LoadingIndicator from "../components/LoadingIndicator";
import { User } from "../types/User";
import { ContactsStackNavigationProp } from "../navigation/types";
import ScreenContainer from "../components/ScreenContainer";

const Pilots = () => {
  const { user } = useUser();
  const { data: pilots, isLoading } = useQuery(
    `/pilots/${user?.id}/contractors`,
    () => api.getPilotContractors(user?.id as string),
    { enabled: !!user }
  );
  const navigation = useNavigation<ContactsStackNavigationProp>();

  const viewPilot = (p: User) => {
    navigation.push("ViewUser", { userId: p.id });
  };

  return (
    <ScreenContainer>
      {isLoading && <LoadingIndicator />}
      {pilots && pilots.length === 0 ? (
        <Text>No pilots available.</Text>
      ) : (
        <View>
          {pilots?.map((p) => (
            <TouchableOpacity key={p.id} onPress={() => viewPilot(p)}>
              <PreviewPicture
                url={p.photoUrl}
                title={`${p.firstName} ${p.lastName}`}
                style={{ marginBottom: 16 }}
              />
            </TouchableOpacity>
          ))}
        </View>
      )}
      <ZephurVersion />
    </ScreenContainer>
  );
};

const Organizations = () => {
  const { user } = useUser();
  const { data: orgs, isLoading } = useQuery(
    `/pilots/${user?.id}/organizations`,
    () => api.getPilotOrgs(user?.id as string),
    { enabled: !!user }
  );
  const navigation = useNavigation<ContactsStackNavigationProp>();

  const viewOrg = (id: string) => {
    navigation.push("ViewOrganization", { organizationId: id });
  };

  return (
    <ScreenContainer>
      {isLoading && <LoadingIndicator />}
      {orgs && orgs.length === 0 ? (
        <Text>No organizations available.</Text>
      ) : (
        <View>
          {orgs?.map((org) => (
            <TouchableOpacity key={org.id} onPress={() => viewOrg(org.id)}>
              <PreviewPicture
                url={org.photoUrl}
                title={`${org.name}`}
                style={{ marginBottom: 16 }}
              />
            </TouchableOpacity>
          ))}
        </View>
      )}
      <ZephurVersion />
    </ScreenContainer>
  );
};

const renderScene = SceneMap({
  pilots: Pilots,
  organizations: Organizations,
});

type Route = {
  key: "pilots" | "organizations";
  title: string;
};

const TabBar = (
  props: SceneRendererProps & { navigationState: NavigationState<Route> }
) => {
  return (
    <DefaultTabBar
      {...props}
      style={{ backgroundColor: "#fff" }}
      labelStyle={{
        textTransform: "capitalize",
        color: Colors.theme.subtle,
        fontWeight: "600",
        fontSize: 16,
      }}
      activeColor={Colors.theme.primary}
      indicatorStyle={{ backgroundColor: Colors.theme.primary, height: 4 }}
    />
  );
};

export default function ContactsScreen() {
  const { width } = useWindowDimensions();
  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState<Route[]>([
    { key: "pilots", title: "Pilots" },
    { key: "organizations", title: "Organizations" },
  ]);

  return (
    <TabView
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      renderTabBar={TabBar}
      // Required to ensure tab nav state is maintained on navigation.goBack().
      initialLayout={{ width, height: 0 }}
    />
  );
}

export function ContactsScreenHeader() {
  return (
    <BaseHeader
      title="Contacts"
      leftIcon="menu"
      rightIcon="notifications"
      color="blue"
    />
  );
}
