import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ToastPayload {
	id?: number;
	type: string;
	header: string;
	message: string;
	onClick: Function;
	data?: {};
}

export interface CloseToastPayload {
	id: number;
	index: number;
}

export interface RemoveToastPaylaod {
	id : number;
}

export interface CreatNotificationState {
	initialized: boolean;
	isToastOpen: boolean;
	toastPayload?: ToastPayload;
	toasts: ToastPayload[];
}

const initialState: CreatNotificationState = {
	initialized: false,
	isToastOpen: false,
	toastPayload: undefined,
	toasts: [],
}

const createNotifications = createSlice( {
	name: 'createNotifications',
	initialState,
	reducers: {
		initialize: ( state, action: PayloadAction<ToastPayload> ) => {
			state.toastPayload = action.payload;
			state.initialized = true;
		},
		reset: () => {
			initialState;
		},
		addToast( state, action: PayloadAction<ToastPayload> ) {
			state.toasts.splice( 0, 0, action.payload );
		},
		removeToastById( state, action: PayloadAction<RemoveToastPaylaod> ) {
			const i = state.toasts.findIndex( ( toast ) => toast.id === action.payload.id );
			if ( i === -1 ) return;
			state.toasts.splice( i, 1 );
		},
		closeToast( state, action: PayloadAction<CloseToastPayload> ) {
			if ( !action.payload ) return;

			const { id, index } = action.payload;
			if ( id ) createNotifications.caseReducers.removeToastById( state, action );
		},
		displayToast( state, action: PayloadAction<ToastPayload> ) {
			let { type, header, message, onClick, data } = action.payload;

			if ( !type ) type = "info";
			if ( !header || !message ) {
				switch ( type ) {
					case 'success':
						header = header || 'Success';
						message = message || "action succeeded.";
						break;
					case 'error':
						header = header || 'Error';
						message = message || 'An error occurred.';
						break;
					case 'loading':
						header = header || 'Loading';
						message = message || 'Loading an action.';
						break;
					case 'info':
						header = header || 'Info';
						message = message || 'Application info appears here.';
						break;
				}
			}

			const id = Math.floor( Math.random() * 100000 );
			action.payload.id = id;
			createNotifications.caseReducers.addToast( state, action );
		},
	},
} );

export const {
	initialize,
	reset,
	addToast,
	removeToastById,
	closeToast,
	displayToast
} = createNotifications.actions;
export default createNotifications;