import { TouchableOpacity, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { BaseHeader } from "../components/BaseHeader";
import { Button } from "../components/Button";
import { View, Text } from "../components/Themed";
import Theme from "../constants/Theme";
import {
  AircraftScreenProps,
  AircraftStackNavigationProp,
} from "../navigation/types";
import { ZephurVersion } from "../components/ZephurVersion";
import { Aircraft } from "../types/Aircraft";
import LoadingIndicator from "../components/LoadingIndicator";
import { Ionicons } from "@expo/vector-icons";
import { useMember } from "../hooks/useUser";
import { useOrgAircraft } from "../hooks/useOrgAircraft";
import ScreenContainer from "../components/ScreenContainer";
import { MemberRole } from "../types/User";

type AircraftItemProps = {
  aircraft: Aircraft;
  onPress: () => void;
};
function AircraftItem({ aircraft, onPress }: AircraftItemProps) {
  return (
    <TouchableOpacity style={itemStyles.container} onPress={onPress}>
      <Text style={itemStyles.tailNum}>{aircraft.tailNumber}</Text>
      <Ionicons name="chevron-forward" size={20} />
    </TouchableOpacity>
  );
}

const itemStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 20,
  },
  tailNum: {
    textTransform: "uppercase",
    fontWeight: "600",
    fontSize: 16,
  },
});

export default function AircraftScreen({ navigation }: AircraftScreenProps) {
  const { user } = useMember();
  const { aircraft, isLoading } = useOrgAircraft(user?.organization);

  const viewAircraft = (air: Aircraft) => {
    navigation.push("ViewAircraft", { id: air.id });
  };

  return (
    <ScreenContainer>
      <View>
        <Text style={Theme.h2}>Your Organization's Aircraft</Text>
        {isLoading && <LoadingIndicator />}
        {aircraft &&
          aircraft.map((air) => (
            <AircraftItem
              key={air.id}
              aircraft={air}
              onPress={() => viewAircraft(air)}
            />
          ))}
      </View>
      <ZephurVersion />
    </ScreenContainer>
  );
}

export function AircraftScreenHeader() {
  const navigation = useNavigation<AircraftStackNavigationProp>();
  const { user } = useMember();

  const addNew = () => {
    navigation.push("AddAircraft");
  };

  return (
    <BaseHeader
      title="Aircraft"
      leftIcon="menu"
      rightIcon="notifications"
      renderBelow={() => (
        <>
          { ( user && user.role !== MemberRole.GENERAL && (
            <Button
              title="Add New Aircraft"
              color="lightBlue"
              onPress={addNew}
              style={{ alignSelf: "center" }}
            />
          ) ) || null }
        </>
      )}
    />
  );
}
