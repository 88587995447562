export enum StripePilotStatus {
  CHARGES_DISABLED = "charges_disabled",
  GOOD = "good",
}

export enum StripeMemberStatus {
  GOOD = "good",
  DELETED = "deleted",
  DELINQUENT = "delinquent",
}

export type StripeCheckoutDto = {
	tripId: string;
	totalPrice: number;
};

export type StripeResponseObject = {
  paymentIntent: string;
  ephemeralKey: string;
  customer: string;
  publishableKey: string;
}
