import * as React from "react";
import { StyleSheet } from "react-native";
import * as Linking from "expo-linking";

import { Button } from "../components/Button";
import Style from "../constants/CreateAccountStyles";
import { View, Text } from "../components/Themed";
import LoadingIndicator from "../components/LoadingIndicator";
import Theme from "../constants/Theme";
import { api } from "../api";
import { BaseHeader } from "../components/BaseHeader";
import { useUser } from "../hooks";
import { StripeMemberStatus, StripePilotStatus } from "../types/Stripe";
import { UserType } from "../types/User";
import { StripeScreenProps } from "../navigation/types";
import { useNavigation } from "@react-navigation/native";
import ScreenContainer from "../components/ScreenContainer";

type PilotStripeProps = {
  status: StripePilotStatus;
};

function PilotStripe({ status }: PilotStripeProps) {
  const navigation = useNavigation();
  const [url, setUrl] = React.useState("");
  const { user } = useUser();

  const shouldCreateUrl = status === StripePilotStatus.CHARGES_DISABLED;

  const shouldShowLogin = status === StripePilotStatus.GOOD;

  const goToStripe = () => {
    Linking.openURL(url);
  };

  const createUrl = async () => {
    try {
      const { url } = await api.createStripeUrl();
      setUrl(url);
    } catch (err) {
      console.error("Error creating stripe url", err);
    }
  };

  const goToStripeLogin = async () => {
    const logInUrl = await api.createLoginLink();
    Linking.openURL( logInUrl as string );
  }

  React.useEffect( () => {
	if ( shouldCreateUrl ) {
	createUrl();
	}
  }, [shouldCreateUrl]);

  if (shouldCreateUrl) {
    return (
      <>
        <Text style={styles.header}>Charges Disabled</Text>
        <Text style={styles.subheading}>
          Before you can accept payments, you need to set up a Stripe account.
        </Text>
        <View style={Theme.spacer} />
        <View style={Theme.spacer} />
        {!!url && (
          <Button
            title="Go To Stripe"
            onPress={goToStripe}
            variant="large"
            color="lightBlue"
          />
        )}
      </>
    );
  }

  return (
    <>
      <Text style={styles.header}>Good Standing</Text>
      <Text style={styles.subheading}>
        Your Stripe account is currently in good standing.
      </Text>
      <View style={Theme.spacer} />
      <Button
        title="Go To Stripe Login"
        onPress={goToStripeLogin}
        variant='large'
        color='success'
      />
    </>
  );
}

type MemberStripeProps = {
  status: StripeMemberStatus;
};

function MemberStripe({ status }: MemberStripeProps) {
  if (status === StripeMemberStatus.DELINQUENT) {
    return (
      <>
        <Text style={styles.header}>Delinquent</Text>
        <Text style={styles.subheading}>
          Your organization's account has one or more unpaid invoices, and is
          currently delinquent.
        </Text>
      </>
    );
  }

  return (
    <>
      <Text style={styles.header}>Good Standing</Text>
      <Text style={styles.subheading}>
        Your organization's account is currently in good standing.
      </Text>
    </>
  );
}

export default function StripeScreen({ route }: StripeScreenProps) {
  // const { action } = route.params;
  const action = "";
  const { user } = useUser();
  const [status, setStatus] = React.useState<
    StripePilotStatus | StripeMemberStatus | null
  >(null);
  const [errMsg, setErrMsg] = React.useState("");

  React.useEffect(() => {
    (async function () {
      try {
        const { status } = await api.initStripe();
        setStatus(status);
      } catch (err) {
        setErrMsg("There was a problem getting this stripe information.");
      }
    })();
  }, []);

  return (
    <ScreenContainer>
      <View style={styles.container}>
        {(!user || !status) && <LoadingIndicator />}

        <Text style={Theme.errorMessage}>{errMsg}</Text>

        {status && user && user.type === UserType.MEMBER ? (
          <MemberStripe status={status as StripeMemberStatus} />
        ) : (
          <PilotStripe status={status as StripePilotStatus} />
        )}
      </View>
    </ScreenContainer>
  );
}

export function StripeScreenHeader() {
  const { user } = useUser();
  
  return <>
    {
      ( user && user.type === UserType.PILOT && user.firstLogin ) ?
      <BaseHeader title="Stripe" leftIcon="logout" />
      :
      <BaseHeader title="Stripe" leftIcon="menu" />
    }
  </>
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  header: {
    ...Style.header,
  },
  subheading: {
    ...Style.subheading,
  },
});
