import * as React from "react";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { useMutation, useQueryClient } from "react-query";

import { api } from "../../api";
import { BaseHeader } from "../../components/BaseHeader";
import LoadingIndicator from "../../components/LoadingIndicator";
import TripChangeForm, { OnSaveHandler } from "../../components/TripChangeForm";
import { useTripChangeRequest, useTripPilots } from "../../hooks/trips";
import { ReviewTripDetailsScreenProps } from "../../navigation/types";
import { useUser } from "../../hooks";

export default function ReviewChangeRequestScreen({
  route,
  navigation,
}: ReviewTripDetailsScreenProps) {
  const { user } = useUser();
  const { tripId } = route.params;
  const { trip, isLoading: tripLoading } = useTripChangeRequest(tripId);
  const { tripPilots, isLoading: tripPilotsLoading } = useTripPilots(tripId);
  const [errMsg, setErrMsg] = React.useState("");
  const queryClient = useQueryClient();
  const approveChangesMutation = useMutation(
    () => api.approveChangeRequest(tripId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["trips", tripId]);
        queryClient.invalidateQueries( ['notifs', user?.id] );
      },
    }
  );

  const isLoading = tripLoading || tripPilotsLoading;

  const approveChanges: OnSaveHandler = async () => {
    try {
      await approveChangesMutation.mutateAsync();
      navigation.navigate("Trip", { tripId });
    } catch (err) {
      setErrMsg("There was an error approving these changes.");
    }
  };

  const onCancel = () => {
    navigation.push( "ConfirmCancelTrip", { tripId } );
  }

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {trip && tripPilots && (
        <TripChangeForm
          trip={trip}
          tripPilots={tripPilots}
          saveBtnLabel="Approve Changes"
          cancelBtnLabel="I'm no longer available for this trip."
          onSave={approveChanges}
          onCancel={onCancel}
          errMsg={errMsg}
          disableBtns={approveChangesMutation.isLoading}
        />
      )}
    </>
  );
}

export function ReviewChangeRequestScreenHeader({
  route,
  navigation,
}: NativeStackHeaderProps) {
  // No way to type-infer header props.
  const { tripId } = route.params as any;

  const onBack = () => {
    if (navigation.canGoBack()) navigation.goBack();
    else navigation.navigate("Trip", { tripId });
  };

  return <BaseHeader title="Edit" leftIcon="back" onBack={onBack} />;
}
