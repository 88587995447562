import { Image, TextStyle, StyleSheet } from "react-native";

import useVersion from "../hooks/useVersion";
import { View, Text } from "./Themed";

export function ZephurVersion(props: { textStyle?: TextStyle }) {
  const version = useVersion();
  return (
    <View style={styles.container}>
      <Image
        source={require("../assets/images/logo_small.png")}
        style={styles.image}
      />
      <Text style={props.textStyle}>Version {version}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: "auto",
    justifyContent: "flex-end",
    alignSelf: "center",
    alignItems: "center",
    padding: 20,
    backgroundColor: "none",
  },
  image: {
    width: 120,
    height: 60,
    resizeMode: "contain",
  },
});
