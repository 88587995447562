import * as React from "react";
import { useForm } from "react-hook-form";
import { StyleSheet, Modal, TouchableHighlight, Pressable, TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { useRef, useEffect } from "react";
import { BaseHeader } from "../components/BaseHeader";
import { View, Text } from "../components/Themed";
import { ZephurVersion } from "../components/ZephurVersion";
import { useUser } from "../hooks";
import { HomeScreenProps } from "../navigation/types";
import { required } from "../util/formRules";
import Theme from "../constants/Theme";
import { Button } from "../components/Button";
import { UserType } from "../types/User";
import CtlTextInput from "../components/ControlledInputs/CtlTextInput";
import { Trip, TripStatus, PilotStatus } from "../types/Trip";
import TripList from "../components/TripList";
import { useSubcontractorTrips, useTrips } from "../hooks/trips";
import ScreenContainer from "../components/ScreenContainer";
import { getDeparture } from "../util/trips";
import { useMutation } from "react-query";
import { api } from "../api";
import { decode } from "base64-arraybuffer";
import { base64UrlToBase64 } from "../util/utils";

type ContactUsData = {
  name: string;
  email: string;
  message: string;
};

export default function HomeScreen({ navigation }: HomeScreenProps) {
  const { user } = useUser();
  const { trips: allTrips } = useTrips();
  const isPilot = user?.type === UserType.PILOT;
  const { control, handleSubmit, reset } = useForm<ContactUsData>();
  const submitContactMutation = useMutation((d: ContactUsData) => api.contactUs(d));
  const [modalOpen, setModalOpen] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");
  const validStatuses = [TripStatus.PENDING, TripStatus.ACCEPTED, TripStatus.IN_PROGRESS, TripStatus.BOOKED, TripStatus.PENDING_PAYMENT];

  const trips = allTrips?.filter((t) => validStatuses.includes(t.status));

  // For pilots.
  const { subcontractorTrips } = useSubcontractorTrips();

  const filteredSubcontractorTrips = subcontractorTrips?.filter((st) => validStatuses.includes(st.status))

  const subTripIds = subcontractorTrips?.map(subt => subt.id);

  const filteredTrips = trips?.filter(t => {
    if (!isPilot) return true;
    // @ts-ignore
    const tripPilot = t.tripPilots.filter(tp => tp.pilot == user?.id).sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];
    return !subTripIds?.includes(t.id) &&
      [PilotStatus.PENDING, PilotStatus.ACCEPTED].includes(tripPilot.pilotStatus);
  });

  const filteredSortedTrips = filteredTrips?.sort((a, b) => new Date(getDeparture(a)?.date as string).getTime() - new Date(getDeparture(b)?.date as string).getTime());

  const sortedFilteredSubcontractorTrips = filteredSubcontractorTrips?.sort((a, b) => new Date(getDeparture(a)?.date as string).getTime() - new Date(getDeparture(b)?.date as string).getTime());

  const goToTrip = ({ id }: Trip) => {
    navigation.push("Trip", { tripId: id });
  };

  const onSubmit = async ( d : ContactUsData ) => {
    try {
      setErrMsg( "" );
      await submitContactMutation.mutateAsync( d );
      reset( { name: "", email: "", message: ""} );
      setModalOpen( false );
    } catch ( err ) {
      if ( errMsg ) true;
      setErrMsg( "There was an error submitting this email. Please try again later" || err);
    };
  };

  const onModalClose = () => {
    reset( { name : "", email : "", message : "" } )
    setModalOpen( false );
  };

  const requestTrip = () => {
    navigation.push("RequestTripStack", { screen: "TripInfo" });
  };

  // const isFirstRender = useRef(true);

  // const subscribe = async () => {
  //   let registration;
    /*
    if (!("serviceWorker" in navigator)) {
      // Service Worker isn't supported on this browser, disable or hide UI.
      return;
    }

    if (!("PushManager" in window)) {
      // Push isn't supported on this browser, diable or hide UI.
      return;
    }
    registration = await navigator.serviceWorker.register(
      '../public/service-worker.js');
    console.info('Service worker registered!', registration);
  }


  // Next, ask for permission.
  const result = await askPerm();
  console.info('Permission granted!', result);

  // Next, subscribe with PushManager.
  try {
    const subscription = await subscribeToPush(registration);
    const res = await api.sendPushSubscription(subscription);
    console.info("Subscribed!", res);
  } catch (err) {
    console.log("unable to subscribe", err);
  }*/
// };

// const askPerm = async () => {
//   const result = await Notification.requestPermission();
//   console.info('Got Permission result.', result);
//   if (result !== "granted") throw new Error("We don't have permission!");
//   return result;
// }

// const subscribeToPush = async (registration: any) => {
//   const subscibeOptions = {
//     userVisiibleOnly: true,
//     applicationServerKey: decode(base64UrlToBase64('BCu1XF031et_CaO4U6bypWNTU3Gp_uhuBtXfLqEzM5Grfk-mPDcw0soD64nM9C1ZJrvq-YSjF5tuYmeNztth6yA')),
//   };
//   const pushSubscription = await registration.pushManager.subscribe(subscibeOptions);
//   console.info('Recieved push subscription', pushSubscription);
//   return pushSubscription;
// }

// useEffect(() => {
//   if (isFirstRender.current) {
//     setTimeout(() => {
//       subscribe()
//     }, 3000);
//   }
// });

return (
  <ScreenContainer>
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalOpen}
      onRequestClose={() => {
        onModalClose();
      }}
    >
      <View style={styles.outsideModal}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={styles.modalHeader}>
              <Text style={styles.modalText}>Contact Us</Text>
              <TouchableOpacity onPress={() => onModalClose()}>
                <Ionicons name="close" size={24} color="black" />
              </TouchableOpacity>
            </View>
            <View style={styles.inputContainer}>
              <CtlTextInput
                name="name"
                control={control}
                rules={required}
                containerStyle={styles.textInput}
                label="Name"
                placeholder="Name"
              ></CtlTextInput>
            </View>
            <View style={styles.inputContainer}>
              <CtlTextInput
                name="email"
                control={control}
                rules={required}
                containerStyle={styles.textInput}
                label="Email"
                placeholder="Email"
              />
            </View>
            <View style={styles.inputContainer}>
              <CtlTextInput
                name="message"
                control={control}
                rules={required}
                containerStyle={styles.textInput}
                label="Message"
                placeholder="Message"
              />
            </View>
            <Text style={styles.errorText}>{errMsg}</Text>
            <View style={styles.buttonContainer}>
              <Button
                title="Send"
                variant="large"
                onPress={handleSubmit(onSubmit)}
                style={styles.submitButton}
              />
            </View>
          </View>
        </View>
      </View>
    </Modal>
    {user && user.type !== UserType.PILOT && (
      <Button
        title="Request a Trip"
        variant="large"
        color="gold"
        onPress={requestTrip}
        style={{ marginBottom: 24 }}
      />
    )}
    <TripList
      trips={filteredSortedTrips}
      header="Upcoming Trips"
      headerEmpty="No Upcoming Trips"
      onPress={goToTrip}
    />
    {isPilot && (
      <>
        <View style={Theme.spacer} />
        <TripList
          trips={sortedFilteredSubcontractorTrips}
          header="Upcoming Subcontractor Trips"
          headerEmpty="No Upcoming Subcontractor Trips"
          onPress={goToTrip}
        />
      </>
    )}
    <View style={{ height: 20 }} />
    <TouchableHighlight
      style={styles.openButton}
      onPress={() => {
        setModalOpen(true);
      }}
    >
      <Text style={styles.textStyle}>Contact Us</Text>
    </TouchableHighlight>
    <ZephurVersion />
  </ScreenContainer>
);
}

export function HomeScreenHeader() {
  const { user } = useUser();
  // const navigation = useNavigation<HomeScreenNavigationProp>();
  const timeOfDay = () => {
    const date = new Date();
    const hours = date.getHours();
    if (hours < 12) {
      return "morning";
    } else if (hours < 18) {
      return "afternoon";
    } else {
      return "evening";
    }
  };

  return (
    <BaseHeader leftIcon="menu" rightIcon="notifications" color="blue">
      <Text style={headerStyles.text}>
        Good {timeOfDay()},{"\n"}
        {user?.firstName}
      </Text>
    </BaseHeader>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalHeader: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  openButton: {
    backgroundColor: "#E68416",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    textAlign: "center",
  },
  inputContainer: {
    width: "100%",
    marginBottom: 10,
  },
  textInput: {
    backgroundColor: "white",
    alignSelf: "stretch",
    marginTop: 16,
  },
  input: {
    fontSize: 16,
  },
  buttonContainer: {
    width: "100%",
    marginTop: 10,
  },
  submitButton: {
    width: "100%",
  },
  outsideModal: {
    backgroundColor: "rgba(1, 1, 1, 0.2)",
    flex: 1,
  },
  errorText: {
    color: "red",
    textAlign: "center",
    marginTop: 10,
  },
});

const headerStyles = StyleSheet.create({
  text: {
    color: "#fff",
    fontWeight: "700",
    fontSize: 28,
    lineHeight: 28,
    marginTop: 8,
    marginBottom: 4,
  }
});
