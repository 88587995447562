import { Falsy } from "react-native";
import { useQuery } from "react-query";

import { api } from "../api";

export function useOrgAircraft(orgId: string | Falsy) {
  const { data: aircraft, ...rest } = useQuery(
    ["organizations", orgId, "aircraft"],
    () => api.getOrgAircraft(orgId as string),
    { enabled: !!orgId }
  );
  return { aircraft, ...rest };
}
