import * as React from "react";
import { useForm } from "react-hook-form";
import { StyleSheet } from "react-native";
import { useMutation, useQueryClient } from "react-query";

import { api } from "../../api";
import { BaseHeader } from "../../components/BaseHeader";
import { Button } from "../../components/Button";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Text, View } from "../../components/Themed";
import Theme from "../../constants/Theme";
import { AddAircraftScreenProps } from "../../navigation/types";
import { isEmail, required } from "../../util/formRules";
import Colors from "../../constants/Colors";
import useOrgUsers from "../../hooks/useOrgUsers";
import { Member, UserType } from "../../types/User";
import { useUser } from "../../hooks";
import { TailNumberAlreadyExistsError } from "../../api/errors";
import CtlSelectInput from "../../components/ControlledInputs/CtlSelectInput";
import CtlTextInput from "../../components/ControlledInputs/CtlTextInput";
import { EditableAircraft } from "../../types/Aircraft";
import ScreenContainer from "../../components/ScreenContainer";
import { useMember } from "../../hooks/useUser";

type SuccessContentProps = {
  tailNumber: string;
  usedEmail?: boolean;
  email?: string;
  addAnother?: () => void;
};

function SuccessContent({
  tailNumber,
  usedEmail,
  email,
  addAnother,
}: SuccessContentProps) {
  return (
    <>
      <View>
        <Text style={sucStyles.header}>Success</Text>
        <Text style={sucStyles.body}>
          Tail number <Text style={sucStyles.tailNum}>#{tailNumber}</Text> has
          been added to your organization.
        </Text>
        {usedEmail && (
          <Text>
            An invitation email has been sent to {email}. He/she will officially
            be added to this aircraft once they've accepted your invitation!
          </Text>
        )}
      </View>
      <View>
        <Button
          title="Add Another"
          style={sucStyles.addAnotherBtn}
          variant="large"
          onPress={() => addAnother && addAnother()}
        />
        <View style={styles.spacer} />
      </View>
    </>
  );
}

const sucStyles = StyleSheet.create({
  header: {
    ...Theme.h1,
    textAlign: "center",
  },
  body: {
    marginVertical: 16,
    textAlign: "center",
  },
  tailNum: {
    fontWeight: "600",
  },
  addAnotherBtn: {
    alignSelf: "center",
  },
});

export default function AddAircraftScreen({
  navigation,
}: AddAircraftScreenProps) {
  const { user } = useMember();
  const { users } = useOrgUsers(user?.organization);
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (d: EditableAircraft) =>
      api.createAircraft({
        ...d,
        dayRate: parseInt(d.dayRate),
        organizationId: (user as Member).organization || "",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("organizations");
      },
    }
  );
  const { control, handleSubmit, reset, getValues, setError } =
    useForm<EditableAircraft>();
  const [usedEmail, setUsedEmail] = React.useState(false);
  const [screenState, setScreenState] = React.useState<"add" | "success">(
    "add"
  );
  const [errMsg, setErrMsg] = React.useState("");

  const pilotItems =
    users &&
    [{ label: "No Pilot", value: "" }].concat(
      users
        ?.filter((m) => m.type === UserType.PILOT)
        .sort((m1, m2) => m1.firstName.localeCompare(m2.firstName))
        .map((p) => ({
          label: `${p.firstName} ${p.lastName}`,
          value: p.id || "",
        }))
    );

  const addAircraft = async (d: EditableAircraft) => {
    try {
      setErrMsg("");
      await mutation.mutateAsync(d);
      setUsedEmail(!!(d.inviteeEmail && !d.pilotId));
      setScreenState("success");
    } catch (err) {
      if (err instanceof TailNumberAlreadyExistsError) {
        setError("tailNumber", {
          message: "An aircraft with this tail number already exists",
        });
        return;
      }
      setErrMsg(
        "There was an error adding this aircraft. Please try again later."
      );
    }
  };

  const addAnother = () => {
    reset();
    setScreenState("add");
  };

  if (screenState === "success") {
    return (
      <ScreenContainer>
        <SuccessContent
          tailNumber={getValues("tailNumber")}
          usedEmail={usedEmail}
          email={getValues("inviteeEmail")}
          addAnother={addAnother}
        />
      </ScreenContainer>
    );
  }

  return (
    <ScreenContainer>
      <View>
        <Text style={styles.header}>Add Aircraft</Text>
        <Text style={styles.subheader}>
          Please provide the tail number, day rate, airport, and associated
          pilot for this aircraft.
        </Text>
        <View style={styles.spacer} />
        <CtlTextInput
          name="tailNumber"
          control={control}
          rules={required}
          label="Tail #"
        />
        <View style={styles.spacer} />
        <CtlTextInput
          name="dayRate"
          control={control}
          rules={required}
          label="Day Rate (USD)"
          keyboardType="numeric"
          mask="numeric"
          placeholder="$700"
        />
        <View style={styles.spacer} />
        <CtlTextInput
          name="homeAirportCode"
          control={control}
          rules={required}
          label="Home Airport"
        />
        <View style={styles.spacer} />
        <CtlTextInput
          name="currentAirportCode"
          control={control}
          rules={required}
          label="Current Airport"
        />
        <View style={styles.spacer} />
        {!pilotItems && <LoadingIndicator />}
        {pilotItems && (
          <CtlSelectInput
            name="pilotId"
            control={control}
            label="Pilot"
            items={pilotItems}
          />
        )}
        <Text style={styles.fancyOr}>OR</Text>
        <Text style={styles.enterEmailText}>
          Enter the pilot's email below.
        </Text>
        <View style={styles.spacer} />
        <CtlTextInput
          name="inviteeEmail"
          control={control}
          rules={isEmail}
          label="Email"
        />
        <Text style={styles.errorMsg}>{errMsg}</Text>
      </View>
      <Button
        title="Add Aircraft"
        style={styles.addBtn}
        variant="large"
        onPress={handleSubmit(addAircraft)}
      />
      <View style={styles.spacer} />
    </ScreenContainer>
  );
}

export function AddAircraftScreenHeader() {
  return (
    <BaseHeader title="Aircraft" leftIcon="back" rightIcon="notifications" />
  );
}

const styles = StyleSheet.create({
  spacer: {
    ...Theme.spacer,
  },
  header: {
    ...Theme.h1,
    textAlign: "center",
  },
  subheader: {
    maxWidth: 240,
    marginHorizontal: "auto",
    textAlign: "center",
    marginTop: 8,
  },
  fancyOr: {
    color: Colors.theme.gold,
    fontSize: 24,
    fontWeight: "600",
    textTransform: "uppercase",
    textAlign: "center",
    marginTop: 24,
  },
  enterEmailText: {
    fontWeight: "600",
    fontSize: 16,
    textAlign: "center",
    marginTop: 24,
  },
  errorMsg: {
    ...Theme.errorMessage,
    marginTop: 24,
    marginBottom: 12,
  },
  addBtn: {
    marginTop: "auto",
    alignSelf: "center",
  },
});
