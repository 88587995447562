import { configureStore } from "@reduxjs/toolkit";

import createAccount from "./createAccount";
import requestTrip from "./requestTrip";
import createNotifications from "./createNotifications";
import {
  registerTranslation,
} from 'react-native-paper-dates'
import { useNavigationContainerRef } from "@react-navigation/native";
registerTranslation("pl", {
  save: 'Save',
  selectSingle: 'Select date',
  selectMultiple: 'Select dates',
  selectRange: 'Select period',
  notAccordingToDateFormat: (inputFormat) =>
    `Date format must be ${inputFormat}`,
  mustBeHigherThan: (date) => `Must be later then ${date}`,
  mustBeLowerThan: (date) => `Must be earlier then ${date}`,
  mustBeBetween: (startDate, endDate) =>
    `Must be between ${startDate} - ${endDate}`,
  dateIsDisabled: 'Day is not allowed',
  previous: 'Previous',
  next: 'Next',
  typeInDate: 'Type in date',
  pickDateFromCalendar: 'Pick date from calendar',
  close: 'Close',
})
const store = configureStore({
  reducer: {
    createAccount: createAccount.reducer,
    requestTrip: requestTrip.reducer,
    creatNotifications: createNotifications.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
