import { fetcher } from "./fetcher";
import { User, MemberRole, Member, Pilot } from "../types/User";
import { Invitation, InvitationType } from "../types/Invitation";
import { Organization } from "../types/Organization";
import { Aircraft } from "../types/Aircraft";
import {
  BasicInfo,
  CreateAccountState,
  OrganizationInfo,
  PilotInfo,
} from "../store/createAccount";
import { RequestTripState } from "../store/requestTrip";
import {
  ChangeRequest,
  DetailType,
  Location,
  Trip,
  TripPilot,
  UpdateTripDto,
} from "../types/Trip";
import { StripeMemberStatus, StripePilotStatus, StripeCheckoutDto, StripeResponseObject } from "../types/Stripe";
import { TripNotification } from "../types/Notification";

export type LoginArgs = {
  username: string;
  password: string;
};

export type ExchangeCreateCodeRes = {
  referrerId: string;
  userType: "pilot" | "passenger";
};

export type AcceptInvitationPayload = {
  code: string;
  userInfo: Omit<BasicInfo, "photo"> & { password: string };
  pilotInfo?: Pick<PilotInfo, "certLevel" | "certNo">;
  organizationInfo?: OrganizationInfo;
};

export type CreateAircraftPayload = {
  organizationId: string;
  tailNumber: string;
  dayRate: number;
  homeAirportCode: string;
  currentAirportCode: string;
  pilotId: string;
  inviteeEmail: string;
};

export type UpdateAircraftPayload = Omit<
  CreateAircraftPayload,
  "organizationId"
>;

type TripDetailPayload = {
  location: Location;
  airport: string;
  date: string;
  time: string;
  preferredFBO: string;
  detailType: DetailType;
};

type NewPasswordDto = {
  email: string;
  password: string;
  confirmPassword: string;
}

type ConfirmEmailData = {
  email: string;
  emailOtp: string;
}

type ContactUsDto = {
  name: string;
  email: string;
  message: string;
}

export type SendTripRequestPayload = RequestTripState["tripInfo"] & {
  notes: string;
  bags: number;
  passengers: number;
} & {
  departureInfo: TripDetailPayload;
  arrivalInfo: TripDetailPayload;
  returnInfo: TripDetailPayload;
};

export type UpdateHobbsDto = {
  hobbs: string;
  isStart: boolean;
};

export type StartOrEndTripDto = {
  hobbs: string;
};

class API {
  async login(creds: LoginArgs) {
    const { data } = await fetcher.request<{ accessToken: string; user: User }>(
      {
        method: "post",
        url: "/login",
        data: creds,
      }
    );
    return data;
  }

  async whoami() {
    const { data } = await fetcher.request<User>(
      {
        method: "get",
        url: "/users/whoami",
      },
      { useAccessToken: true }
    );
    return data;
  }

  async exchangeCreateCode(code: string) {
    const { data } = await fetcher.request<Invitation>({
      method: "post",
      url: `/invitations/exchange`,
      data: {
        code,
      },
    });
    return data;
  }

  // async mockExchangeCreateCode(code: string): Promise<Invitation> {
  //   return {
  //     id: "88e8d40b-7240-4e71-8678-cbcf7b6e51b7",
  //     temporary: true,
  //     url: "http://localhost:19006/create-account?code=88e8d40b-7240-4e71-8678-cbcf7b6e51b7",
  //     referrer: {
  //       id: "39eba41e-1985-4b8f-944b-dd4d40208f40",
  //     } as any,
  //     invitationType: InvitationType.SUBCONTRACTOR,
  //   };
  // }

  async createUser(payload: any) {}

  async deleteUser(userId: string) {
    const { data } = await fetcher.request(
      {
        method: "delete",
        url: `/users/${userId}`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async initStripe() {
    const { data } = await fetcher.request<{
      status: StripeMemberStatus | StripePilotStatus;
    }>(
      {
        method: "post",
        url: `/stripe/init`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async createStripeUrl() {
    const { data } = await fetcher.request<Invitation>(
      {
        method: "post",
        url: `/stripe/create-link`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async createLoginLink() {
    const { data } = await fetcher.request(
      {
        method: 'post',
        url: '/stripe/create-login-link',
      },
      { useAccessToken: true }
    );
    return data;
  }

  async stripeCheckout( dto: StripeCheckoutDto ) {
    // @ts-ignore
    const { data, url } = await fetcher.request<StripeResponseObject>(
      {
        method: 'post',
        url:'/stripe/checkout-session',
        data: dto,
      },
      { useAccessToken: true }
    );
    window.open( url, '_self' );
    return data;
  }

  async sendInvitation(email: string, invType: InvitationType) {
    const { data } = await fetcher.request<Invitation>(
      {
        method: "post",
        url: "/invitations",
        data: {
          inviteeEmail: email,
          invitationType: invType,
        },
      },
      { useAccessToken: true }
    );
    return data;
  }

  async createTempInvitation(invType: InvitationType) {
    const { data } = await fetcher.request<Invitation>(
      {
        method: "post",
        url: "/invitations",
        data: {
          invitationType: invType,
        },
      },
      { useAccessToken: true }
    );
    return data;
  }

  async acceptInvitation(code: string, payload?: FormData) {
    const { data } = await fetcher.request<Invitation>(
      {
        method: "post",
        url: `/invitations/${code}/accept`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: payload,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async rejectInvitation(code: string) {
    const { data } = await fetcher.request({
      method: "post",
      url: `/invitations/${code}/reject`,
    });
    return data;
  }

  async getPilot(pilotId: string) {
    const { data } = await fetcher.request<User>({
      method: "get",
      url: `/pilots/${pilotId}`,
    });
    return data;
  }

  async getPilots() {
    const { data } = await fetcher.request({
      method: "get",
      url: `/pilots`,
    });
    return data;
  }

  async getPilotContractors(pilotId: string) {
    const { data } = await fetcher.request<Pilot[]>(
      {
        method: "get",
        url: `/pilots/${pilotId}/subcontractors`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async getSubcontractorTrips(pilotId: string) {
    const { data } = await fetcher.request<Trip[]>(
      {
        method: "get",
        url: `/pilots/${pilotId}/subcontractor-trips`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async getPilotOrgs(pilotId: string) {
    const { data } = await fetcher.request<Organization[]>(
      {
        method: "get",
        url: `/pilots/${pilotId}/organizations`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async deletePilot( pilotId: string, orgId: string ) {
    const { data } = await fetcher.request(
      {
        method: "post",
        url: `pilots/${pilotId}/delete`,
        data: { orgId }
      },
      { useAccessToken: true }
    );
    return data;
  }

  async getUser(id: string) {
    const { data } = await fetcher.request<User>(
      {
        method: "get",
        url: `/users/${id}`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async getOrganization(id: string) {
    const { data } = await fetcher.request<Organization>(
      {
        method: "get",
        url: `/organizations/${id}`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async getOrgUsers(id: string) {
    const { data } = await fetcher.request<User[]>(
      {
        method: "get",
        url: `/organizations/${id}/users`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async getOrgAircraft(orgId: string) {
    const { data } = await fetcher.request<Aircraft[]>(
      {
        method: "get",
        url: `/organizations/${orgId}/aircraft`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async getAircraft(aircraftId: string) {
    const { data } = await fetcher.request<Aircraft>(
      {
        method: "get",
        url: `/aircraft/${aircraftId}`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async createAircraft(payload: CreateAircraftPayload) {
    const { data } = await fetcher.request<Aircraft>(
      {
        method: "post",
        url: `/aircraft`,
        data: payload,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async deleteAircraft(id: string) {
    const { data } = await fetcher.request<number>(
      {
        method: "delete",
        url: `/aircraft/${id}`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async editAircraft(id: string, dto: UpdateAircraftPayload) {
    const { data } = await fetcher.request<Aircraft>(
      {
        method: "patch",
        url: `/aircraft/${id}`,
        data: dto,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async updateMemberRole(id: string, role: MemberRole) {
    const { data } = await fetcher.request<User>(
      {
        method: "post",
        url: `/users/${id}/role`,
        data: {
          role,
        },
      },
      { useAccessToken: true }
    );
    return data;
  }

  async sendTripRequest(payload: SendTripRequestPayload) {
    const { data } = await fetcher.request<Trip>(
      {
        method: "post",
        url: `/trips`,
        data: payload,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async getTripsByUser(userId: string) {
    const { data } = await fetcher.request<Trip[]>(
      {
        method: "get",
        url: `/users/${userId}/trips`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async getTripsByOrg(orgId: string) {
    const { data } = await fetcher.request<Trip[]>(
      {
        method: "get",
        url: `/organizations/${orgId}/trips`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async getTripsByPilot(pilotId: string) {
    const { data } = await fetcher.request<Trip[]>(
      {
        method: "get",
        url: `/pilots/${pilotId}/trips`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async getTrip(tripId: string) {
    const { data } = await fetcher.request<Trip>(
      {
        method: "get",
        url: `/trips/${tripId}`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async getTripPilots(tripId: string) {
    const { data } = await fetcher.request<TripPilot[]>(
      {
        method: "get",
        url: `/trips/${tripId}/pilots`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async subcontractTrip(tripId: string, recipientPilotId: string) {
    const { data } = await fetcher.request<boolean>(
      {
        method: "post",
        url: `/trips/${tripId}/subcontract`,
        data: { recipientPilotId },
      },
      { useAccessToken: true }
    );
    return data;
  }

  async declineTrip(tripId: string) {
    const { data } = await fetcher.request(
      {
        method: "post",
        url: `/trips/${tripId}/decline`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async cancelTrip( tripId: string ) {
    const { data } = await fetcher.request(
      {
        method: "post",
        url: `/trips/${tripId}/cancel`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async bookTrip( tripId: string ) {
    const { data } = await fetcher.request(
      {
        method: 'post',
        url: `/trips/${tripId}/book`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async acceptTrip(tripId: string, dto: UpdateTripDto) {
    const { data } = await fetcher.request(
      {
        method: "post",
        url: `/trips/${tripId}/accept`,
        data: dto,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async startTrip(tripId: string, dto: StartOrEndTripDto) {
    const { data } = await fetcher.request(
      {
        method: "post",
        url: `/trips/${tripId}/start`,
        data: dto,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async endTrip(tripId: string, dto: StartOrEndTripDto) {
    const { data } = await fetcher.request(
      {
        method: "post",
        url: `/trips/${tripId}/end`,
        data: dto,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async updateHobbs(tripId: string, dto: UpdateHobbsDto) {
    const { data } = await fetcher.request(
      {
        method: "patch",
        url: `/trips/${tripId}/hobbs`,
        data: dto,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async makeChangeRequest(tripId: string, dto: ChangeRequest) {
    const { data } = await fetcher.request(
      {
        method: "post",
        url: `/trips/${tripId}/change-request`,
        data: dto,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async getTripChangeRequestTest(tripId: string) {
    const { data } = await fetcher.request<Trip>(
      {
        method: "get",
        url: `/trips/${tripId}/change-request/test`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  async approveChangeRequest(tripId: string) {
    const { data } = await fetcher.request(
      {
        method: "post",
        url: `/trips/${tripId}/change-request/approve`,
      },
      { useAccessToken: true }
    );
    return data;
  }

  sendPushSubscription( subscription: any ) {
    return fetcher.request(
      {
        method: 'post',
        url: "/app-notifications/save-subscription",
        data: JSON.stringify( subscription ),
      },
      { useAccessToken: true }
    );
  }

  async getAppNotifications( id: string ) {
    const { data } = await fetcher.request<TripNotification[]>(
      {
        method: 'get',
        url: `/users/${id}/notifications`,
      },
      { useAccessToken: true }
    );
    return data;
  }

	async readNotification( id: string ) {
		const patch = {
			actions: [{ op: "replace", path: "read", value: true }],
		};
		const { data } = await fetcher.request(
			{
				method: "patch",
				url: `/app-notifications/${id}`,
				data: patch,
			},
			{ useAccessToken: true }
		);
		return data;
	}

  async forgotPassword( email: string ) {
    const { data } = await fetcher.request(
      {
        method: 'post',
        url: `/users/forgot-password`,
        data: { email },
      },
      { useAccessToken: false }
    );
    return data;
  }

  async resetPassword( dto: NewPasswordDto ) {
    const { data } = await fetcher.request(
      {
        method: 'post',
        url: `/users/reset-password`,
        data: dto,
      },
      { useAccessToken: false }
    );
    return data;
  }

  async confirmEmail( dto: ConfirmEmailData ) {
    const { data } = await fetcher.request(
      {
        method: 'post',
        url: `/users/confirm-email`,
        data: dto,
      },
      { useAccessToken: false }
    );
    return data;
  }

  async contactUs( dto : ContactUsDto ) {
    const { data } = await fetcher.request(
      {
        method: 'post',
        url: `/users/contact-us`,
        data: dto,
      },
      { useAccessToken: false }
    );
    return data;
  }
}

export const api = new API();
