import { PlacesValue } from "../components/GooglePlacesInput";
import { airportCodeRegex } from "./trips";

export const required = {
  required: { value: true, message: "This field is required." },
};

export const googlePlacesRequired = {
  validate: (val: PlacesValue) =>
    !val.city ? "This field is required." : undefined,
};

export const isEmail = {
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "Invalid email address",
  },
};

export const isAirport = {
  pattern: {
    value: airportCodeRegex,
    message:
      'Airport code must be Three Characters Long. E.g., "FAT - Fresno Internat..."',
  },
};
