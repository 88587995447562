import * as React from "react";
import { StyleSheet } from "react-native";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";

import Theme from "../constants/Theme";
import Colors from "../constants/Colors";
import { BaseHeader } from "../components/BaseHeader";
import { Button } from "../components/Button";
import { View, Text } from "../components/Themed";
import { useAuth } from "../hooks";
import CtlTextInput from "../components/ControlledInputs/CtlTextInput";
import { required } from "../util/formRules";
import ScreenContainer from "../components/ScreenContainer";
import { useNavigation } from "@react-navigation/native";
import { UnauthStackNavigationProp } from "../navigation/types";

type ForgotPasswordData = {
	email: string;
};

export default function ForgotPasswordScreen() {
	const navigation = useNavigation<UnauthStackNavigationProp>();
	const { control, handleSubmit } = useForm<ForgotPasswordData>();
	const [errMsg, setErrMsg] = React.useState("");
	const { forgotPassword } = useAuth();

	const callForgotPassword = async (d: ForgotPasswordData) => {
		try {
			setErrMsg("");
			await forgotPassword(d);
			navigation.push( "ConfirmEmail", { email: d.email } );
		} catch (err) {
			setErrMsg("That Email does not exist.");
		}
	};

	return (
		<ScreenContainer>
			<View style={styles.main}>
				<Text style={styles.title}>Forgot Password</Text>
				<CtlTextInput
					name="email"
					control={control}
					rules={required}
					containerStyle={styles.textInput}
					centerText
					label="Email"
					placeholder="email@provider.com"
					textContentType="emailAddress"
					onSubmitEditing={handleSubmit(callForgotPassword)}
				/>
				<Text style={styles.errMsg}>{errMsg}</Text>
				<Button
					title="Send Reset Email"
					onPress={handleSubmit(callForgotPassword)}
					style={styles.button}
				/>
			</View>
		</ScreenContainer>
	);
}

export function ForgotPasswordHeader({ navigation }: NativeStackHeaderProps) {

	const onBack = () => {
		if (navigation.canGoBack())	navigation.goBack();
		else navigation.navigate("Login");
	};

	return <BaseHeader	leftIcon="back"	onBack={onBack} />;
}

const styles = StyleSheet.create({
  container: {
    ...Theme.page,
  },
  contentContainer: {
    flex: 1,
    justifyContent: "space-between",
  },
  main: {
    alignItems: "center",
  },
  pretitle: {
    color: Colors.theme.primary,
    fontWeight: "600",
    fontSize: 16,
  },
  title: {
    color: Colors.theme.primary,
    fontSize: 48,
    fontWeight: "bold",
    marginBottom: 24,
  },
  textInput: {
    alignSelf: "stretch",
    marginTop: 16,
  },
  errMsg: {
    ...Theme.errorMessage,
  },
  button: {
    marginTop: 25,
    paddingHorizontal: 32,
  },
  signInBtn: {
    marginTop: 15,
    paddingHorizontal: 48,
  },
});