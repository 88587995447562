import { useController, FieldValues, FieldPath } from "react-hook-form";

import { ControlledInputProps } from "../../types/ControlledInput";
import SelectInput, { SelectInputProps } from "../SelectInput";

export default function CtlSelectInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  rules,
  ...rest
}: ControlledInputProps<TFieldValues, TName> & SelectInputProps) {
  const {
    field,
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    name,
    control,
    rules,
  });

  return <SelectInput {...field} error={error} {...rest} />;
}
