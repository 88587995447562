import * as React from "react";
import { StyleSheet } from "react-native";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";

import Theme from "../constants/Theme";
import Colors from "../constants/Colors";
import { BaseHeader } from "../components/BaseHeader";
import { Button } from "../components/Button";
import { View, Text } from "../components/Themed";
import { useAuth } from "../hooks";
import CtlTextInput from "../components/ControlledInputs/CtlTextInput";
import { required } from "../util/formRules";
import ScreenContainer from "../components/ScreenContainer";
import { ConfirmEmailScreenProps, UnauthStackNavigationProp } from "../navigation/types";
import { useNavigation } from "@react-navigation/native";

type ConfirmEmailData = {
	email: string;
	emailOtp: string;
};

export default function ConfirmEmailScreen( { route } : ConfirmEmailScreenProps ) {
	if (!route.params) throw new Error("Email not provided");
	const { email } = route.params;
	const navigation = useNavigation<UnauthStackNavigationProp>();
	const { control, handleSubmit } = useForm<ConfirmEmailData>();
	const confirmEmailMutation = useMutation((d: ConfirmEmailData) => confirmEmail(d));
	const [errMsg, setErrMsg] = React.useState("");
	const { confirmEmail } = useAuth();

	const callConfirmEmail = async (d: ConfirmEmailData) => {
		try {
			setErrMsg("");
			d.email = email;
			await confirmEmailMutation.mutateAsync(d);
			navigation.push("UpdatePassword", { email: d.email });
		} catch (err) {
			setErrMsg("There was an error confirming your email." || err);
		}
	};

	return (
		<ScreenContainer>
			<View style={styles.main}>
				<Text style={styles.title}>Confirm Email</Text>
				<Text style={styles.paragraph}>
					We sent a confirmation code to {email}. Please enter it below.
				</Text>
				<CtlTextInput
					name="emailOtp"
					control={control}
					rules={required}
					containerStyle={styles.textInput}
					centerText
					label="One Time Password"
					placeholder="1234"
					textContentType="none"
				></CtlTextInput>
				<Text style={styles.errMsg}>{errMsg}</Text>
				<Button
					title="Confirm"
					variant="large"
					onPress={handleSubmit(callConfirmEmail)}
					style={styles.button}
					disabled={confirmEmailMutation.isLoading}
				/>
			</View>
		</ScreenContainer>
	)
}

export function ConfirmEmailHeader({ navigation }: NativeStackHeaderProps) {

	const onBack = () => {
		if (navigation.canGoBack())	navigation.goBack();
		else navigation.navigate("ForgotPassword");
	};

	return <BaseHeader	leftIcon="back"	onBack={onBack} />;
}

const styles = StyleSheet.create({
  container: {
    ...Theme.page,
  },
  contentContainer: {
    flex: 1,
    justifyContent: "space-between",
  },
  main: {
    alignItems: "center",
  },
  pretitle: {
    color: Colors.theme.primary,
    fontWeight: "600",
    fontSize: 16,
  },
  title: {
    color: Colors.theme.primary,
    fontSize: 48,
    fontWeight: "bold",
    marginBottom: 24,
  },
  textInput: {
    alignSelf: "stretch",
    marginTop: 16,
  },
  errMsg: {
    ...Theme.errorMessage,
  },
  button: {
    marginTop: 25,
    paddingHorizontal: 32,
  },
  signInBtn: {
    marginTop: 15,
    paddingHorizontal: 48,
  },
	paragraph: {
		color: 'black',
		fontSize: 16,
		textAlign: "center",
		marginBottom: 24,
	},
});