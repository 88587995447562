import * as React from "react";
import { ScrollView, StyleSheet, TouchableOpacity } from "react-native";
import { NavigationHelpersContext, useNavigation } from "@react-navigation/native";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import Toast from "react-native-toast-message";

import Theme from "../constants/Theme";
import SharedStyles from "../constants/SharedStyles";
import { BaseHeader } from "../components/BaseHeader";
import { View, Text } from "../components/Themed";
import { ZephurVersion } from "../components/ZephurVersion";
import { Button } from "../components/Button";
import TripDetailVisual from "../components/TripDetailVisual";
import { useUser } from "../hooks";
import { TripScreenProps, HomeStackNavigationProp } from "../navigation/types";
import LoadingIndicator from "../components/LoadingIndicator";
import { getDestination, getPilotForTrip } from "../util/trips";
import PreviewPicture from "../components/PreviewPicture";
import { UserType } from "../types/User";
import TripStatusCard from "../components/TripStatusCard";
import {
    DetailType,
    PilotStatus,
    Trip,
    TripPilot,
    TripStatus,
} from "../types/Trip";
import { useTrip, useTripPilots } from "../hooks/trips";
import ScreenContainer from "../components/ScreenContainer";
import Colors from "../constants/Colors";

function TripDetails({ trip }: { trip: Trip }) {
    // Utility function.
    const getDetail = (t: DetailType) =>
        trip.tripDetails.find((d) => d.detailType === t);

    const departure = getDetail(DetailType.DEPARTURE);
    const arrival = getDetail(DetailType.ARRIVAL);
    const ret = getDetail(DetailType.RETURN);
    const orange = "#E58416",
        blue = "#6BAFFF";

    if (!departure || !arrival) return null;

    return (
        <>
            <TripDetailVisual
                departure={departure}
                arrival={arrival}
                departureColor={orange}
                arrivalColor={blue}
            />
            {ret && (
                <TripDetailVisual
                    departure={arrival}
                    arrival={ret}
                    departureColor={blue}
                    arrivalColor={orange}
                    style={{ marginTop: 36 }}
                />
            )}
        </>
    );
}

export default function TripScreen({ route, navigation }: TripScreenProps) {
    const { tripId } = route.params;
    const { user } = useUser();
    const { trip, isLoading: tripLoading } = useTrip(tripId);
    const { tripPilots, isLoading: tripPilotsLoading } = useTripPilots(tripId);

    const tripPilot = tripPilots && getPilotForTrip(tripPilots);
    const isLoading = tripLoading || tripPilotsLoading;

    const userMadeChangeReq = trip && trip.changeRequestBy?.id === user?.id;

    const reviewChangeRequest = () => {
        navigation.push("ReviewChangeRequest", { tripId });
    };

    const viewPilotClick = () => {
        if ( pilot.id === user?.id ) return;
        navigation.getParent()?.navigate( 'PilotsStack', {
            screen : 'ViewPilot', params : { id : pilot.id }
        });
    }

    if (!trip || isLoading) {
        return (
            <ScrollView style={Theme.page} contentContainerStyle={Theme.pageContent}>
                {isLoading && <LoadingIndicator />}
                <ZephurVersion />
            </ScrollView>
        );
    }

    const { organization, pointOfContact } = trip;
    const { pilot } = tripPilot as TripPilot; // pilot should never be empty.

    const viewRequestorClick = () => {
        navigation.getParent()?.navigate( "ContactsStack", {
            screen : 'ViewUser', params : { userId : pointOfContact.id } 
        });
    }

    return (
        <ScreenContainer>
            {/* <View
        style={StyleSheet.flatten([
          styles.sectionContainer,
          { borderTopWidth: 0 },
        ])}
      >
        <Text style={styles.sectionHeader}>Trip ID</Text>
        <Text>{trip.id}</Text>
      </View> */}

            {trip.changeRequest && (
                <View style={styles.sectionContainer}>
                    <Text style={styles.sectionHeader}>Change Request</Text>
                    {userMadeChangeReq ? (
                        <Text>Waiting for your change request to be approved.</Text>
                    ) : (
                        <>
                            <Text style={{ textAlign: "center" }}>
                                {`${trip.changeRequestBy?.firstName} ${trip.changeRequestBy?.lastName}`}{" "}
                                made a change to this trip's details.
                            </Text>
                            <Button
                                title="Review"
                                onPress={reviewChangeRequest}
                                style={{ alignSelf: "center", marginTop: 20 }}
                            />
                        </>
                    )}
                </View>
            )}

            <View style={styles.sectionContainer}>
                <Text style={styles.sectionHeader}>Pilot Details</Text>
                {pilot.id == user?.id ? (
                    <PreviewPicture
                        url={pilot.photoUrl}
                        title={`${pilot.firstName} ${pilot.lastName}`}
                    />
                ) : (
                    <PreviewPicture
                        onPress={viewPilotClick}
                        url={pilot.photoUrl}
                        title={`${pilot.firstName} ${pilot.lastName}`}
                    />
                )}
            </View>

            <View style={styles.sectionContainer}>
                <Text style={styles.sectionHeader}>Client Details</Text>
                <PreviewPicture url={organization.photoUrl} title={organization.name} />
                <View style={styles.clientDetailsSpacer} />
                <PreviewPicture
                    onPress={viewRequestorClick}
                    url={pointOfContact.photoUrl}
                    title={`${pointOfContact.firstName} ${pointOfContact.lastName}`}
                />
                <View style={styles.clientDetailsSpacer} />
                <Text style={styles.label}>
                    Tail Number: #{trip.aircraft.tailNumber}
                </Text>
                <Text style={styles.label}>Day Rate: ${trip.aircraft.dayRate}</Text>
                <Text style={styles.label}>
                    Home Airport: <span style={{ 'textTransform': 'uppercase' }}>{trip.aircraft.homeAirportCode}</span>
                </Text>
                <Text style={styles.label}>
                    Current Airport: <span style={{ 'textTransform': 'uppercase' }}>{trip.aircraft.currentAirportCode}</span>
                </Text>
            </View>

            <View style={styles.sectionContainer}>
                <Text style={styles.sectionHeader}>Trip Details</Text>
                <Text style={styles.label}>Trip Type: {trip.tripType === 'drop_off' ? 'Drop off' : 'Standby'}</Text>
                <Text style={styles.label}>
                    Start Hobbs: {trip.startHobbs ? trip.startHobbs : "N/A"}
                </Text>
                <Text style={StyleSheet.flatten([styles.label, { marginBottom: 12 }])}>
                    End Hobbs: {trip.endHobbs ? trip.endHobbs : "N/A"}
                </Text>
                <TripDetails trip={trip} />
            </View>

            <View
                style={StyleSheet.flatten([
                    styles.sectionContainer,
                    { borderBottomWidth: 0 },
                ])}
            >
                <Text style={styles.sectionHeader}>Additional Details</Text>

                <Text style={styles.label}>
                    Preferred FBO: {trip.preferredFBO || "No preference"}
                </Text>
                <View style={styles.aditDetailsSpacer} />
                <Text style={styles.label}>Passengers: {trip.passengers}</Text>
                <View style={styles.aditDetailsSpacer} />
                <Text style={styles.label}>Bags: {trip.bags}</Text>
                <View style={styles.aditDetailsSpacer} />
                <Text style={styles.label}>Notes:</Text>
                <Text>{trip.notes || "No notes provided."}</Text>
            </View>
            <ZephurVersion />
        </ScreenContainer>
    );
}

export function TripScreenHeader({ route }: NativeStackHeaderProps) {
    // No way to type-infer header props.
    const { tripId } = route.params as any;
    const { trip } = useTrip(tripId);
    const { tripPilots } = useTripPilots(tripId);
    const { user, isLoading: userLoading } = useUser();
    const navigation = useNavigation<HomeStackNavigationProp>();

    const destination = trip && getDestination(trip);
    const isPilot = user?.type === UserType.PILOT;
    const thisTripPilot = tripPilots?.find((p) => p.pilot.id === user?.id);

    const showEdit = ( TripStatus.ACCEPTED === trip?.status && isPilot ) || ( [TripStatus.PENDING, TripStatus.ACCEPTED].includes( trip?.status as any ) && !isPilot );
    
    // // For debugging toast message.
    // const showToast = () => {
    //     Toast.show({
    //         type: "success",
    //         position: "bottom",
    //         text1: "Hello!",
    //     });
    // };

    const DeclineOrAccept = (
        <View style={headerStyles.declineAcceptBtns}>
            <Button
                title="Decline"
                variant="large"
                color="blueishBlack"
                style={{ marginRight: 40 }}
                onPress={declineTrip}
            // onPress={showToast}
            />
            <Button title="Accept" variant="large" onPress={acceptTrip} />
        </View>
    );

    const renderBelow = ((): JSX.Element | undefined => {
        if (!user || !trip || !tripPilots) return;

        const memberRenderBelow = (): JSX.Element => {
            // TODO: getPilotForTrip needs the trip to be more intelligent about
            // TODO: what to show (eg, dont show a pending subcontractor to a member, show
            // TODO: the original pilot for the trip!)
            const tp = getPilotForTrip(tripPilots);
            switch (trip.status) {
                case TripStatus.PENDING:
                    return (
                        <TripStatusCard
                            status={TripStatus.PENDING}
                            name={`${tp?.pilot.firstName}`}
                        />
                    );

                case TripStatus.DECLINED:
                    return (
                        <TripStatusCard
                            status={TripStatus.DECLINED}
                            subtext={`${tp?.pilot.firstName} declined this trip.`}
                        />
                    );
                case TripStatus.ACCEPTED:
                    return (
                        <View>
                            <TripStatusCard
                                status={TripStatus.ACCEPTED}
                                name={`${tp?.pilot.firstName}`}
                            />
                            <View style={Theme.spacer} />
                            {!trip.changeRequest && (
                                <Button
                                    title="Book"
                                    variant="large"
                                    color="lightBlue"
                                    onPress={bookTrip}
                                />
                            )}
                        </View>
                    );
                case TripStatus.CANCELLED:
                    return (
                        <TripStatusCard
                            status={TripStatus.CANCELLED}
                        />
                    );
                case TripStatus.IN_PROGRESS:
                    return <TripStatusCard status={TripStatus.IN_PROGRESS} />;
                case TripStatus.COMPLETED:
                    return <TripStatusCard status={TripStatus.COMPLETED} />;
                case TripStatus.BOOKED:
                    return <TripStatusCard status={TripStatus.BOOKED} />;
                case TripStatus.PENDING_PAYMENT:
                    return <TripStatusCard status={TripStatus.PENDING_PAYMENT} />
            }
            return <></>;
        };

        const pilotRenderBelow = (): JSX.Element => {
            // Sort pilots by the time they were added to this trip.
            tripPilots.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            if (!thisTripPilot) {
                // Return the trip status view.
                return <></>;
            }

            switch (thisTripPilot.pilotStatus) {
                case PilotStatus.PENDING:
                    // Should just be a decline/accept button combo.
                    return DeclineOrAccept;
                case PilotStatus.SUBCONTRACTED: {
                    // Get who you subcontracted to.
                    const subber = tripPilots.find(
                        (tp) =>
                            [PilotStatus.PENDING, PilotStatus.ACCEPTED].includes(tp.pilotStatus) &&
                            tp.subcontractedFrom?.id === user.id
                    );
                    const name = subber ? subber.pilot.firstName : "";
                    // console.log( 'tripStatusCard', trip, subber, name );
                    return <TripStatusCard status={trip.status} name={name} />;
                }
                case PilotStatus.ACCEPTED:
                    switch (trip.status) {
                        case TripStatus.ACCEPTED:
                            return (
                                <TripStatusCard status={TripStatus.ACCEPTED} name={thisTripPilot.pilot.firstName} />
                            );
                        case TripStatus.PENDING_PAYMENT:
                            return (
                                <TripStatusCard status={TripStatus.PENDING_PAYMENT} />
                            );
                        case TripStatus.BOOKED:
                            return (
                                <Button
                                    title="Start Trip"
                                    variant="large"
                                    color="gold"
                                    onPress={startTrip}
                                />
                            );
                        case TripStatus.IN_PROGRESS:
                            return (
                                <Button
                                    title="End Trip"
                                    variant="large"
                                    color="gold"
                                    onPress={endTrip}
                                />
                            );
                        case TripStatus.COMPLETED:
                            return <TripStatusCard status={TripStatus.COMPLETED} />;
                        default:
                            // Should never happen.
                            return <></>;
                    }
                case PilotStatus.DECLINED:
                    return (
                        <TripStatusCard
                            status={TripStatus.DECLINED}
                            subtext="You have declined this trip."
                        />
                    );
            }
        };

        if (isPilot) {
            return pilotRenderBelow();
        } else {
            return memberRenderBelow();
        }
    })();

    function editTrip() {
        navigation.navigate("EditTrip", { tripId: tripId });
    }

    // Declared as function for
    function declineTrip() {
        navigation.push("Subcontract", { tripId });
    }

    function acceptTrip() {
        navigation.push("ApproveTripDetails", { tripId });
    }

    const pastTripsStatuses = [TripStatus.COMPLETED, TripStatus.DECLINED, TripStatus.CANCELLED]

    function onBack() {
        if (pastTripsStatuses.includes(trip?.status!)) navigation.navigate("PastTrips");
        // else if (navigation.canGoBack()) navigation.goBack();
        else navigation.navigate("Home");
    }

    function startTrip() {
        navigation.push("EnterHobbs", { tripId, isStart: true });
    }

    function endTrip() {
        navigation.push("EnterHobbs", { tripId, isStart: false });
    }

    function bookTrip() {
        navigation.push("BookTrip", { tripId });
    }

    return (
        <BaseHeader
            leftIcon="back"
            rightIcon={showEdit ? "edit" : undefined}
            color="blue"
            onEdit={editTrip}
            onBack={onBack}
            renderBelow={() => renderBelow}
        >
            {destination && (
                <View style={headerStyles.container}>
                    <Text style={headerStyles.pretitle}>Destination:</Text>
                    <Text style={headerStyles.title}>
                        {`${destination.location.city}, ${destination.location.state}`}
                    </Text>
                </View>
            )}
        </BaseHeader>
    );
}

const headerStyles = StyleSheet.create({
    container: {
        marginTop: 8,
    },
    pretitle: {
        color: "#fff",
        fontWeight: "400",
    },
    title: {
        color: "#fff",
        fontWeight: "700",
        fontSize: 28,
    },
    declineAcceptBtns: {
        flexDirection: "row",
        justifyContent: "center",
    },
});

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
        backgroundColor: "#fff",
    },
    sectionContainer: {
        ...SharedStyles.sectionContainer,
    },
    sectionHeader: {
        ...SharedStyles.sectionHeader,
    },
    label: {
        fontSize: 16,
        fontWeight: "600",
    },
    clientDetailsSpacer: {
        height: 20,
    },
    aditDetailsSpacer: {
        height: 20,
    },
    errorMsg: {
        color: Colors.theme.danger,
        textAlign: "center",
    },
});
