import { StyleSheet } from "react-native";

import Theme from "../constants/Theme";
import { ZephurVersion } from "../components/ZephurVersion";
import { Button } from "../components/Button";
import { BaseHeader } from "../components/BaseHeader";
import { View, Text } from "../components/Themed";
import { InviteScreenProps } from "../navigation/types";
import { InvitationType } from "../types/Invitation";
import ScreenContainer from "../components/ScreenContainer";

export default function InviteScreen({ navigation }: InviteScreenProps) {
  const invite = (
    inviteType: InvitationType.ORGANIZATION | InvitationType.SUBCONTRACTOR
  ) => {
    navigation.push("QR", { inviteType });
  };

  return (
    <ScreenContainer>
      <Text style={styles.header}>
        Who would you like to invite to use Zephur?
      </Text>
      <View style={styles.buttons}>
        <Button
          title="Invite a Pilot"
          onPress={() => invite(InvitationType.SUBCONTRACTOR)}
        />
        <Button
          title="Invite an Organization"
          color="lightBlue"
          style={{ marginTop: 16 }}
          onPress={() => invite(InvitationType.ORGANIZATION)}
        />
      </View>
      <ZephurVersion />
    </ScreenContainer>
  );
}

export function InviteScreenHeader() {
  return <BaseHeader title="Invite" leftIcon="menu" color="blue" />;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    ...Theme.h1,
    textAlign: "center",
  },
  buttons: {
    marginTop: 32,
    paddingHorizontal: 20,
  },
});
