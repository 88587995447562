import * as React from "react";
import { StyleSheet } from "react-native";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigation } from "@react-navigation/native";

import Theme from "../constants/Theme";
import Colors from "../constants/Colors";
import { BaseHeader } from "../components/BaseHeader";
import { Button } from "../components/Button";
import { View, Text } from "../components/Themed";
import { ZephurVersion } from "../components/ZephurVersion";
import { useAuth } from "../hooks";
import CtlTextInput from "../components/ControlledInputs/CtlTextInput";
import { required } from "../util/formRules";
import ScreenContainer from "../components/ScreenContainer";
import { UnauthStackNavigationProp } from "../navigation/types";

type LoginData = {
  username: string;
  password: string;
};

export default function LoginScreen() {
  const navigation = useNavigation<UnauthStackNavigationProp>();
  const { control, handleSubmit } = useForm<LoginData>();
  const loginMutation = useMutation((d: LoginData) => login(d));
  const [errMsg, setErrMsg] = React.useState("");
  const { login } = useAuth();

  const callLogin = async (d: LoginData) => {
    try {
      setErrMsg("");
      await login(d);
    } catch (err) {
      // TODO: Check for invalid username/password, or if account is disabled.
      setErrMsg("The username or password is incorrect.");
    }
  };

  return (
    <ScreenContainer>
      <View style={styles.main}>
        <Text style={styles.pretitle}>Welcome to Zephur</Text>
        <Text style={styles.title}>Sign In</Text>
        <CtlTextInput
          name="username"
          control={control}
          rules={required}
          containerStyle={styles.textInput}
          centerText
          label="Email"
          placeholder="Email@provider.com"
          textContentType="username"
        />
        <CtlTextInput
          name="password"
          control={control}
          rules={required}
          containerStyle={styles.textInput}
          centerText
          label="Password"
          placeholder="Password"
          textContentType="password"
          secureTextEntry={true}
          onKeyPress={(e) => {
            if (e.nativeEvent.key !== "Enter") return;
            handleSubmit(callLogin)();
          }}
        />
        <Text style={styles.errMsg}>{errMsg}</Text>
        <Button
          style={styles.button}
          textStyle={styles.buttonText}
          onPress={() => navigation.navigate("ForgotPassword")}
          title="Forgot Password"
        />
        <Button
          title="Sign In"
          variant="large"
          onPress={handleSubmit(callLogin)}
          style={styles.signInBtn}
          disabled={loginMutation.isLoading}
        />
      </View>
      <ZephurVersion />
    </ScreenContainer>
  );
}

export function LoginHeader({ navigation }: NativeStackHeaderProps) {
  return (
    <BaseHeader hideBack style={{ minHeight: 140 }}>
      {/* <View style={headerStyles.container}>
        <Text style={headerStyles.text}>New around here?</Text>
        <Pressable onPress={createAccount}>
          <Text style={headerStyles.createText}>Create an Account</Text>
        </Pressable>
      </View> */}
    </BaseHeader>
  );
}

const headerStyles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: "none",
    padding: 20,
  },
  text: {
    color: "#fff",
    marginBottom: 8,
  },
  createText: {
    color: "#fff",
    fontSize: 20,
    fontWeight: "600",
    textDecorationLine: "underline",
  },
});

const styles = StyleSheet.create({
  container: {
    ...Theme.page,
  },
  contentContainer: {
    flex: 1,
    justifyContent: "space-between",
  },
  main: {
    alignItems: "center",
  },
  pretitle: {
    color: Colors.theme.primary,
    fontWeight: "600",
    fontSize: 16,
  },
  title: {
    color: Colors.theme.primary,
    fontSize: 48,
    fontWeight: "bold",
    marginBottom: 24,
  },
  textInput: {
    alignSelf: "stretch",
    marginTop: 16,
  },
  errMsg: {
    ...Theme.errorMessage,
  },
  button: {
    marginTop: 25,
    paddingHorizontal: 32,
    backgroundColor: "transparent",
  },
  buttonText: {
    color: Colors.theme.primary,
    fontSize: 16,
    fontWeight: "600",
  },
  signInBtn: {
    marginTop: 35,
    paddingHorizontal: 48,
  },
});
