import { useForm, useWatch, FormState, useFormState } from "react-hook-form";
import { StyleSheet } from "react-native";
import format from "date-fns/format";

import Theme from "../constants/Theme";
import { useOrgAircraft } from "../hooks/useOrgAircraft";
import {
  ChangeRequest,
  DetailType,
  Trip,
  TripDetail,
  TripPilot,
  TripType,
  UpdateTripDto,
} from "../types/Trip";
import { getDetail, getPilotForTrip } from "../util/trips";
import CtlGooglePlacesInput from "./ControlledInputs/CtlGooglePlacesInput";
import CtlSelectInput from "./ControlledInputs/CtlSelectInput";
import CtlTextInput from "./ControlledInputs/CtlTextInput";
import CtlDatePickerInput from "./ControlledInputs/CtlDatePickerInput";
import TextInput from "./TextInput";
import { Text, View } from "./Themed";
import { isAirport, required } from "../util/formRules";
import { Button } from "./Button";
import SubtleButton from "./SubtleButton";
import { apply, get, isValidDate } from "../util/utils";
import SharedStyles from "../constants/SharedStyles";
import ScreenContainer from "./ScreenContainer";
import CtlTimeInput from "./ControlledInputs/CtlTimeInput";
import * as React from 'react';

type TripDirtyFields = FormState<UpdateTripDto>["dirtyFields"];

type OnSaveMetadata = {
  isDirty: boolean;
  changedFields: ChangeRequest;
};

export type OnSaveHandler = (data: UpdateTripDto, meta: OnSaveMetadata) => void;

type TripChangeFormProps = {
  trip: Trip;
  tripPilots: TripPilot[];
  applyChangeRequest?: boolean;
  saveBtnLabel?: string;
  onSave?: OnSaveHandler;
  cancelBtnLabel?: string;
  onCancel?: () => void;
  disableBtns?: boolean;
  errMsg?: string;
};

const isoToFormattedDate = (iso: string | undefined) => {
  if (!iso) return "";
  const d = new Date(iso);
  return isValidDate(d) ? format(d, "MM/dd/yyyy") : "";
};

const tripDetailToDto = (td: TripDetail | undefined) => {
  if (!td) return;
  return {
    ...td,
    date: isoToFormattedDate(td.date),
  };
};

const tripToDto = (t: Trip): UpdateTripDto => {
  return {
    ...t,
    aircraftId: t.aircraft.id,
    departureInfo: tripDetailToDto(getDetail(t, DetailType.DEPARTURE)),
    arrivalInfo: tripDetailToDto(getDetail(t, DetailType.ARRIVAL)),
    returnInfo: tripDetailToDto(getDetail(t, DetailType.RETURN)),
  };
};

export default function TripChangeForm({
  trip,
  tripPilots,
  saveBtnLabel,
  onSave = () => null,
  cancelBtnLabel,
  onCancel = () => null,
  disableBtns
}: TripChangeFormProps) {
  const vals = tripToDto(trip);

  const [errMsg, setErrMsg] = React.useState("");

  // defaultValue should be the trip with the changeReq applied to it.
  const {
    control,
    handleSubmit,
    formState: { isDirty, dirtyFields },
    getValues
  } = useForm<UpdateTripDto>({
    defaultValues: vals,
  });
  const tripType = useWatch({ control, name: "tripType" }); // TODO: Not sure what this error means.
  const { aircraft } = useOrgAircraft(trip.organization.id);

  const minArrivalDate = useWatch({
    control,
    name: "departureInfo.date"
  })

  const maxArrivalDate = useWatch({
    control,
    name: "returnInfo.date",
  })

  const pilotName = (() => {
    // Shouldn't be empty.
    const tp = getPilotForTrip(tripPilots);
    return tp ? `${tp.pilot.firstName} ${tp.pilot.lastName}` : "";
  })();
  const pointOfContactName = `${trip.pointOfContact.firstName} ${trip.pointOfContact.lastName}`;

  // Only show aircraft that have a pilot assigned.
  const tailNumbers = aircraft
    ?.filter((a) => !!a.pilot)
    .map((a) => ({
      label: a.tailNumber,
      value: a.id,
    }));


  const validateTripDates = () => {
    const _departureDate = new Date(getValues('departureInfo.date') as string);
    const _arrivalDate = new Date(getValues('arrivalInfo.date') as string);
    const _returnDate = new Date(getValues('returnInfo.date') as string);
    if (!(_departureDate <= _arrivalDate)) {
      setErrMsg('Please check your selected dates.');
      return false;
    } else if (!(_arrivalDate <= _returnDate)) {
      setErrMsg('Please check your selected dates.');
      return false;
    }
    return true;
  }

  const __onSave = ( d: UpdateTripDto ) => {
    if ( !validateTripDates() ) return
    const dirtyFieldsToVals = (
      d: UpdateTripDto,
      dirtyFields: TripDirtyFields
    ) => {
      let cr: ChangeRequest = {};
      const k = ( keys: string[], obj: Record<string, any> ) => {
        for (const [key, val] of Object.entries( obj ) ) {
          keys.push( key );

          if ( val instanceof Object ) k( keys, val );
          else if ( ["bags", "passengers"].includes( key ) ) apply( cr, keys, parseInt( get( d, keys ) ) )
          else apply( cr, keys, get( d, keys ) );

          keys.pop();
        }
      };
      k( [], dirtyFields );
      return cr;
    };
    onSave( d, { isDirty, changedFields: dirtyFieldsToVals( d, dirtyFields ) } );
  };

  const tripTypes = [
    { label: "Drop off", value: TripType.DROP_OFF },
    { label: "Standby", value: TripType.STANDBY },
  ];

  return (
    <ScreenContainer>
      <View
        style={StyleSheet.flatten([
          styles.sectionContainer,
          { borderTopWidth: 0 },
        ])}
      >
        <Text style={styles.sectionHeader}>Pilot Details</Text>
        {/* <CtlTextInput name="pointOfContact" control={control} /> */}
        <TextInput label="Pilot" value={`${pilotName}`} />
      </View>

      <View style={styles.sectionContainer}>
        <Text style={styles.sectionHeader}>Client Details</Text>
        {/* <CtlTextInput name="pointOfContact" control={control} /> */}
        <TextInput label="Company" value={`${trip.organization.name}`} />
        <View style={styles.spacer} />
        <TextInput label="Point of Contact" value={pointOfContactName} />
        {tailNumbers && (
          <>
            <View style={styles.spacer} />
            <CtlSelectInput
              name="aircraftId"
              control={control}
              label="Tail Number"
              items={tailNumbers}
            />
          </>
        )}
      </View>

      <View style={styles.sectionContainer}>
        <Text style={styles.sectionHeader}>Trip Details</Text>
        <CtlSelectInput
          name="tripType"
          control={control}
          label="Trip Type"
          items={tripTypes} />
      </View>

      <View style={styles.sectionContainer}>
        <Text style={styles.sectionHeader}>Departure</Text>
        <CtlGooglePlacesInput
          name="departureInfo.location"
          control={control}
          label="Departure Location"
        />
        <View style={styles.spacer} />
        <CtlTextInput
          name="departureInfo.airport"
          control={control}
          rules={{ ...required, ...isAirport }}
          label="Departure Airport"
          maxLength={3}
        />
        <View style={styles.spacer} />
        <CtlDatePickerInput
          name="departureInfo.date"
          control={control}
          label="Departure Date"
        />
        <View style={styles.spacer} />
        <CtlTimeInput
          name="departureInfo.time"
          control={control}
          label="Departure Time"
        />
      </View>

      <View style={styles.sectionContainer}>
        <Text style={styles.sectionHeader}>Arrival</Text>
        <CtlGooglePlacesInput
          name="arrivalInfo.location"
          control={control}
          label="Arrival Location"
        />
        <View style={styles.spacer} />
        <CtlTextInput
          name="arrivalInfo.airport"
          control={control}
          rules={{ ...required, ...isAirport }}
          label="Arrival Airport"
          maxLength={3}
        />
        <View style={styles.spacer} />
        <CtlDatePickerInput
          name="arrivalInfo.date"
          control={control}
          label="Arrival Date"
          startDate={new Date(minArrivalDate as string)}
          endDate={new Date(maxArrivalDate as string)}
        />
        <View style={styles.spacer} />
        <CtlTimeInput
          name="arrivalInfo.time"
          control={control}
          label="Arrival Time"
        />
      </View>

      {tripType === TripType.STANDBY ? (
        <View style={styles.sectionContainer}>
          <Text style={styles.sectionHeader}>Return</Text>
          <CtlGooglePlacesInput
            name="returnInfo.location"
            control={control}
            label="Return Location"
          />
          <View style={styles.spacer} />
          <CtlTextInput
            name="returnInfo.airport"
            control={control}
            rules={{ ...required, ...isAirport }}
            label="Return Airport"
            maxLength={3}
          />
          <View style={styles.spacer} />
          <CtlDatePickerInput
            name="returnInfo.date"
            control={control}
            label="Return Date"
          />
          <View style={styles.spacer} />
          <CtlTimeInput
            name="returnInfo.time"
            control={control}
            label="Return Time"
          />
        </View>
      ) : null}

      <View style={styles.sectionContainer}>
        <Text style={styles.sectionHeader}>Additional Details</Text>
        <CtlTextInput
          name="passengers"
          control={control}
          // containerStyle={pageStyles.input}
          keyboardType="numeric"
          label="Passengers"
          optional
          placeholder="3"
        />
        <View style={styles.spacer} />
        <CtlTextInput
          name="bags"
          control={control}
          // containerStyle={pageStyles.input}
          keyboardType="numeric"
          label="Bags"
          optional
          placeholder="3"
        />
        <View style={styles.spacer} />
        <CtlTextInput
          name="notes"
          control={control}
          label="Notes for the pilot:"
          placeholder="We will be flying with our dog, etc."
          optional
          multiline
          numberOfLines={5}
        />
      </View>
      <Text style={styles.errMsg}>{errMsg}</Text>
      <Button
        title={saveBtnLabel || "Save"}
        color="success"
        onPress={handleSubmit(__onSave)}
        disabled={disableBtns}
      />
      <View style={styles.spacer} />
      <SubtleButton
        title={cancelBtnLabel || "Go Back"}
        onPress={onCancel}
        disabled={disableBtns}
      />
      <View style={styles.spacer} />
    </ScreenContainer>
  );
}

const styles = StyleSheet.create({
  sectionContainer: {
    ...SharedStyles.sectionContainer,
  },
  sectionHeader: {
    ...SharedStyles.sectionHeader,
  },
  spacer: {
    ...Theme.spacer,
  },
  errMsg: {
    ...Theme.errorMessage,
  },
});
