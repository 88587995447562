import { StyleSheet, ViewStyle } from "react-native";
import format from "date-fns/format";

import { TripDetail } from "../types/Trip";
import { extractAirportCodeFromName } from "../util/trips";
import { isValidDate } from "../util/utils";
import { Text, View } from "./Themed";

type TripDetailVisualProps = {
  departure: TripDetail;
  arrival: TripDetail;
  departureColor: string;
  arrivalColor: string;

  style?: ViewStyle;
};

export default function TripDetailVisual({
  departure,
  arrival,
  departureColor,
  arrivalColor,
  style,
}: TripDetailVisualProps) {
  const EMPTY_AIRPORT = "No airport selected";
  const EMPTY_AIRPORT_CODE = "N/A";

  const depAirport = departure.airport ? departure.airport : EMPTY_AIRPORT;
  const depAirportCode = departure.airport
    ? extractAirportCodeFromName(departure.airport)
    : EMPTY_AIRPORT_CODE;

  const arrivalAirport = arrival.airport ? arrival.airport : EMPTY_AIRPORT;
  const arrivalAirportCode = arrival.airport
    ? extractAirportCodeFromName(arrival.airport)
    : EMPTY_AIRPORT_CODE;

  return (
    <View style={style}>
      <View style={styles.detailContainer}>
        <View style={styles.circleContainer}>
          <View
            style={StyleSheet.flatten([
              styles.circle,
              { backgroundColor: departureColor },
            ])}
          >
            <Text style={styles.circleText}>{depAirportCode}</Text>
          </View>
        </View>
        <View style={styles.infoContainer}>
          <Text style={styles.emphasis} numberOfLines={1}>
            {depAirport}
          </Text>
          <Text>{formatLocation(departure)}</Text>
          <Text>{formatDate(departure)} {departure.date && departure.time ? `at ${departure.time}` : ''}</Text>
        </View>
      </View>

      <View style={styles.spacer} />

      <View style={styles.detailContainer}>
        <View style={styles.circleContainer}>
          <View
            style={StyleSheet.flatten([
              styles.circle,
              { backgroundColor: arrivalColor },
            ])}
          >
            <Text style={styles.circleText}>{arrivalAirportCode}</Text>
          </View>
        </View>
        <View style={styles.infoContainer}>
          <Text style={styles.emphasis} numberOfLines={1}>
            {arrivalAirport}
          </Text>
          <Text>{formatLocation(arrival)}</Text>
          <Text>{formatDate(arrival)} {arrival.date && arrival.time ? `at ${arrival.time}` : ''}</Text>
        </View>
      </View>
    </View>
  );
}

const formatLocation = ({ location }: TripDetail) => {
  return `${location.city}, ${location.state}`;
};

const formatDate = (d: TripDetail) => {
  if (!d.date) return "";
  const date = new Date(d.date);
  if (!isValidDate(date)) return "";
  return format(date, "LLLL do");
};

const styles = StyleSheet.create({
  detailContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  circleContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    paddingVertical: 4,
  },
  circle: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 999,
    borderColor: "black",
    borderWidth: 2,
    backgroundColor: "red",
    width: 48,
    height: 48,
  },
  circleText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "700",
	textTransform: "uppercase"
  },
  infoContainer: {
    flex: 1,
    marginLeft: 16,
  },
  emphasis: {
    flex: 1,
    fontSize: 16,
    fontWeight: "700",
	textTransform: "uppercase"
  },
  spacer: {
    width: 0,
    height: 60,
    zIndex: -1,
    marginLeft: 23,
    marginVertical: -20,
    borderWidth: 2,
    borderStyle: "dashed",
  },
});
