import { TouchableOpacity, Image, StyleSheet } from "react-native";
import { FieldError } from "react-hook-form";
import { Ionicons } from "@expo/vector-icons";
import * as DocumentPicker from "expo-document-picker";

import { View, Text } from "./Themed";
import Colors from "../constants/Colors";
import { FileData } from "../types/FileData";

const personStroke = {
  uri: require("../assets/images/person_stroke_icon.png"),
};

type PhotoInputProps = {
  label: string;
  icon?: "plus" | "user";
  value?: FileData | null;
  onChange?: (v: DocumentPicker.DocumentResult) => void;
  error?: FieldError | undefined;
};

export default function PhotoInput(props: PhotoInputProps) {
  const label = props.label || "";
  const icon = props.icon || "add-circle-outline";
  const value = props.value?.type === "success" ? props.value : null;

  // Same exact implementation as FileInput.
  const getFile = async () => {
    const doc = await DocumentPicker.getDocumentAsync({
      type: ["image/png", "image/jpeg"],
    });
    if (doc.type !== "success") {
      return;
    }
    const { type, mimeType, name, uri, size }: FileData = doc;
    props.onChange &&
      props.onChange({
        type,
        mimeType,
        name,
        uri,
        size,
      });
  };

  const noValuePreview =
    icon === "plus" ? (
      <Ionicons name="add-outline" size={30} color={Colors.theme.lightGray} />
    ) : (
      <Image
        source={personStroke}
        style={StyleSheet.flatten([styles.image, styles.imagePreview])}
        resizeMode="contain"
      />
    );

  return (
    <TouchableOpacity onPress={getFile}>
      <View style={styles.container}>
        <View
          style={StyleSheet.flatten([
            styles.imageContainer,
            value ? { borderColor: "#000" } : null,
          ])}
        >
          {value ? (
            <Image
              source={{ uri: value.uri }}
              style={styles.image}
              resizeMode="cover"
            />
          ) : (
            noValuePreview
          )}
        </View>
        <Text>{label}</Text>
        <Text style={styles.errorMsg}>
          {props.error && props.error.message}
        </Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  imageContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: 80,
    height: 80,
    borderWidth: 3,
    borderRadius: 999,
    borderColor: Colors.theme.lightGray,
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  imagePreview: {
    width: 60,
    height: 60,
  },
  errorMsg: {
    color: Colors.theme.danger,
    textAlign: "center",
  },
});
