import * as React from "react";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { StyleSheet } from "react-native";
import { useMutation, useQueryClient } from "react-query";

import Theme from "../../constants/Theme";
import { BaseHeader } from "../../components/BaseHeader";
import { Text, View } from "../../components/Themed";
import { Button } from "../../components/Button";
import SubtleButton from "../../components/SubtleButton";
import { ConfirmDeleteMemberScreenProps } from "../../navigation/types";
import { api } from "../../api";
import { useMember } from "../../hooks/useUser";
import { MemberRole } from "../../types/User";

export default function ConfirmDeleteMemberScreen({
  navigation,
  route,
}: ConfirmDeleteMemberScreenProps) {
  const { user } = useMember();

  //! RBAC
  React.useEffect(() => {
    if (!user?.role) return;
    if(!([MemberRole.SUPERADMIN].includes(user?.role))) {
      //* Replace screen on stack to prevent returning to this screen on back button
      navigation.replace('NotFound')
    }
  }, [])
  //!

  const { id } = route.params;
  const queryClient = useQueryClient();
  const deleteMutation = useMutation(() => api.deleteUser(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
    },
  });
  const [errMsg, setErrMsg] = React.useState("");

  const deleteUser = async () => {
    try {
      setErrMsg("");
      await deleteMutation.mutateAsync();
      navigation.navigate("Members");
    } catch (err) {
      setErrMsg(
        "There was an error deleting this user. Please try again later."
      );
    }
  };

  const cancel = () => {
    if (navigation.canGoBack()) navigation.goBack();
    else navigation.navigate("ViewMember", { id });
  };

  return (
    <View style={Theme.page}>
      <Text style={styles.header}>Are you sure?</Text>
      <Text style={styles.subheader}>
        Once this user is deleted they will no longer have access to your
        organization’s account.
      </Text>
      <Text style={styles.errMsg}>{errMsg}</Text>
      <Button
        title="Confirm Change"
        onPress={deleteUser}
        variant="large"
        color="danger"
        style={styles.confirmBtn}
      />
      <SubtleButton
        title="Oops! I don't want to delete this user."
        onPress={cancel}
      />
    </View>
  );
}

export function ConfirmDeleteMemberScreenHeader({
  route,
  navigation,
}: NativeStackHeaderProps) {
  const { id } = route.params as any;

  const onBack = () => {
    if (navigation.canGoBack()) navigation.goBack();
    else navigation.navigate("ViewMember", { id });
  };

  return <BaseHeader leftIcon="back" color="black" onBack={onBack} />;
}

const styles = StyleSheet.create({
  header: {
    ...Theme.h1,
    textAlign: "center",
  },
  subheader: {
    textAlign: "center",
  },
  errMsg: {
    ...Theme.errorMessage,
  },
  confirmBtn: {
    alignSelf: "center",
    marginBottom: 20,
  },
});
