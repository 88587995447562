import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller, FieldError } from "react-hook-form";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { styles } from "../RequestTripScreen";
import { RootState } from "../../store";
import Theme from "../../constants/Theme";
import { Button } from "../../components/Button";
import { View, Text } from "../../components/Themed";
import { DetailType } from "../../types/Trip";
import { setDetail } from "../../store/requestTrip";
import { DetailFormData } from "../../store/requestTrip";
import { RequestTripScreenProps } from "../../navigation/types";
import CtlTextInput from "../../components/ControlledInputs/CtlTextInput";
import CtlGooglePlacesInput from "../../components/ControlledInputs/CtlGooglePlacesInput";
import CtlDatePickerInput from "../../components/ControlledInputs/CtlDatePickerInput";
import { googlePlacesRequired, required } from "../../util/formRules";
import CtlTimeInput from "../../components/ControlledInputs/CtlTimeInput";



export default function DepartInfoScreen({
  navigation,
}: RequestTripScreenProps) {
  const departureInfo = useSelector(
    (state: RootState) => state.requestTrip.departureInfo
  );
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<DetailFormData>({
    defaultValues: departureInfo,
  });

  const submit = (d: DetailFormData) => {
    dispatch(setDetail({ detailType: DetailType.DEPARTURE, data: d }));
    navigation.push("ArrivalInfo");
  };

  return (
    <KeyboardAwareScrollView
      style={Theme.page}
      contentContainerStyle={Theme.pageContent}
    >
      <View>
        <Text style={styles.header}>
          When and where will you{"\n"}be departing?
        </Text>
        <CtlGooglePlacesInput
          name="location"
          control={control}
          label="Departure Location"
          rules={googlePlacesRequired}
        />
        <View style={styles.spacer} />
        <CtlTextInput
          name="airport"
          control={control}
          label={`Departure Airport`}
          optional
          placeholder="Enter 3-character airport (IATA) code."
          maxLength={3}
        />
        <View style={styles.spacer} />
        <CtlTextInput
          name="preferredFBO"
          control={control}
          label="Preferred FBO"
          optional
          placeholder="Add if you have a preference."
        />
        <View style={styles.spacer} />



        <CtlDatePickerInput
          name="date"
          control={control}
          rules={required}
          label={`Departure Date`}           
        />
        
        
        <View style={styles.spacer} />
        <CtlTimeInput
          name="time"
          control={control}
          label="Departure Time"
          placeholder="Anytime"
        />
        <View style={styles.spacer} />
      </View>
      <View>
        <Button
          title="Next"
          style={styles.nextBtn}
          onPress={handleSubmit(submit)}
        />
        <View style={styles.spacer} />
      </View>
    </KeyboardAwareScrollView>
  );
}
