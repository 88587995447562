import { Image, StyleSheet, TouchableHighlight, ViewStyle } from "react-native";

import { Text, View } from "./Themed";
import Theme from "../constants/Theme";
import Colors from "../constants/Colors";

type PreviewPictureProps = {
  url: string;
  title: string;
  onPress?: () => void;
  subtitle?: string;
  style?: ViewStyle;
};

export default function PreviewPicture(props: PreviewPictureProps) {
  return (
    <View style={StyleSheet.flatten([props.style, styles.container])}>
      {props.onPress ? (
          <TouchableHighlight onPress={props.onPress} style={styles.container}>
            <>
              <Image style={styles.image} source={{ uri: props.url }} />
              <View style={styles.content}>
                {props.subtitle && (
                  <Text style={styles.subtitle}>{props.subtitle}</Text>
                )}
                <Text style={styles.title}>{props.title}</Text>
              </View>
            </>
          </TouchableHighlight>
      ) : (
        <>
          <Image style={styles.image} source={{ uri: props.url }} />
          <View style={styles.content}>
            {props.subtitle && (
              <Text style={styles.subtitle}>{props.subtitle}</Text>
            )}
            <Text style={styles.title}>{props.title}</Text>
          </View>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    ...Theme.roundedImage,
    width: 52,
    height: 52,
  },
  content: {
    marginLeft: 8,
  },
  subtitle: {
    color: Colors.theme.gold,
  },
  title: {
    fontWeight: "600",
    fontSize: 16,
  },
});
