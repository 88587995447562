import * as React from "react";
import { StyleSheet } from "react-native";
import { useForm, Controller } from "react-hook-form";
import { useNavigation } from "@react-navigation/native";
import QRCode from "react-native-qrcode-svg";

import { api } from "../api";
import LoadingIndicator from "../components/LoadingIndicator";
import { BaseHeader } from "../components/BaseHeader";
import { View, Text } from "../components/Themed";
import TextInput from "../components/TextInput";
import { InviteStackNavigationProp, QRScreenProps } from "../navigation/types";
import { Invitation } from "../types/Invitation";
import { Button } from "../components/Button";
import Colors from "../constants/Colors";
import ScreenContainer from "../components/ScreenContainer";

enum SendState {
  SUCCESS,
  ERROR,
  NONE,
}

export default function QRScreen({ route }: QRScreenProps) {
  const [tempInv, setTempInv] = React.useState<Invitation | null>(null);
  const [qrError, setQrError] = React.useState(false);
  const [sendInvLoading, setSendInvLoading] = React.useState(false);
  const [sendInvState, setSendInvState] = React.useState<SendState>(
    SendState.NONE
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const sendInvite = async ({ email }: { email: string }) => {
    try {
      setSendInvLoading(true);
      await api.sendInvitation(email, route.params.inviteType);
      setSendInvState(SendState.SUCCESS);
    } catch (err) {
      setSendInvState(SendState.ERROR);
    } finally {
      setSendInvLoading(false);
    }
  };

  React.useEffect(() => {
    async function getTemp() {
      try {
        const inv = await api.createTempInvitation(route.params.inviteType);
        setTempInv(inv);
      } catch (err) {
        setQrError(true);
      }
    }
    getTemp();
  }, []);

  return (
    <ScreenContainer>
      <View>
        {!tempInv && !qrError && <LoadingIndicator />}
        {qrError && (
          <Text style={styles.errorMsg}>
            There was an error generating the QR code.
          </Text>
        )}
        {tempInv && (
          <>
            <View style={styles.qrContainer}>
              <QRCode value={tempInv.url} size={240} />
            </View>
            <Text style={styles.scanQrText}>
              Show the QR Code and tell the invitee to scan it with their
              phone's camera.
            </Text>
            {/* <Text>{tempInv.url}</Text> */}
            <Text style={styles.fancyOr}>OR</Text>
          </>
        )}
        <Text style={styles.enterEmailText}>
          Enter the invitee's email below.
        </Text>
        <View style={{ marginTop: 24 }} />
        <Controller
          control={control}
          name="email"
          rules={{
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
            required: { value: true, message: "This field is required." },
          }}
          render={({ field }) => (
            <TextInput
              label="Email"
              placeholder="invitee@gmail.com"
              {...field}
              error={errors.email}
            />
          )}
        />

        {sendInvState === SendState.SUCCESS && (
          <Text style={styles.successMsg}>Invitation sent!</Text>
        )}
        {sendInvState === SendState.ERROR && (
          <Text style={styles.errorMsg}>
            There was an error sending the invitation. Please try again later.
          </Text>
        )}

        <Button
          title="Send Invite Code"
          style={styles.sendBtn}
          disabled={sendInvLoading}
          onPress={handleSubmit(sendInvite)}
        />
        <View style={{ marginTop: 20 }} />
      </View>
    </ScreenContainer>
  );
}

export function QRScreenHeader() {
  const navigation = useNavigation<InviteStackNavigationProp>();

  return (
    <BaseHeader
      title="Invite"
      leftIcon="back"
      color="blue"
      onBack={() =>
        navigation.canGoBack()
          ? navigation.goBack()
          : navigation.navigate("Invite")
      }
    />
  );
}

const styles = StyleSheet.create({
  qrContainer: {
    alignItems: "center",
  },
  scanQrText: {
    fontWeight: "600",
    fontSize: 16,
    textAlign: "center",
    marginTop: 16,
  },
  fancyOr: {
    color: Colors.theme.gold,
    fontSize: 24,
    fontWeight: "600",
    textTransform: "uppercase",
    textAlign: "center",
    marginTop: 24,
  },
  enterEmailText: {
    fontWeight: "600",
    fontSize: 16,
    textAlign: "center",
    marginTop: 24,
  },
  sendBtn: {
    marginTop: 16,
  },
  successMsg: {
    color: Colors.theme.success,
    fontWeight: "600",
    textAlign: "center",
    marginVertical: 8,
  },
  errorMsg: {
    color: Colors.theme.danger,
    textAlign: "center",
  },
});
