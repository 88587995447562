import { useQuery } from "react-query";

import { api } from "../api";
import { UserType } from "../types/User";
import useUser from "./useUser";

export function useTrip(tripId: string) {
  const { data, ...rest } = useQuery(["trips", tripId], () =>
    api.getTrip(tripId)
  );
  return { trip: data, ...rest };
}

export function useTripPilots(tripId: string) {
  const { data: tripPilots, ...rest } = useQuery(
    ["trips", tripId, "pilots"],
    () => api.getTripPilots(tripId)
  );
  return { tripPilots, ...rest };
}

export function useTripChangeRequest(tripId: string) {
  const { data: trip, ...rest } = useQuery(
    ["trips", tripId, "change-request", "test"],
    () => api.getTripChangeRequestTest(tripId)
  );
  return { trip, ...rest };
}

export function useTrips() {
  const { user } = useUser();
  const isPilot = user?.type === UserType.PILOT;

  // For org members.
  const { data: clientTrips, ...clientRest } = useQuery(
    [`users`, user?.id, "trips"],
    () => api.getTripsByUser(user?.id as string),
    { enabled: !!user?.id, refetchInterval: 30000, refetchIntervalInBackground: true }
  );

  // For pilots.
  const { data: pilotTrips, ...pilotRest } = useQuery(
    ["pilots", user?.id, "trips"],
    () => api.getTripsByPilot(user?.id as string),
    { enabled: isPilot, refetchInterval: 30000, refetchIntervalInBackground: true }
  );

  const trips = isPilot ? pilotTrips : clientTrips;
  const rest = isPilot ? pilotRest : clientRest;
  return {
    trips,
    ...rest,
  };
}

export function useOrgTrips() {
  const { user } = useUser();

  const { data: orgTrips, ...rest } = useQuery(
    ['org', "trips"],
    // @ts-ignore
    () => api.getTripsByOrg(user?.organization as string),
    { enabled: !!user?.id, refetchInterval: 30000, refetchIntervalInBackground: true }
  );

  return { orgTrips, ...rest };
}

export function useSubcontractorTrips() {
  const { user } = useUser();
  const { data: subcontractorTrips, ...rest } = useQuery(
    ["pilots", user?.id, "subcontractor-trips"],
    () => api.getSubcontractorTrips(user?.id as string),
    { enabled: !!user?.id, refetchInterval: 30000, refetchIntervalInBackground: true }
  );

  return { subcontractorTrips, ...rest };
}
