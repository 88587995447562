import * as React from "react";
import { StyleSheet } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { styles } from "../RequestTripScreen";
import Theme from "../../constants/Theme";
import { View, Text } from "../../components/Themed";
import CtlGooglePlacesInput from "../../components/ControlledInputs/CtlGooglePlacesInput";
import CtlTextInput from "../../components/ControlledInputs/CtlTextInput";
import { Button } from "../../components/Button";
import { RootState } from "../../store";
import { DetailType } from "../../types/Trip";
import { setDetail } from "../../store/requestTrip";
import { DetailFormData } from "../../store/requestTrip";
import { RequestTripScreenProps } from "../../navigation/types";
import CtlDatePickerInput from "../../components/ControlledInputs/CtlDatePickerInput";
import { googlePlacesRequired, required } from "../../util/formRules";
import CtlTimeInput from "../../components/ControlledInputs/CtlTimeInput";

export default function ReturnInfoScreen({
  navigation,
}: RequestTripScreenProps) {
  const departureInfo = useSelector(
    (state: RootState) => state.requestTrip.departureInfo
  );
  const returnInfo = useSelector(
    (state: RootState) => state.requestTrip.returnInfo
  );
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue } = useForm<DetailFormData>({
    defaultValues: returnInfo,
  });

  const returnHome = () => {
    setValue("location", departureInfo.location);
    setValue("airport", departureInfo.airport);
  };

  const submit = (d: DetailFormData) => {
    dispatch(setDetail({ detailType: DetailType.RETURN, data: d }));
    navigation.push("MoreDetails");
    // console.log(d,DetailType);
    
  };

  const minReturnDate = new Date(departureInfo.date);

  return (
    <KeyboardAwareScrollView
      style={Theme.page}
      contentContainerStyle={Theme.pageContent}
    >
      <View>
        <Text style={styles.header}>
          When and where will you{"\n"}be returning to?
        </Text>

        <Button
          title="Same as Departure"
          style={pageStyles.backHomeBtn}
          onPress={returnHome}
        />
        <CtlGooglePlacesInput
          name="location"
          control={control}
          label="Return Location"
          rules={googlePlacesRequired}
        />
        <View style={styles.spacer} />
        <CtlTextInput
          name="airport"
          control={control}
          label={`Return Airport`}
          optional
          placeholder="Enter 3-character airport (IATA) code."
          maxLength={3}
        />
        <View style={styles.spacer} />
        <CtlDatePickerInput
          name="date"
          control={control}
          rules={required}
          label={`Return Date`}
          startDate={minReturnDate}
        />
        <View style={styles.spacer} />
        <CtlTimeInput
          name="time"
          control={control}
          rules={required}
          label="Return Time"
          placeholder="Anytime, 12:00pm, etc."
        />
        <View style={styles.spacer} />
      </View>
      <View>
        <Button
          title="Next"
          style={styles.nextBtn}
          onPress={handleSubmit(submit)}
        />
        <View style={styles.spacer} />
      </View>
    </KeyboardAwareScrollView>
  );
}

const pageStyles = StyleSheet.create({
  backHomeBtn: {
    marginBottom: 12,
    alignSelf: "center",
  },
});
