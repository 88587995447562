import * as React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { useMember } from "../../hooks/useUser";
import { MemberRole } from "../../types/User";

import { View, Text } from "../../components/Themed";
import { Button } from "../../components/Button";
import { ConfirmDeleteScreenProps } from "../../navigation/types";
import Theme from "../../constants/Theme";
import { useMutation, useQueryClient } from "react-query";
import { api } from "../../api";
import LoadingIndicator from "../../components/LoadingIndicator";

export default function ConfirmDeleteModal({
  route,
  navigation,
}: ConfirmDeleteScreenProps) {
  const { user } = useMember();
  const aircraftId = route.params.id;
  const queryClient = useQueryClient();
  const mutation = useMutation(() => api.deleteAircraft(aircraftId), {
    onSuccess: () => {
      queryClient.invalidateQueries("organizations");
    },
  });
  const [errMsg, setErrMsg] = React.useState("");

  const confirm = async () => {
    try {
      setErrMsg("");
      await mutation.mutateAsync();
      navigation.navigate("Aircraft");
    } catch (err) {
      setErrMsg("An error occurred deleting this aircraft.");
    }
  };

  const goBack = () => {
    navigation.goBack();
  };

  //! RBAC
  React.useEffect( () => {
    if ( !user ) return;
    if ( !user.role ) return;
    if( !( [MemberRole.SUPERADMIN, MemberRole.ADMIN].includes( user.role ) ) ) {
      //* Replace screen on stack to prevent returning to this screen on back button
      navigation.replace( 'Aircraft' );
    }
  }, [] )
  //!

  return (
    <View style={styles.container}>
      <Text style={styles.header}>Confirm Change</Text>
      <Text style={styles.message}>
        Are you sure you would like to remove this aircraft? It will no longer
        be accessible by your pilots.
      </Text>
      <Text style={Theme.errorMessage}>{errMsg}</Text>

      {mutation.isLoading && <LoadingIndicator />}
      {!mutation.isLoading && (
        <>
          <Button
            title="Confirm Change"
            color="danger"
            variant="large"
            onPress={confirm}
          />
          <TouchableOpacity style={{ marginTop: 24 }} onPress={goBack}>
            <Text style={styles.cancelBtn}>
              Oops! I don't want to remove this aircraft.
            </Text>
          </TouchableOpacity>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    ...Theme.page,
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    ...Theme.h1,
    textAlign: "center",
  },
  message: {
    textAlign: "center",
    marginBottom: 40,
    marginTop: 8,
  },
  cancelBtn: {
    textDecorationLine: "underline",
  },
});
