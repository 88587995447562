/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
  useNavigationContainerRef,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import * as React from "react";
import { ColorSchemeName, View, Button } from "react-native";

// Auth screens/
import HomeScreen, { HomeScreenHeader } from "../screens/HomeScreen";
import TripScreen, { TripScreenHeader } from "../screens/TripScreen";
import BookTripScreen, { BookTripScreenHeader } from "../screens/BookTripScreen";
import SubcontractTripScreen, {
  SubcontractTripScreenHeader,
} from "../screens/Trip/Subcontract";
import SubcontractConfirmTripScreen, {
  SubcontractConfirmTripScreenHeader,
} from "../screens/Trip/SubcontractConfirm";
import ApproveTripDetailsScreen, {
  ApproveTripDetailsScreenHeader,
} from "../screens/ApproveTripDetailsScreen";
import ReviewChangeRequestScreen, {
  ReviewChangeRequestScreenHeader,
} from "../screens/Trip/ReviewChangeRequest";
import TripScheduledModal from "../screens/Trip/TripScheduledModal";
import EnterHobbsScreen, {
  EnterHobbsScreenHeader,
} from "../screens/Trip/EnterHobbs";
import EditTripScreen, {
  EditTripScreenHeader,
} from "../screens/EditTripScreen";
import ConfirmCancelTripScreen,
{ ConfirmCancelTripScreenHeader }
  from "../screens/Trip/confirmTripCancellation";
import RequestTripScreen, {
  RequestTripScreenHeader,
} from "../screens/RequestTripScreen";
import DepartInfoScreen from "../screens/RequestTrip/DepartInfo";
import ArrivalInfoScreen from "../screens/RequestTrip/ArrivalInfo";
import ReturnInfoScreen from "../screens/RequestTrip/ReturnInfo";
import MoreDetailsScreen from "../screens/RequestTrip/MoreDetails";
import RequestTripSuccessScreen, {
  RequestTripSuccessScreenHeader,
} from "../screens/RequestTrip/Success";
import PastTripsScreen, {
  PastTripsScreenHeader,
} from "../screens/PastTripsScreen";
import ContactsScreen, {
  ContactsScreenHeader,
} from "../screens/ContactsScreen";
import ViewOrganizationScreen, {
  ViewOrganizationScreenHeader,
} from "../screens/ViewOrganizationScreen";
import ViewUserScreen, {
  ViewUserScreenHeader,
} from "../screens/ViewUserScreen";
import InviteScreen, { InviteScreenHeader } from "../screens/InviteScreen";
import QRScreen, { QRScreenHeader } from "../screens/QRScreen";

import MembersScreen, { MembersScreenHeader } from "../screens/MembersScreen";
import ViewMemberScreen, {
  ViewMemberScreenHeader,
} from "../screens/Members/ViewMemberScreen";
import AddMemberScreen, {
  AddMemberScreenHeader,
} from "../screens/Members/AddMemberScreen";
import ChangeRoleScreen, {
  ChangeRoleScreenHeader,
} from "../screens/Members/ChangeRole";
import ConfirmDeleteMemberScreen, {
  ConfirmDeleteMemberScreenHeader,
} from "../screens/Members/ConfirmDeleteMember";

import PilotsScreen, { PilotsScreenHeader } from "../screens/PilotsScreen";
import ViewPilotScreen, {
  ViewPilotScreenHeader,
} from "../screens/Pilots/ViewPilot";
import AircraftScreen, {
  AircraftScreenHeader,
} from "../screens/AircraftScreen";
import ViewAircraftScreen, {
  ViewAircraftScreenHeader,
} from "../screens/Aircraft/ViewAircraft";
import ConfirmDeleteModal from "../screens/Aircraft/ConfirmDeleteModal";
import AddAircraftScreen, {
  AddAircraftScreenHeader,
} from "../screens/Aircraft/AddAircraft";
import EditAircraftScreen, {
  EditAircraftScreenHeader,
} from "../screens/Aircraft/EditAircraft";
import ConfirmDeletePilotScreen,
{ ConfirmDeletePilotScreenHeader }
  from '../screens/Pilots/ConfirmDeletePilot';

import StripeScreen, { StripeScreenHeader } from "../screens/Stripe";

// Unauth screens.
import LoginScreen, { LoginHeader } from "../screens/LoginScreen";
import ForgotPasswordScreen, { ForgotPasswordHeader } from "../screens/ForgotPasswordScreen";
import ConfirmEmailScreen, { ConfirmEmailHeader } from "../screens/ConfirmEmailScreen";
import UpdatePasswordScreen, { UpdatePasswordHeader } from "../screens/UpdatePasswordScreen";
import CreateAccountScreen, {
  CreateAccountScreenHeader,
} from "../screens/CreateAccountScreen";
import BasicInfoScreen from "../screens/CreateAccount/BasicInfo";
import OrganizationInfoScreen from "../screens/CreateAccount/OrganizationInfo";
import SetPasswordScreen from "../screens/CreateAccount/SetPassword";
import PilotInformationScreen from "../screens/CreateAccount/PilotInformation";
import TermsAndInfoScreen from "../screens/CreateAccount/TermsAndInfo";
import TermsAndConditionsScreen from "../screens/CreateAccount/TermsAndConditions";
import OtherInfoScreen from "../screens/CreateAccount/OtherInfo";
import SuccessScreen, {
  SuccessScreenHeader,
} from "../screens/CreateAccount/Success";

import ConfirmInvScreen from "../screens/CreateAccount/ConfirmInv";

import NotFoundScreen from "../screens/NotFoundScreen";
import {
  RootStackParamList,
  UnauthStackParamList,
  AuthDrawerParamList,
  AuthDrawerWrapperParamList,
  HomeStackParamList,
  ContactsStackParamList,
  InviteStackParamList,
  HandleInviteStackParamList,
  UnauthProps,
  MembersStackParamList,
  AircraftStackParamList,
  PilotsStackParamList,
  RequestTripStackParamList,
} from "./types";
import LinkingConfiguration from "./LinkingConfiguration";
import { AuthDrawerContent } from "../components/AuthDrawer";
import { NotificationDrawerContent } from '../components/notificationDrawer';
import { useAuth, useUser } from "../hooks";
import { UserType } from "../types/User";
import { useMember } from "../hooks/useUser";

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  const { user } = useAuth();

  return (
    <Stack.Navigator>
      {user ? (
        <Stack.Group>
          <Stack.Screen
            name="Auth"
            component={AuthDrawerWrapperNavigator}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="NotFound"
            component={NotFoundScreen}
            options={{ title: "Oops!" }}
          />
        </Stack.Group>
      ) : (
        <Stack.Group>
          <Stack.Screen
            name="Unauth"
            component={UnauthStackNavigator}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="HandleInvite"
            component={HandleInviteStackNavigator}
            options={{ headerShown: false }}
          />
        </Stack.Group>
      )}
    </Stack.Navigator>
  );
}

// Wrapper drawer navigator, only for notifications.
const AuthNotifDrawer = createDrawerNavigator<AuthDrawerWrapperParamList>();
// Main drawer navigator.
const AuthDrawer = createDrawerNavigator<AuthDrawerParamList>();

function AuthDrawerWrapperNavigator() {
  return (
    <AuthNotifDrawer.Navigator
      screenOptions={{
        drawerPosition: "right",
        drawerType: "back",
        headerShown: false,
        sceneContainerStyle: { overflow: "visible" },
        drawerStyle: { opacity: 1 },
        title: "Notifications",
        unmountOnBlur: true
      }}
      initialRouteName="AuthMain"
      drawerContent={(props) => <NotificationDrawerContent {...props} />}
    >
      <AuthNotifDrawer.Screen name="AuthMain" component={AuthDrawerNavigator} options={{ drawerItemStyle: { height: 0 } }} />
    </AuthNotifDrawer.Navigator>
  );
}

function AuthDrawerNavigator() {
  const { user } = useUser();
  return (
    <>
      {
        ( user && user.type === UserType.PILOT && user.firstLogin ) ? (
          <AuthDrawer.Navigator
            initialRouteName="Stripe"
            drawerContent={(props) => <AuthDrawerContent {...props} />}
          >
            <AuthDrawer.Screen
              name="Stripe"
              component={StripeScreen}
              options={{ header: () => <StripeScreenHeader /> }}
            />
          </AuthDrawer.Navigator>
        ) : (
          <AuthDrawer.Navigator
            initialRouteName="HomeStack"
            drawerContent={(props) => <AuthDrawerContent {...props} />}
          >
            <AuthDrawer.Screen
              name="HomeStack"
              component={HomeStackNavigator}
              options={{ headerShown: false }}
            />
            <AuthDrawer.Screen
              name="ContactsStack"
              component={ContactsStackNavigator}
              options={{ headerShown: false }}
            />
            <AuthDrawer.Screen
              name="InviteStack"
              component={InviteStackNavigator}
              options={{ headerShown: false }}
            />
            <AuthDrawer.Screen
              name="MembersStack"
              component={MembersStackNavigator}
              options={{ headerShown: false }}
            />
            <AuthDrawer.Screen
              name="AircraftStack"
              component={AircraftStackNavigator}
              options={{ headerShown: false }}
            />
            <AuthDrawer.Screen
              name="PilotsStack"
              component={PilotsStackNavigator}
              options={{ headerShown: false }}
            />
            <AuthDrawer.Screen
              name="Stripe"
              component={StripeScreen}
              options={{ header: () => <StripeScreenHeader /> }}
            />
          </AuthDrawer.Navigator>
        )
      }
    </>
  );
}

const HomeStack = createNativeStackNavigator<HomeStackParamList>();
function HomeStackNavigator() {
  return (
    <HomeStack.Navigator initialRouteName="Home">
      <HomeStack.Screen
        name="Home"
        component={HomeScreen}
        options={{ header: (props) => <HomeScreenHeader /> }}
      />
	  <HomeStack.Screen
        name="Trip"
        component={TripScreen}
        options={{ header: (props) => <TripScreenHeader {...props} /> }}
      />
      <HomeStack.Screen
        name="PastTrips"
        component={PastTripsScreen}
        options={{ header: (props) => <PastTripsScreenHeader /> }}
      />
      <HomeStack.Screen
        name="Subcontract"
        component={SubcontractTripScreen}
        options={{
          header: (props) => <SubcontractTripScreenHeader {...props} />,
        }}
      />
      <HomeStack.Screen
        name="SubcontractConfirm"
        component={SubcontractConfirmTripScreen}
        options={{
          header: (props) => <SubcontractConfirmTripScreenHeader {...props} />,
        }}
      />
      <HomeStack.Screen
        name="ApproveTripDetails"
        component={ApproveTripDetailsScreen}
        options={{
          header: (props) => <ApproveTripDetailsScreenHeader {...props} />,
        }}
      />
      <HomeStack.Screen
        name="ReviewChangeRequest"
        component={ReviewChangeRequestScreen}
        options={{
          header: (props) => <ReviewChangeRequestScreenHeader {...props} />,
        }}
      />
      <HomeStack.Screen
        name="TripScheduled"
        component={TripScheduledModal}
        options={{
          headerShown: false,
          presentation: "modal",
        }}
      />
      <HomeStack.Screen
        name="EnterHobbs"
        component={EnterHobbsScreen}
        options={{
          header: (props) => <EnterHobbsScreenHeader {...props} />,
        }}
      />
      <HomeStack.Screen
        name="EditTrip"
        component={EditTripScreen}
        options={{ header: (props) => <EditTripScreenHeader {...props} /> }}
      />
      <HomeStack.Screen
        name="BookTrip"
        component={BookTripScreen}
        options={{ header: (props) => <BookTripScreenHeader {...props} /> }}
      />
      <HomeStack.Screen
        name="ConfirmCancelTrip"
        component={ConfirmCancelTripScreen}
        options={{ header: (props) => <ConfirmCancelTripScreenHeader {...props} /> }}
      />
      <HomeStack.Screen
        name="RequestTripStack"
        component={RequestTripStackNavigator}
        options={{ headerShown: false }}
      />
	  <HomeStack.Screen
	  	name="Stripe"
		component={StripeScreen}
		options={{ header: () => <StripeScreenHeader /> }}
		/>
    </HomeStack.Navigator>
  );
}

const RequestTripStack =
  createNativeStackNavigator<RequestTripStackParamList>();
function RequestTripStackNavigator() {
  return (
    <RequestTripStack.Navigator initialRouteName="TripInfo">
      <RequestTripStack.Screen
        name="TripInfo"
        component={RequestTripScreen}
        options={{ header: () => <RequestTripScreenHeader /> }}
      />
      <RequestTripStack.Screen
        name="DepartInfo"
        component={DepartInfoScreen}
        options={{ header: () => <RequestTripScreenHeader /> }}
      />
      <RequestTripStack.Screen
        name="ArrivalInfo"
        component={ArrivalInfoScreen}
        options={{ header: () => <RequestTripScreenHeader /> }}
      />
      <RequestTripStack.Screen
        name="ReturnInfo"
        component={ReturnInfoScreen}
        options={{ header: () => <RequestTripScreenHeader /> }}
      />
      <RequestTripStack.Screen
        name="MoreDetails"
        component={MoreDetailsScreen}
        options={{ header: () => <RequestTripScreenHeader /> }}
      />
      <RequestTripStack.Screen
        name="Success"
        component={RequestTripSuccessScreen}
        options={{ header: () => <RequestTripSuccessScreenHeader /> }}
      />
    </RequestTripStack.Navigator>
  );
}

const ContactsStack = createNativeStackNavigator<ContactsStackParamList>();
function ContactsStackNavigator() {
  return (
    <ContactsStack.Navigator initialRouteName="Contacts">
      <ContactsStack.Screen
        name="Contacts"
        component={ContactsScreen}
        options={{ header: (props) => <ContactsScreenHeader /> }}
      />
      <ContactsStack.Screen
        name="ViewOrganization"
        component={ViewOrganizationScreen}
        options={{ header: (props) => <ViewOrganizationScreenHeader /> }}
      />
      <ContactsStack.Screen
        name="ViewUser"
        component={ViewUserScreen}
        options={{ header: (props) => <ViewUserScreenHeader /> }}
      />
    </ContactsStack.Navigator>
  );
}

const InviteStack = createNativeStackNavigator<InviteStackParamList>();
function InviteStackNavigator() {
  return (
    <InviteStack.Navigator initialRouteName="Invite">
      <InviteStack.Screen
        name="Invite"
        component={InviteScreen}
        options={{ header: (props) => <InviteScreenHeader /> }}
      />
      <InviteStack.Screen
        name="QR"
        component={QRScreen}
        options={{ header: (props) => <QRScreenHeader /> }}
      />
    </InviteStack.Navigator>
  );
}

const MembersStack = createNativeStackNavigator<MembersStackParamList>();
function MembersStackNavigator() {
  const { user } = useMember();
  return (
    <MembersStack.Navigator initialRouteName="Members">
      <MembersStack.Screen
        name="Members"
        component={MembersScreen}
        options={{ header: () => <MembersScreenHeader /> }}
      />
      <MembersStack.Screen
        name="AddMember"
        component={AddMemberScreen}
        options={{ header: () => <AddMemberScreenHeader /> }}
      />
      <MembersStack.Screen
        name="ViewMember"
        component={ViewMemberScreen}
        options={{ header: () => <AddMemberScreenHeader /> }}
      />
      <MembersStack.Screen
        name="ChangeRole"
        component={ChangeRoleScreen}
        options={{ header: () => <ChangeRoleScreenHeader /> }}
      />
      <MembersStack.Screen
        name="ConfirmDeleteMember"
        component={ConfirmDeleteMemberScreen}
        options={{
          header: (props) => <ConfirmDeleteMemberScreenHeader {...props} />,
        }}
      />
      {/* <MembersStack.Screen name="ChangePermissions" /> */}
    </MembersStack.Navigator>
  );
}

const PilotsStack = createNativeStackNavigator<PilotsStackParamList>();
function PilotsStackNavigator() {
  return (
    <PilotsStack.Navigator initialRouteName="Pilots">
      <PilotsStack.Screen
        name="Pilots"
        component={PilotsScreen}
        options={{ header: () => <PilotsScreenHeader /> }}
      />
      <PilotsStack.Screen
        name="ViewPilot"
        component={ViewPilotScreen}
        options={{ header: () => <ViewPilotScreenHeader /> }}
      />
      <PilotsStack.Screen
        name="ConfirmDeletePilot"
        component={ConfirmDeletePilotScreen}
        options={{ header: (props) => <ConfirmDeletePilotScreenHeader {...props} /> }}
      />
    </PilotsStack.Navigator>
  );
}

const AircraftStack = createNativeStackNavigator<AircraftStackParamList>();
function AircraftStackNavigator() {
  return (
    <AircraftStack.Navigator initialRouteName="Aircraft">
      <AircraftStack.Screen
        name="Aircraft"
        component={AircraftScreen}
        options={{ header: () => <AircraftScreenHeader /> }}
      />
      <AircraftStack.Screen
        name="ViewAircraft"
        component={ViewAircraftScreen}
        options={{ header: () => <ViewAircraftScreenHeader /> }}
      />
      <AircraftStack.Screen
        name="ConfirmDelete"
        component={ConfirmDeleteModal}
        options={{ headerShown: false, presentation: "modal" }}
      />
      <AircraftStack.Screen
        name="AddAircraft"
        component={AddAircraftScreen}
        options={{ header: () => <AddAircraftScreenHeader /> }}
      />
      <AircraftStack.Screen
        name="EditAircraft"
        component={EditAircraftScreen}
        options={{ header: (props) => <EditAircraftScreenHeader {...props} /> }}
      />
    </AircraftStack.Navigator>
  );
}

const HandleInviteStack =
  createNativeStackNavigator<HandleInviteStackParamList>();

function HandleInviteStackNavigator() {
  return (
    <HandleInviteStack.Navigator initialRouteName="CreateAccount">
      <HandleInviteStack.Screen
        name="CreateAccount"
        component={CreateAccountScreen}
        options={{ header: (props) => <CreateAccountScreenHeader /> }}
      />
      <HandleInviteStack.Screen
        name="BasicInfo"
        component={BasicInfoScreen}
        options={{ header: (props) => <CreateAccountScreenHeader /> }}
      />
      <HandleInviteStack.Screen
        name="OrganizationInfo"
        component={OrganizationInfoScreen}
        options={{ header: (props) => <CreateAccountScreenHeader /> }}
      />
      <HandleInviteStack.Screen
        name="SetPassword"
        component={SetPasswordScreen}
        options={{ header: (props) => <CreateAccountScreenHeader /> }}
      />
      <HandleInviteStack.Screen
        name="PilotInformation"
        component={PilotInformationScreen}
        options={{ header: (props) => <CreateAccountScreenHeader /> }}
      />
      <HandleInviteStack.Screen
        name="TermsAndInfo"
        component={TermsAndInfoScreen}
        options={{ header: (props) => <CreateAccountScreenHeader /> }}
      />
      <HandleInviteStack.Screen
        name="TermsAndConditions"
        component={TermsAndConditionsScreen}
        options={{ header: (props) => <CreateAccountScreenHeader /> }}
      />
      <HandleInviteStack.Screen
        name="OtherInfo"
        component={OtherInfoScreen}
        options={{ header: (props) => <CreateAccountScreenHeader /> }}
      />
      <HandleInviteStack.Screen
        name="Success"
        component={SuccessScreen}
        options={{ header: (props) => <SuccessScreenHeader /> }}
      />
      <HandleInviteStack.Screen
        name="ConfirmInv"
        component={ConfirmInvScreen}
        options={{ header: () => <CreateAccountScreenHeader /> }}
      />
    </HandleInviteStack.Navigator>
  );
}

const UnauthStack = createNativeStackNavigator<UnauthStackParamList>();

function UnauthStackNavigator({ navigation }: UnauthProps) {
  return (
    <UnauthStack.Navigator>
      <UnauthStack.Screen
        name="Login"
        component={LoginScreen}
        options={{ header: (props) => <LoginHeader {...props} /> }}
      />
      <UnauthStack.Screen
        name="ForgotPassword"
        component={ForgotPasswordScreen}
        options={{ header: (props) => <ForgotPasswordHeader {...props} /> }}
      />
      <UnauthStack.Screen
        name="ConfirmEmail"
        component={ConfirmEmailScreen}
        options={{ header: (props) => <ConfirmEmailHeader {...props} /> }}
      />
      <UnauthStack.Screen
        name="UpdatePassword"
        component={UpdatePasswordScreen}
        options={{ header: (props) => <UpdatePasswordHeader {...props} /> }}
      />
    </UnauthStack.Navigator>
  );
}
