import * as React from "react";
import { View, StyleSheet } from "react-native";
import { FieldError } from "react-hook-form";

import Colors from "../constants/Colors";
import RadioButton from "./RadioButton";
import { Text } from "./Themed";

type RadioGroupProps = {
  items: Array<{ label: string; value: string }>;
  value: string | null;
  onChange: (v: string) => void;
  onBlur: () => void;
  label?: string;
  error?: FieldError | undefined;
};

export default React.forwardRef<any, RadioGroupProps>(
  (props, ref): React.ReactElement => {
    return (
      <View ref={ref}>
        <Text style={styles.label}>{props.label}</Text>
        {props.items.map((item) => (
          <RadioButton
            key={item.value}
            label={item.label}
            style={{ marginTop: 8 }}
            selected={props.value === item.value}
            onSelect={(v) => (v ? props.onChange(item.value) : null)}
          />
        ))}
        <Text style={styles.errorText}>
          {props.error && props.error.message}
        </Text>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  label: {
    fontWeight: "600",
    marginBottom: 12,
  },
  errorText: {
    color: Colors.theme.danger,
  },
});
