/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";

import { RootStackParamList } from "./types";

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL("/"), "https://app.flyzephur.com"],
  config: {
    screens: {
      Auth: {
        screens: {
          AuthMain: {
            screens: {
              HomeStack: {
                screens: {
                  Home: "home",
                  PastTrips: "past-trips",
                  Trip: "trips/:tripId",
                  BookTrip: "trips/:tripId/book",
                  EditTrip: "trips/:tripId/edit",
                  ConfirmCancelTrip: "trips/:tripId/confirm-cancel",
                  Subcontract: "trips/:tripId/subcontract",
                  SubcontractConfirm: "trips/:tripId/subcontract/:type",
                  ApproveTripDetails: "trips/:tripId/approve",
                  ReviewChangeRequest: "trips/:tripId/review-changes",
                  TripScheduled: "trips/:tripId/scheduled",
                  EnterHobbs: "trips/:tripId/enter-hobbs",
                  RequestTripStack: {
                    screens: {
                      TripInfo: "request-trip/trip-info",
                      DepartInfo: "request-trip/depart-info",
                      ArrivalInfo: "request-trip/arrival-info",
                      ReturnInfo: "request-trip/return-info",
                      MoreDetails: "request-trip/more-details",
                      Success: "request-trip/success",
                    },
                  },
                },
              },
              ContactsStack: {
                screens: {
                  Contacts: "contacts",
                  ViewOrganization: "organizations/:organizationId",
                  ViewUser: "users/:userId",
                },
              },
              InviteStack: {
                screens: {
                  Invite: "invite",
                  QR: "qr",
                },
              },
              MembersStack: {
                screens: {
                  Members: "members",
                  AddMember: "add-member",
                  ChangePermissions: "change-perms",
                  ViewMember: "members/:id",
                  ChangeRole: "members/:id/change-role",
                  ConfirmDeleteMember: "members/:id/confirm-delete",
                },
              },
              PilotsStack: {
                screens: {
                  Pilots: "pilots",
                  ViewPilot: "pilots/:id",
                  ConfirmDeletePilot: "pilots/:id/confirm-delete",
                },
              },
              AircraftStack: {
                screens: {
                  Aircraft: "aircraft",
                  ViewAircraft: "aircraft/:id",
                  AddAircraft: "add-aircraft",
                  ConfirmDelete: "aircraft/:id/confirm-delete",
                  EditAircraft: "aircraft/:id/edit",
                },
              },
              Stripe: "stripe/:action",
            },
          },
        },
      },
      HandleInvite: {
        screens: {
          CreateAccount: "handle-invite/create-account",
          BasicInfo: "handle-invite/basic-info",
          SetPassword: "handle-invite/set-password",
          OrganizationInfo: "handle-invite/organization-info",
          PilotInformation: "handle-invite/pilot-information",
          TermsAndInfo: "handle-invite/terms-and-info",
          TermsAndConditions: "handle-invite/terms-and-conditions",
          OtherInfo: "handle-invite/other-info",
          Stripe: "handle-invite/stripe",
          Success: "handle-invite/success",
          ConfirmInv: "handle-invite/confirm-invitation",
        },
      },
      Unauth: {
        screens: {
          Login: "login",
          ForgotPassword: "forgot-password",
          ConfirmEmail: "confirm-email",
          UpdatePassword: "update-password",
        },
      },
      NotFound: "*",
    },
  },
};

export default linking;
