import * as React from "react";
import { FieldError } from "react-hook-form";
import TextInput from "../TextInput";
import { TimePickerModal } from 'react-native-paper-dates';
import { TouchableOpacity } from "react-native";

export interface DatePickerInputProps {
  value?: string;
  label?: string;
  onChange?: (...vals: any[]) => void;
  error?: FieldError | undefined;
}

export default React.forwardRef<any, DatePickerInputProps>((props, ref) => {
  const { value, label, onChange, error } = props;
  const [visible, setVisible] = React.useState(false);

  const onDismiss = React.useCallback(() => {
    setVisible(false)
  }, [setVisible])

  const onConfirm = React.useCallback(
    ( { hours, minutes } ) => {
      setVisible( false );
      if ( !onChange ) return;
      onChange( hours == undefined ? 'Anytime' : `${hours === 0 ? '00' : hours < 10 ? `0${hours}` : hours}:${minutes == (0 || undefined) ? '00' : minutes < 10 ? `0${minutes}` : minutes}` );
    },
    [value]
  );

  return (
  <>
    <TimePickerModal
        locale={'en'} // optional, default is automically detected by your system
        visible={visible}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
        uppercase={false} // optional, default is true
        animationType="none" // optional, default is 'none'
      />
      <TouchableOpacity  onPress={()=> setVisible(true)}>
        <TextInput
          ref={ref}
          editable={false}
          value={value}
          label={label}
          error={error}
          onSelectionChange={onChange}
        />
      </TouchableOpacity> 
  </>
  );
  
});