import { Image, StyleSheet } from "react-native";
import { useQuery } from "react-query";

import { BaseHeader } from "../components/BaseHeader";
import { View, Text } from "../components/Themed";
import Theme from "../constants/Theme";
import { ViewUserScreenProps } from "../navigation/types";
import { api } from "../api";
import LoadingIndicator from "../components/LoadingIndicator";
import Colors from "../constants/Colors";
import TextInput from "../components/TextInput";
import { MemberRole, UserType } from "../types/User";

export default function ViewUserScreen({ route }: ViewUserScreenProps) {
  const { userId } = route.params;
  const { data: user, isLoading } = useQuery(`/users/${userId}`, () =>
    api.getUser(userId)
  );

  let subtitle = "";
  if (user?.type === UserType.PILOT) {
    subtitle = "Pilot";
  } else {
    switch (user?.role) {
      case MemberRole.GENERAL:
        subtitle = "General User";
        break;
      case MemberRole.ADMIN:
        subtitle = "Admin";
        break;
      case MemberRole.SUPERADMIN:
        subtitle = "Super Admin";
        break;
    }
  }

  return (
    <View style={Theme.page}>
      {isLoading && <LoadingIndicator />}
      <View style={styles.headerContainer}>
        <Image style={styles.image} source={{ uri: user?.photoUrl }} />
        <Text style={styles.title}>
          {user && `${user?.firstName} ${user?.lastName}`}
        </Text>
        <Text style={styles.subtitle}>{subtitle}</Text>
      </View>
      <TextInput
        name="cell"
        value={user?.phone || ""}
        label="Cell"
        editable={false}
      />
      <TextInput
        name="email"
        value={user?.email || ""}
        label="Email"
        editable={false}
        containerStyle={{ marginTop: 16 }}
      />
    </View>
  );
}

export function ViewUserScreenHeader() {
  return (
    <BaseHeader
      title="Contacts"
      leftIcon="back"
      rightIcon="notifications"
      color="blue"
    />
  );
}

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerContainer: {
    alignItems: "center",
    marginBottom: 36,
  },
  image: {
    ...Theme.roundedImage,
    width: 128,
    height: 128,
    marginBottom: 16,
  },
  title: {
    ...Theme.h1,
  },
  subtitle: {
    color: Colors.theme.gold,
    fontWeight: "600",
    marginTop: 8,
  },
});
