import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Invitation, InvitationType } from "../types/Invitation";
import { FileData } from "../types/FileData";
import { PilotCertLevel } from "../types/User";

export interface BasicInfo {
  photo: FileData | null;
  firstName: string;
  lastName: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  phone: string;
  email: string;
}

export interface PilotInfo {
  certLevel: PilotCertLevel | null;
  certNo: string;
  resume: FileData | null;
  certImage: FileData | null;
}

export interface OrganizationInfo {
  name: string;
  address1: string;
  address2?: string;
  photo?: FileData | null;
}

export interface CreateAccountState {
  initialized: boolean;
  disableEmail?: boolean; // For invitations with defined inviteeEmail.
  invitation?: Invitation;
  basicInfo: BasicInfo;
  organizationInfo: OrganizationInfo;
  pilotInfo: PilotInfo;
  acceptedTerms: boolean;
  acceptedOther: boolean;
  password: string;
}

const initialState: CreateAccountState = {
  initialized: false,
  acceptedTerms: false,
  acceptedOther: false,
  password: "",
  basicInfo: {
    photo: null,
    firstName: "",
    lastName: "",
    address1: "",
    city: "",
    state: "",
    phone: "",
    email: "",
  },
  pilotInfo: {
    certLevel: null,
    certNo: "",
    resume: null,
    certImage: null,
  },
  organizationInfo: {
    photo: null,
    name: "",
    address1: "",
  },
};

// Useful for testing.
/*
const filledInitialState: CreateAccountState = {
  initialized: false,
  acceptedTerms: true,
  acceptedOther: true,
  password: "asdf",
  invitation: {
    id: "c19a06af-c967-4a7f-9d13-af853ebdd7c0",
    referrer: { id: "" } as any,
    invitationType: InvitationType.SUBCONTRACTOR,
    url: "",
    temporary: true,
  },
  basicInfo: {
    // /*
    photo: {
      type: "success",
      uri: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAASCAYAAACuLnWgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABQSURBVHgB7ZJBDQAhDAT3TsFJOGdgAQc4BQfgoCw46GMTHp1kmv72MzCzTIdpaDQ9+wHwQ8d8eT5oOSOFdmiYtCJwEQl7RyLhy4iEvSPyhBecVECtl3DXLgAAAABJRU5ErkJggg==",
      mimeType: "image/png",
      name: "Group 698.png",
      size: 187,
    },
    // photo: null,
    firstName: "Test",
    lastName: "User",
    address1: "123 E Street",
    city: "Fresno",
    state: "CA",
    phone: "123",
    email: "email@test.com",
  },
  pilotInfo: {
    certLevel: PilotCertLevel.AIRLINE_TRANSPORTATION,
    certNo: "123",
    // /*
    resume: {
      type: "success",
      uri: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAASCAYAAACuLnWgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABQSURBVHgB7ZJBDQAhDAT3TsFJOGdgAQc4BQfgoCw46GMTHp1kmv72MzCzTIdpaDQ9+wHwQ8d8eT5oOSOFdmiYtCJwEQl7RyLhy4iEvSPyhBecVECtl3DXLgAAAABJRU5ErkJggg==",
      mimeType: "image/png",
      name: "Group 698.png",
      size: 187,
    },
    // resume: null,
    certImage: {
      type: "success",
      uri: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAASCAYAAACuLnWgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABQSURBVHgB7ZJBDQAhDAT3TsFJOGdgAQc4BQfgoCw46GMTHp1kmv72MzCzTIdpaDQ9+wHwQ8d8eT5oOSOFdmiYtCJwEQl7RyLhy4iEvSPyhBecVECtl3DXLgAAAABJRU5ErkJggg==",
      mimeType: "image/png",
      name: "Group 698.png",
      size: 187,
    },
    //certImage: null,
  },
  organizationInfo: {
    photo: {
      type: "success",
      uri: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAASCAYAAACuLnWgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABQSURBVHgB7ZJBDQAhDAT3TsFJOGdgAQc4BQfgoCw46GMTHp1kmv72MzCzTIdpaDQ9+wHwQ8d8eT5oOSOFdmiYtCJwEQl7RyLhy4iEvSPyhBecVECtl3DXLgAAAABJRU5ErkJggg==",
      mimeType: "image/png",
      name: "Group 698.png",
      size: 187,
    },
    name: "New Org",
    address1: "1234 E Street",
  },
};
*/

const createAccount = createSlice({
  name: "createAccount",
  initialState,
  reducers: {
    initialize: (state, action: PayloadAction<Invitation>) => {
      state.invitation = action.payload;
      if (state.invitation.inviteeEmail) {
        state.basicInfo.email = state.invitation.inviteeEmail;
        state.disableEmail = true;
      }
      state.initialized = true;
    },
    reset: (state) => {
      state.basicInfo = initialState.basicInfo;
      state.invitation = undefined;
      state.initialized = false;
    },
    setBasicInfo: (state, action: PayloadAction<BasicInfo>) => {
      state.basicInfo = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setPilotInfo: (state, action: PayloadAction<PilotInfo>) => {
      state.pilotInfo = action.payload;
    },
    setOrganizationInfo: (state, action: PayloadAction<OrganizationInfo>) => {
      state.organizationInfo = action.payload;
    },
    acceptTerms: (state) => {
      state.acceptedTerms = true;
    },
    acceptOther: (state) => {
      state.acceptedOther = true;
    },
  },
});

export const {
  initialize,
  reset,
  setBasicInfo,
  setPassword,
  setPilotInfo,
  setOrganizationInfo,
  acceptTerms,
  acceptOther,
} = createAccount.actions;
export default createAccount;
