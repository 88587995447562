import {
  Text,
  ButtonProps as DefaultButtonProps,
  StyleSheet,
  ViewStyle,
  TouchableOpacity,
  GestureResponderEvent,
  TextStyle,
} from "react-native";
import Colors from "../constants/Colors";

interface ButtonProps extends DefaultButtonProps {
  style?: ViewStyle;
  /**
   * Has to be defined as an object, NOT created with StyleSheet.create()
   * This is because StyleSheet.create apparently creates ID's, and not actual
   * objects containing the styles.
   */
  textStyle?: TextStyle;
  small?: boolean;
  variant?: "large";
  color?: "lightBlue" | "success" | "danger" | "gold" | "blueishBlack";
}

export function Button(props: ButtonProps) {
  const btnStyle: ViewStyle = {
    paddingVertical: 10,
    paddingHorizontal: 24,
  };
  const labelStyle = { ...props.textStyle };
  const bg = {
    lightBlue: Colors.theme.lightBlue,
    success: Colors.theme.success,
    danger: Colors.theme.danger,
    gold: Colors.theme.gold,
    blueishBlack: Colors.theme.blueishBlack,
  };
  Object.assign(btnStyle, {
    backgroundColor: props.disabled
      ? Colors.theme.lightGray
      : props.color
      ? bg[props.color]
      : Colors.theme.primary,
  });
  if (props.variant === "large") {
    Object.assign(btnStyle, {
      elevation: 3,
      shadowColor: "#000",
      shadowRadius: 12,
      shadowOpacity: 0.4,
      paddingVertical: 12,
      paddingHorizontal: 28,
    });
    labelStyle.fontSize = 20;
  }

  const onPress = (ev: GestureResponderEvent) => {
    if (props.disabled) {
      return;
    }
    props.onPress(ev);
  };

  return (
    <TouchableOpacity
      style={StyleSheet.flatten([btnStyle, styles.button, props.style])}
      onPress={onPress}
    >
      <Text style={StyleSheet.flatten([labelStyle, styles.text, props.textStyle])}>
        {props.title}
      </Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 9999,
  },
  text: {
    color: "#fff",
    fontWeight: "600",
    fontSize: 16,
  },
});
