import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { styles } from "../RequestTripScreen";
import { RootState } from "../../store";
import { View, Text } from "../../components/Themed";
import Theme from "../../constants/Theme";
import { Button } from "../../components/Button";
import { DetailType, TripType } from "../../types/Trip";
import { setDetail } from "../../store/requestTrip";
import { DetailFormData } from "../../store/requestTrip";
import { RequestTripScreenProps } from "../../navigation/types";
import CtlTextInput from "../../components/ControlledInputs/CtlTextInput";
import CtlGooglePlacesInput from "../../components/ControlledInputs/CtlGooglePlacesInput";
import { googlePlacesRequired } from "../../util/formRules";

export default function ArrivalInfoScreen({
  navigation,
}: RequestTripScreenProps) {
  const tripInfo = useSelector(
    (state: RootState) => state.requestTrip.tripInfo
  );
  const arrivalInfo = useSelector(
    (state: RootState) => state.requestTrip.arrivalInfo
  );
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm<DetailFormData>({
    defaultValues: arrivalInfo,
  });

  const submit = (d: DetailFormData) => {
    dispatch(setDetail({ detailType: DetailType.ARRIVAL, data: d }));
    navigation.push("ReturnInfo");
  };

  return (
    <KeyboardAwareScrollView
      style={Theme.page}
      contentContainerStyle={Theme.pageContent}
    >
      <View>
        <Text style={styles.header}>
          What is your destination?
        </Text>
        <CtlGooglePlacesInput
          name="location"
          control={control}
          label="Arrival Location"
          rules={googlePlacesRequired}
        />
        <View style={styles.spacer} />
        <CtlTextInput
          name="airport"
          control={control}
          label={`Arrival Airport`}
          optional
          placeholder="Enter 3-character airport (IATA) code."
		      maxLength={3}
        />
        <View style={styles.spacer} />
        <CtlTextInput
          name="preferredFBO"
          control={control}
          label="Preferred FBO"
          optional
          placeholder="Add if you have a preference."
        />
      </View>
      <View>
        <Button
          title="Next"
          style={styles.nextBtn}
          onPress={handleSubmit(submit)}
        />
        <View style={styles.spacer} />
      </View>
    </KeyboardAwareScrollView>
  );
}
