import { Image, ImageSourcePropType, StyleSheet } from "react-native";

import { View, Text } from "../components/Themed";
import Colors from "../constants/Colors";

const splash: ImageSourcePropType = {
  // uri: require("../assets/images/zephur_splash.gif"),
  uri: require("../assets/images/logo_small.png"),
};

export default function SplashScreen() {
  return (
    <View style={styles.container}>
      <Image source={splash} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.theme.primary,
    // height: '100%'
  },
});
