import Theme from "./Theme";

const SharedStyles = {
  sectionContainer: {
    marginHorizontal: -Theme.pagePadding,
    paddingHorizontal: Theme.pagePadding,
    paddingVertical: 20,
    borderBottomColor: "rgba(0, 0, 0, 0.2)",
    borderBottomWidth: 1,
  },
  sectionHeader: {
    ...Theme.h2,
    textAlign: "center",
    marginBottom: 16,
  },
} as const;

export default SharedStyles;
