import format from "date-fns/format";

import { MemberRole, User, UserType } from "../types/User";

/**
 * https://stackoverflow.com/questions/12168909/blob-from-dataurl
 */
export function dataURItoBlob(uri: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(uri.split(",")[1]);

  // separate out the mime component
  var mimeString = uri.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

export function displayUserRole(user: User) {
  if (user.type === UserType.PILOT) {
    return "Pilot";
  } else {
    switch (user.role) {
      case MemberRole.SUPERADMIN:
        return "Super Admin";
      case MemberRole.ADMIN:
        return "Admin";
      case MemberRole.GENERAL:
        return "General User";
    }
  }
}

export function isValidDate(d: Date) {
  // If d is invalid, d.getTime() returns NaN, and NaN !== NaN
  return d.getTime() === d.getTime();
}

export const formatDate = (
  dateStr: string,
  options?: { includeSuffix?: boolean }
): string => {
  const date = new Date(dateStr);
  if (!isValidDate(date)) return "";
  let fStr = "LLL d" + (options?.includeSuffix ? "o" : "");
  return format(date, fStr);
};

/**
 * https://github.com/facebook/react/issues/5465#issuecomment-157888325
 *
 * Wraps a promise, enabling it to be canceled. On cancel, excepts an
 * object { isCanceled: true }.
 *
 * @example
 * const somePromise = new Promise(r => setTimeout(r, 1000));
 * const cancelable = makeCancelable(somePromise);
 *
 * cancelable
 *   .promise
 *   .then(() => console.log('resolved'))
 *   .catch(({ isCanceled, ...error }) => console.log('isCanceled', isCanceled));
 *
 * // To cancel:
 * cancelable.cancel()
 */
export const makeCancelable = <T>(promise: Promise<T>) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then((val) =>
      hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)
    );
    promise.catch((error) =>
      hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export type makeCancelableError = { isCanceled: true };

/**
 * Sets `val` at the JSON path `keys` to `target`, creating new objects
 * for any keys that are missing in `target`.
 */
export const apply = (
  target: Record<string, any>,
  keys: string[],
  val: any
) => {
  let obj = target;
  for (const key of keys.slice(0, -1)) {
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      obj[key] = {};
    }
    obj = obj[key];
  }
  obj[keys[keys.length - 1]] = val;
};

/**
 * Gets the value at the JSON path `keys` in `target`.
 */
export const get = (target: Record<string, any>, keys: string[]) => {
  let obj = target;
  for (const key of keys) {
    obj = obj[key];
  }
  return obj;
};

export const base64UrlToBase64 = (str: string) => {
	return str.replace(/-/g, "+").replace(/_/g, "/");
};
