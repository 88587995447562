import * as React from "react";
import { FieldValues, FieldPath, useController } from "react-hook-form";

import DatePickerInput, {
  DatePickerInputProps,
} from "../DatePickerInput/DatePickerInput";
import { ControlledInputProps } from "../../types/ControlledInput";

export default function CtlDatePickerInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  rules,
  ...rest
}: ControlledInputProps<TFieldValues, TName> & DatePickerInputProps) {
  const {
    field,
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    name,
    control,
    rules,
  });

  return <DatePickerInput {...field} error={error} {...rest} />;
}
