export type ErrorResponse<T = any> = {
  statusCode: number;
  name: string;
  message: string;
  details: T;
};

export class BaseErrorResponse<T> extends Error {
  name: string;
  message: string;
  details: T;

  constructor({ name, message, details }: ErrorResponse<T>) {
    super(message);
    this.name = name;
    this.message = message;
    this.details = details;
  }
}

export class TailNumberAlreadyExistsError extends BaseErrorResponse<undefined> {}

export function makeErrorResponse(rawRes: Record<string, unknown>) {
  if (typeof rawRes !== "object") {
    return null;
  }

  const res = rawRes as ErrorResponse;
  switch (res.name) {
    case "TAIL_NUMBER_ALREADY_EXISTS":
      return new TailNumberAlreadyExistsError(res);
  }

  console.debug("Unknown error name:", res.name);
  return null;
}
