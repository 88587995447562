import * as React from "react";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { StyleSheet } from "react-native";
import { useMutation, useQueryClient } from "react-query";

import Theme from "../../constants/Theme";
import { useUser } from "../../hooks";
import { BaseHeader } from "../../components/BaseHeader";
import { Text, View } from "../../components/Themed";
import { Button } from "../../components/Button";
import SubtleButton from "../../components/SubtleButton";
import { ConfirmCancelTripScreenProps } from "../../navigation/types";
import { api } from "../../api";

export default function ConfirmCancelTripScreen( {
	navigation,
	route,
}: ConfirmCancelTripScreenProps ) {
	const { tripId } = route.params;
	const { user } = useUser();
	const queryClient = useQueryClient();
	const cancelMutation = useMutation( () => api.cancelTrip( tripId ), {
		onSuccess: () => {
			queryClient.invalidateQueries( ["trips", tripId] );
			queryClient.invalidateQueries( ['users', user?.id, "trips"] );
			queryClient.invalidateQueries( ['notifs', user?.id] );
		},
	} );
	const [errMsg, setErrMsg] = React.useState( "" );

	const cancelTrip = async () => {
		try {
			setErrMsg( "" );
			await cancelMutation.mutateAsync();
			navigation.push( "Trip", { tripId } );
		} catch ( err ) {
			setErrMsg( "There was an error cancelling this Trip. Please try again later." );
		}
	};

	const cancel = () => {
		if ( navigation.canGoBack() ) navigation.goBack();
		else navigation.navigate( "Home" );
	};

	return (
		<View style={Theme.page}>
			<Text style={styles.header}>Confirm Cancellation</Text>
			<Text style={styles.subheader}>Are you sure you would like to cancel this trip?</Text>
			<Text style={styles.errMsg}>{ errMsg }</Text>
			<Button
				title="Cancel Trip"
				onPress={cancelTrip}
				variant="large"
				color="danger"
				style={styles.confirmBtn}
			/>
			<SubtleButton
				title="Oops! I don't want to cancel."
				onPress={cancel}
			/>
		</View>
	);
}

export function ConfirmCancelTripScreenHeader( {
	route,
	navigation,
}: NativeStackHeaderProps ) {
	const { tripId } = route.params as any;

	const onBack = () => {
		if ( navigation.canGoBack() ) navigation.goBack();
		else navigation.navigate( "Trip", { tripId } );
	};

	return <BaseHeader leftIcon="back" color="black" onBack={onBack} />;
}

const styles = StyleSheet.create({
  header: {
    ...Theme.h1,
    textAlign: "center",
  },
  subheader: {
    textAlign: "center",
  },
  errMsg: {
    ...Theme.errorMessage,
  },
  confirmBtn: {
    alignSelf: "center",
    marginBottom: 20,
  },
});