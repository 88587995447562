import * as React from "react";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";

import { RootState } from "../store";
import { CreateAccountNavigationProp } from "../navigation/types";

export default function useCheckInit() {
  const initialized = useSelector(
    (state: RootState) => state.createAccount.initialized
  );
  const navigation = useNavigation<CreateAccountNavigationProp>();

  React.useEffect(() => {
    if (!initialized) {
      navigation.getParent()?.navigate("Unauth");
    }
  }, []);
}
