import { StyleSheet, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { BaseHeader } from "../components/BaseHeader";
import Theme from "../constants/Theme";
import { View, Text } from "../components/Themed";
import PreviewPicture from "../components/PreviewPicture";
import { Member, MemberRole, UserType } from "../types/User";
import {
  MembersScreenProps,
  MembersStackNavigationProp,
} from "../navigation/types";
import { User } from "../types/User";
import { ZephurVersion } from "../components/ZephurVersion";
import { hasPermission } from "../util/permissions";
import { Button } from "../components/Button";
import { useMember } from "../hooks/useUser";
import ScreenContainer from "../components/ScreenContainer";
import useOrgUsers from "../hooks/useOrgUsers";

export default function MembersScreen({ navigation }: MembersScreenProps) {
  const { user } = useMember();
  const { users } = useOrgUsers(user?.organization);

  const isMember = (user: User): user is Member =>
    user.type === UserType.MEMBER;
  const members = users?.filter<Member>(isMember).sort(function(a: Member, b: Member) {
    return (a.firstName < b.firstName) ? -1 : (a.firstName > b.firstName) ? 1: 0;
  });

  const superAdmins = members?.filter((m) => m.role === MemberRole.SUPERADMIN);
  const admins = members?.filter((m) => m.role === MemberRole.ADMIN);
  const gens = members?.filter((m) => m.role === MemberRole.GENERAL);

  const viewUser = (u: User) => {
    navigation.push("ViewMember", { id: u.id });
  };

  return (
    <ScreenContainer>
      <View>
        <>
          <Text style={styles.header}>Super Admin User</Text>
          {superAdmins?.map((u) => (
            <TouchableOpacity key={u.id} onPress={() => viewUser(u)}>
              <PreviewPicture
                url={u.photoUrl}
                title={`${u.firstName} ${u.lastName}`}
                subtitle="Super Admin"
                style={styles.previewPic}
              />
            </TouchableOpacity>
          ))}
        </>
        <View style={styles.spacer} />

        {admins && admins.length > 0 && (
          <>
            <Text style={styles.header}>Admin User</Text>
            {admins.map((u) => (
              <TouchableOpacity key={u.id} onPress={() => viewUser(u)}>
                <PreviewPicture
                  url={u.photoUrl}
                  title={`${u.firstName} ${u.lastName}`}
                  subtitle="Admin"
                  style={styles.previewPic}
                />
              </TouchableOpacity>
            ))}
            <View style={styles.spacer} />
          </>
        )}

        {gens && gens.length > 0 && (
          <>
            <Text style={styles.header}>General User</Text>
            {gens?.map((u) => (
              <TouchableOpacity key={u.id} onPress={() => viewUser(u)}>
                <PreviewPicture
                  url={u.photoUrl}
                  title={`${u.firstName} ${u.lastName}`}
                  subtitle="General User"
                  style={styles.previewPic}
                />
              </TouchableOpacity>
            ))}
          </>
        )}
      </View>
      <ZephurVersion />
    </ScreenContainer>
  );
}

export function MembersScreenHeader() {
  const { user } = useMember();
  const navigation = useNavigation<MembersStackNavigationProp>();

  const addNewMember = () => {
    navigation.push("AddMember");
  };

  let below: React.ReactNode | null;
  if (hasPermission(user, "addMembers")) {
    below = (
      <Button
        title="Add New Member"
        color="lightBlue"
        onPress={addNewMember}
        style={{ alignSelf: "center" }}
      />
    );
  }

  return (
    <BaseHeader
      title="Members"
      leftIcon="menu"
      rightIcon="notifications"
      color="blue"
      renderBelow={() => below}
    />
  );
}

const styles = StyleSheet.create({
  header: {
    ...Theme.h2,
    marginBottom: 8,
  },
  spacer: {
    height: 40,
  },
  previewPic: {
    marginBottom: 12,
  },
});
