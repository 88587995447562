import * as React from "react";
import { ScrollView } from "react-native";
import { useDispatch } from "react-redux";

import Style from "../../constants/CreateAccountStyles";
import { Text, View } from "../../components/Themed";
import { Button } from "../../components/Button";
import { OtherInfoScreenProps } from "../../navigation/types";
import { acceptOther } from "../../store/createAccount";
import { useCheckInit } from "../../hooks";

export default function OtherInfoScreen({ navigation }: OtherInfoScreenProps) {
  useCheckInit();
  const dispatch = useDispatch();

  const agree = () => {
    dispatch(acceptOther());
    navigation.goBack();
  };

  return (
    <ScrollView style={Style.page} contentContainerStyle={Style.pageContent}>
      <Text style={Style.header}>
        Other Agreements and End User License Agreement
      </Text>
      <View style={Style.spacerSmall} />
      <Text>
        Zephur is licensed to You (End-User) by Zulu Aviation Technologies, LLC,
        located and registered at 740 W Pinedale Ave, Fresno , California 93711,
        United States ("Licensor"), for use only under the terms of this License
        Agreement.
      </Text>
      <View style={Style.spacerSmall} />
      <Text>
        By downloading the Licensed Application from Google's software
        distribution platform ("Play Store"), and any update thereto (as
        permitted by this License Agreement), You indicate that You agree to be
        bound by all of the terms and conditions of this License Agreement, and
        that You accept this License Agreement. Play Store is referred to in
        this License Agreement as "Services."
      </Text>
      <View style={Style.spacerSmall} />
      <Text>
        The parties of this License Agreement acknowledge that the Services are
        not a Party to this License Agreement and are not bound by any
        provisions or obligations with regard to the Licensed Application, such
        as warranty, liability, maintenance and support thereof. Zulu Aviation
        Technologies, LLC, not the Services, is solely responsible for the
        Licensed Application and the content thereof.
      </Text>
      <View style={Style.spacerSmall} />
      <Text>
        This License Agreement may not provide for usage rules for the Licensed
        Application that are in conflict with the latest{" "}
        <a
          href="https://play.google.com/intl/en_US/about/play-terms/"
          target="_blank"
        >
          Google Play Terms of Service
        </a>{" "}
        ("Usage Rules"). Zulu Aviation Technologies, LLC acknowledges that it
        had the opportunity to review the Usage Rules and this License Agreement
        is not conflicting with them.
      </Text>
      <View style={Style.spacerSmall} />
      <Text>
        Zephur when purchased or downloaded through the Services, is licensed to
        You for use only under the terms of this License Agreement. The Licensor
        reserves all rights not expressly granted to You. Zephur is to be used
        on devices that operate with Google's operating system ("Android").
      </Text>
      <ol>
        <li style={Style.liStyle}>
          The Application
          <View style={Style.spacerSmall} />
          <Text>
            Zephur ("Licensed Application") is a piece of software created to
            Facilitate payment and scheduling between aircraft owners and
            pilots. — and customized for Android mobile devices ("Devices"). It
            is used to Scheduling and payments.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            The Licensed Application is not tailored to comply with
            industry-specific regulations (Health Insurance Portability and
            Accountability Act (HIPAA), Federal Information Security Management
            Act (FISMA), etc.), so if your interactions would be subjected to
            such laws, you may not use this Licensed Application. You may not
            use the Licensed Application in a way that would violate the
            Gramm-Leach-Bliley Act (GLBA).
          </Text>
        </li>
        <View style={Style.spacerSmall} />
        <li style={Style.liStyle}>
          Scope of License
          <View style={Style.spacerSmall} />
          <Text>
            2.1 You are given a non-transferable, non-exclusive,
            non-sublicensable license to install and use the Licensed
            Application on any Devices that You (End-User) own or control and as
            permitted by the Usage Rules, with the exception that such Licensed
            Application may be accessed and used by other accounts associated
            with You (End-User, The Purchaser) via Family Sharing or volume
            purchasing.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            2.2 This license will also govern any updates of the Licensed
            Application provided by Licensor that replace, repair, and/or
            supplement the first Licensed Application, unless a separate license
            is provided for such update, in which case the terms of that new
            license will govern.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            2.3 You may not share or make the Licensed Application available to
            third parties (unless to the degree allowed by the Usage Rules, and
            with Zulu Aviation Technologies, LLC's prior written consent), sell,
            rent, lend, lease or otherwise redistribute the Licensed
            Application.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            2.4 You may not reverse engineer, translate, disassemble, integrate,
            decompile, remove, modify, combine, create derivative works or
            updates of, adapt, or attempt to derive the source code of the
            Licensed Application, or any part thereof (except with Zulu Aviation
            Technologies, LLC's prior written consent).
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            2.5 You may not copy (excluding when expressly authorized by this
            license and the Usage Rules) or alter the Licensed Application or
            portions thereof. You may create and store copies only on devices
            that You own or control for backup keeping under the terms of this
            license, the Usage Rules, and any other terms and conditions that
            apply to the device or software used. You may not remove any
            intellectual property notices. You acknowledge that no unauthorized
            third parties may gain access to these copies at any time. If you
            sell your Devices to a third party, you must remove the Licensed
            Application from the Devices before doing so.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            2.6 Violations of the obligations mentioned above, as well as the
            attempt of such infringement, may be subject to prosecution and
            damages.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            2.7 Licensor reserves the right to modify the terms and consitions
            of licensing.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            2.8 Nothing in this license should be interpreted to restrict
            third-party terms. When using the Licensed Application, You must
            ensure that You comply with applicable third-party terms and
            conditions.
          </Text>
        </li>
        <View style={Style.spacerSmall} />
        <li style={Style.liStyle}>
          Technical Requirements
          <View style={Style.spacerSmall} />
          <Text>
            3.1 The Licensed Application requires a firmware version 1.0.0 or
            higher. Licensor recommends using the latest version of the
            firmware.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            3.2 Licensor attempts to keep the Licensed Application updated so
            that it complies with modified/new versions of the firmware and new
            hardware. You are not granted rights to claim such an update.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            3.3 You acknowledge that it is Your responsibility to confirm and
            determine that the app end-user device on which You intend to use
            the Licensed Application satisfies the technical specifications
            mentioned above.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            3.4 Licensor reserves the right to modify the technical
            specifications as it sees appropriate at any time.
          </Text>
        </li>
        <View style={Style.spacerSmall} />
        <li style={Style.liStyle}>
          Maintenance and Support
          <View style={Style.spacerSmall} />
          <Text>
            4.1 The Licensor is solely responsible for providing any maintenance
            and support services for this Licensed Application. You can reach
            the Licensor at the email address listed in the Play Store Overview
            for this Licensed Application.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            4.2 Zulu Aviation Technologies, LLC and the End-User acknowledge
            that the Services have no obligation whatsoever to furnish any
            maintenance and support services with respect to the Licensed
            Application.
          </Text>
        </li>
        <View style={Style.spacerSmall} />
        <li style={Style.liStyle}>
          Use of Data
          <View style={Style.spacerSmall} />
          <Text>
            You acknowledge that Licensor will be able to access and adjust Your
            downloaded Licensed Application content and Your personal
            information, and that Licensor's use of such material and
            information is subject to Your legal agreements with Licensor and
            Licensor's privacy policy, which can be found at the bottom of the
            Licensed Application.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            You acknowledge that the Licensor may periodically collect and use
            technical data and related information about your device, system,
            and application software, and peripherals, offer product support,
            facilitate the software updates, and for purposes of providing other
            services to you (if any) related to the Licensed Application.
            Licensor may also use this information to improve its products or to
            provide services or technologies to you, as long as it is in a form
            that does not personally identify you.
          </Text>
        </li>
        <View style={Style.spacerSmall} />
        <li style={Style.liStyle}>
          User-generated Contributions
          <View style={Style.spacerSmall} />
          <Text>
            The Licensed Application does not offer users to submit or post
            content. We may provide you with the opportunity to create, submit,
            post, display, transmit, perform, publish, distribute, or broadcast
            content and materials to us or in the Licensed Application,
            including but not limited to text, writings, video, audio,
            photographs, graphics, comments, suggestions, or personal
            information or other material (collectively, "Contributions").
            Contributions may be viewable by other users of the Licensed
            Application and through third-party websites or applications. As
            such, any Contributions you transmit may be treated in accordance
            with the Licensed Application Privacy Policy. When you create or
            make available any Contributions, you thereby represent and warrant
            that:
          </Text>
          <View style={Style.spacerSmall} />
          <ul style={Style.ulStyleNone}>
            <li>
              <Text>
                1. The creation, distribution, transmission, public display, or
                performance, and the accessing, downloading, or copying of your
                Contributions do not and will not infringe the proprietary
                rights, including but not limited to the copyright, patent,
                trademark, trade secret, or moral rights of any third party.
              </Text>
            </li>
            <li>
              <Text>
                2. You are the creator and owner of or have the necessary licenses,
                rights, consents, releases, and permissions to use and to
                authorize us, the Licensed Application, and other users of the
                Licensed Application to use your Contributions in any manner
                contemplated by the Licensed Application and this License
                Agreement.
              </Text>
            </li>
            <li>
              <Text>
                3. You have the written consent, release, and/or permission of each
                and every identifiable individual person in your Contributions
                to use the name or likeness or each and every such identifiable
                individual person to enable inclusion and use of your
                Contributions in any manner contemplated by the Licensed
                Application and this License Agreement.
              </Text>
            </li>
            <li>
              <Text>
                4. Your Contributions are not false, inaccurate, or misleading.
              </Text>
            </li>
            <li>
              <Text>
                5. Your Contributions are not unsolicited or unauthorized
                advertising, promotional materials, pyramid schemes, chain
                letters, spam, mass mailings, or other forms of solicitation.
              </Text>
            </li>
            <li>
              <Text>
                6. Your Contributions are not obscene, lewd, lascivious, filthy,
                violent, harassing, libelous, slanderous, or otherwise
                objectionable (as determined by us).
              </Text>
            </li>
            <li>
              <Text>
                7. Your Contributions do not ridicule, mock, disparage, intimidate,
                or abuse anyone.
              </Text>
            </li>
            <li>
              <Text>
                8. Your Contributions are not used to harass or threaten (in the
                legal sense of those terms) any other person and to promote
                violence against a specific person or class of people.
              </Text>
            </li>
            <li>
              <Text>
                9. Your Contributions do not violate any applicable law,
                regulation, or rule.
              </Text>
            </li>
            <li>
              <Text>
                10. Your Contributions do not violate the privacy or publicity
                rights of any third party.
              </Text>
            </li>
            <li>
              <Text>
                11. Your Contributions do not violate any applicable law concerning
                child pornography, or otherwise intended to protect the health
                or well-being of minors.
              </Text>
            </li>
            <li>
              <Text>
                12. Your Contributions do not include any offensive comments that
                are connected to race, national origin, gender, sexual
                preference, or physical handicap.
              </Text>
            </li>
            <li>
              <Text>
                13. Your Contributions do not otherwise violate, or link to material
                that violates, any provision of this License Agreement, or any
                applicable law or regulation.
              </Text>
            </li>
          </ul>
          <View style={Style.spacerSmall} />
          <Text>
            Any use of the Licensed Application in violation of the foregoing
            violates this License Agreement and may result in, among other
            things, termination or suspension of your rights to use the Licensed
            Application.
          </Text>
        </li>
        <View style={Style.spacerSmall} />
        <li style={Style.liStyle}>
          Contribution License
          <View style={Style.spacerSmall} />
          <Text>
            You agree that we may access, store, process, and use any
            information and personal data that you provide following the terms
            of the Privacy Policy and your choices (including settings).
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            By submitting suggestions of other feedback regarding the Licensed
            Application, you agree that we can use and share such feedback for
            any purpose without compensation to you.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            We do not assert any ownership over your Contributions. You retain
            full ownership of all of your Contributions and any intellectual
            property rights or other proprietary rights associated with your
            Contributions. We are not liable for any statements or
            representations in your Contributions provided by you in any area in
            the Licensed Application. You are solely responsible for your
            Contributions to the Licensed Application and you expressly agree to
            exonerate us from any and all responsibility and to refrain from any
            legal action against us regarding your Contributions.
          </Text>
        </li>
        <View style={Style.spacerSmall} />
        <li style={Style.liStyle}>
          Liability
          <View style={Style.spacerSmall} />
          <Text>
            8.1 Licensor's responsibility in the case of violation of
            obligations and tort shall be limited to intent and gross
            negligence. Only in case of a breach of essential contractual duties
            (cardinal obligations), Licensor shall also be liable in case of
            slight negligence. In any case, liability shall be limited to the
            foreseeable, contractually typical damages. The limitation mentioned
            above does not apply to injuries to life, limb, or health.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            8.2 Licensor takes no accountability or responsibility for any
            damages caused due to a breach of duties according to Section 3 of
            this License Agreement. To avoid data loss, You are required to make
            use of backup functions of the Licensed Application to the extent
            allowed by applicable third-party terms and conditions of use. You
            are aware that in case of alterations or manipulations of the
            Licensed Application, You will not have access to the Licensed
            Application.
          </Text>
        </li>
        <View style={Style.spacerSmall} />
        <li style={Style.liStyle}>
          Warranty
          <View style={Style.spacerSmall} />
          <Text>
            9.1 Licensor warrants that the Licensed Application is free of
            spyware, trojan horses, viruses, or any other malware at the time of
            Your download. Licensor warrants that the Licensed Application works
            as described in the user documentation.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            9.2 No warranty is provided for the Licensed Application that is not
            executable on the device, that has been unauthorizedly modified,
            handled inappropriately or culpably, combined or installed with
            inappropriate hardware or software, used with inappropriate
            accessories, regardless if by Yourself or by third parties, or if
            there are any other reasons outside of Zulu Aviation Technologies,
            LLC's sphere of influence that affect the executability of the
            Licensed Application.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            9.3 You are required to inspect the Licensed Application immediately
            after installing it and notify Zulu Aviation Technologies, LLC about
            issues discovered without delay by email provided in{" "}
            <a href="#productClaims">Product Claims</a>. The defect report will
            be taken into consideration and further investigated if it has been
            emailed within a period of thirty (30) days after discovery.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            9.4 If we confirm that the Licensed Application is defective, Zulu
            Aviation Technologies, LLC reserves a choice to remedy the situation
            either by means of solving the defect or substitute delivery.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            9.5 In the event of any failure of the Licensed Application to
            conform to any applicable warranty, You may notify the Services
            Store Operator, and Your Licensed Application purchase price will be
            refunded to You. To the maximum extent permitted by applicable law,
            the Services Store Operator will have no other warranty obligation
            whatsoever with respect to the Licensed Application, and any other
            losses, claims, damages, liabilities, expenses, and costs
            attributable to any negligence to adhere to any warranty.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            9.6 If the user is an entrepreneur, any claim based on faults
            expires after a statutory period of limitation amounting to twelve
            (12) months after the Licensed Application was made available to the
            user. The statutory periods of limitation given by law apply for
            users who are consumers.
          </Text>
        </li>
        <View style={Style.spacerSmall} />
        <li style={Style.liStyle} id="productClaims">
          Product Claims
          <View style={Style.spacerSmall} />
          <Text>
            Zulu Aviation Technologies, LLC and the End-User acknowledge that
            Zulu Aviation Technologies, LLC, and not the Services, is
            responsible for addressing any claims of the End-User or any third
            party relating to the Licensed Application or the End-User’s
            possession and/or use of that Licensed Application, including, but
            not limited to:
          </Text>
          <View style={Style.spacerSmall} />
          <ul style={Style.ulStyleNone}>
            <li>
              <Text>(i) product liability claims;</Text>
            </li>
            <li>
              <Text>
                (ii) any claim that the Licensed Application fails to conform to
                any applicable legal or regulatory requirement; and
              </Text>
            </li>
            <li>
              <Text>
                (iii) claims arising under consumer protection, privacy, or
                similar legislation.
              </Text>
            </li>
          </ul>
        </li>
        <View style={Style.spacerSmall} />
        <li style={Style.liStyle}>
          Legal Compliance
          <View style={Style.spacerSmall} />
          <Text>
            You represent and warrant that You are not located in a country that
            is subject to a US Government embargo, or that has been designated
            by the US Government as a "terrorist supporting" country; and that
            You are not listed on any US Government list of prohibited or
            restricted parties.
          </Text>
        </li>
        <View style={Style.spacerSmall} />
        <li style={Style.liStyle}>
          Contact Information
          <View style={Style.spacerSmall} />
          <Text>
            For general inquiries, complaints, questions or claims concerning
            the Licensed Application, please contact:
          </Text>
          <View style={Style.spacerSmall} />
          <Text style={Style.addressBold}>John Paul</Text>
          <View style={Style.spacerTiny} />
          <Text style={Style.addressBold}>740 W Pinedale Ave.</Text>
          <View style={Style.spacerTiny} />
          <Text style={Style.addressBold}>Frsno, CA 93711</Text>
          <View style={Style.spacerTiny} />
          <Text style={Style.addressBold}>United States</Text>
          <View style={Style.spacerTiny} />
          <Text style={Style.addressBold}>info@flyzephur.com</Text>
        </li>
        <View style={Style.spacerSmall} />
        <li style={Style.liStyle}>
          Termination
          <View style={Style.spacerSmall} />
          <Text>
            The license is valid until terminated by Zulu Aviation Technologies,
            LLC or by You. Your rights under this license will terminate
            automatically and without notice from Zulu Aviation Technologies,
            LLC if You fail to adhere to any term(s) of this license. Upon
            License termination, You shall stop all use of the Licensed
            Application, and destroy all copies, full or partial, of the
            Licensed Application.
          </Text>
        </li>
        <View style={Style.spacerSmall} />
        <li style={Style.liStyle}>
          Third-party Terms of Agreements and Beneficiary
          <View style={Style.spacerSmall} />
          <Text>
            Zulu Aviation Technologies, LLC represents and warrants that Zulu
            Aviation Technologies, LLC will comply with applicable third-party
            terms of agreement when using Licensed Application.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            In Accordance with Section 9 of the "Instructions for Minimum Terms
            of Developer's End-User License Agreement," Google's subsidiaries
            shall be third-party beneficiaries of this End User License
            Agreement and — upon Your acceptance of the terms and conditions of
            this License Agreement, Google will have the right (and will be
            deemed to have accepted the right) to enforce this End User License
            Agreement against You as a third-party beneficiary thereof.
          </Text>
        </li>
        <View style={Style.spacerSmall} />
        <li style={Style.liStyle}>
          Intellectual Property Rights
          <View style={Style.spacerSmall} />
          <Text>
            Zulu Aviation Technologies, LLC and the End-User acknowledge that,
            in the event of any third-party claim that the Licensed Application
            or the End-User's possession and use of that Licensed Application
            infringes on the third party's intellectual property rights, Zulu
            Aviation Technologies, LLC, and not the Services, will be solely
            responsible for the investigation, defense, settlement, and
            discharge or any such intellectual property infringement claims.
          </Text>
        </li>
        <View style={Style.spacerSmall} />
        <li style={Style.liStyle}>
          Applicable Law
          <View style={Style.spacerSmall} />
          <Text>
            This License Agreement is governed by the laws of the State of
            California excluding its conflicts of law rules.
          </Text>
        </li>
        <View style={Style.spacerSmall} />
        <li style={Style.liStyle}>
          Miscellaneous
          <View style={Style.spacerSmall} />
          <Text>
            17.1 If any of the terms of this agreement should be or become
            invalid, the validity of the remaining provisions shall not be
            affected. Invalid terms will be replaced by valid ones formulated in
            a way that will achieve the primary purpose.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            17.2 Collateral agreements, changes and amendments are only valid if
            laid down in writing. The preceding clause can only be waived in
            writing.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            17.3 Any cancelation within 8 hours will result in a 50% pay out of
            the pilot pay and any cancelation within 3 hours will result in 100%
            pilot pay.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            17.4 It is the responsibly of the customer to maintain proper
            aircraft insurance.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            17.5 It is the pilots responsibility to adhere to all local, state
            and federal regulations when exercising Pilot in command duties.
          </Text>
          <View style={Style.spacerSmall} />
          <Text>
            17.6 This agreement for pilot services will remain in effect
            indefinitely unless canceled by the pilot or customer and my be done
            at anytime without notice.
          </Text>
        </li>
      </ol>
      <View style={Style.spacer} />
      <Text style={Style.disclaimerText}>
        By clicking the button below, you agree to and accept the Other Agreements and End User License Agreement.
      </Text>
      <Button title="I Agree" color="success" onPress={agree} />
      <View style={Style.spacer} />
    </ScrollView>
  );
}
