import * as React from "react";
import { StyleSheet } from "react-native";
import { useForm, Controller } from "react-hook-form";

import { BaseHeader } from "../../components/BaseHeader";
import Theme from "../../constants/Theme";
import { View, Text } from "../../components/Themed";
import { api } from "../../api";
import { MemberRole } from "../../types/User";
import { Button } from "../../components/Button";
import TextInput from "../../components/TextInput";
import RadioGroup from "../../components/RadioGroup";
import { isEmail, required } from "../../util/formRules";
import { InvitationType } from "../../types/Invitation";
import { useMutation } from "react-query";
import ScreenContainer from "../../components/ScreenContainer";
import { useMember } from "../../hooks/useUser";
import { AddMemberScreenProps } from "../../navigation/types";

type AddMemberForm = {
  role: MemberRole | null;
  inviteeEmail: string;
};

export default function AddMemberScreen({ navigation}: AddMemberScreenProps) {
  const { user } = useMember();

  //! RBAC
  React.useEffect(() => {
    if (!user?.role) return;
    if(!([MemberRole.SUPERADMIN, MemberRole.ADMIN].includes(user?.role))) {
      //* Replace screen on stack to prevent returning to this screen on back button
      navigation.replace('NotFound')
    }
  }, [])
  //!

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AddMemberForm>({
    defaultValues: {
      role: null,
      inviteeEmail: "",
    },
  });
  const sendMutation = useMutation((d: AddMemberForm) =>
    api.sendInvitation(
      d.inviteeEmail,
      d.role === MemberRole.ADMIN
        ? InvitationType.ADMIN_USER
        : InvitationType.GENERAL_USER
    )
  );
  const [succMsg, setSuccMsg] = React.useState("");
  const [errMsg, setErrMsg] = React.useState("");

  const sendInvite = async (d: AddMemberForm) => {
    try {
      setSuccMsg("");
      setErrMsg("");
      await sendMutation.mutateAsync(d);
      setSuccMsg("Invitation sent!");
    } catch (err) {
      setErrMsg(
        "There was a problem sending this invitation. Please try again later."
      );
    }
  };

  return (
    <ScreenContainer>
      <View>
        <Text style={styles.header}>1. Select a Role</Text>
        <Text style={styles.subheader}>
          Tell us what role you would like the new member to have.
        </Text>
        <Controller
          name="role"
          control={control}
          rules={required}
          render={({ field }) => (
            <RadioGroup
              {...field}
              items={[
                { label: "Admin User", value: MemberRole.ADMIN },
                { label: "General User", value: MemberRole.GENERAL },
              ]}
              error={errors.role}
            />
          )}
        />
        <View style={styles.spacer} />
        <Text style={styles.header}>2. Send Invite</Text>
        <Text style={styles.subheader}>Add the member's email below.</Text>
        <Controller
          name="inviteeEmail"
          control={control}
          rules={{ ...required, ...isEmail }}
          render={({ field }) => (
            <TextInput {...field} label="Email" error={errors.inviteeEmail} />
          )}
        />
        <View style={styles.spacer} />
        <Text style={Theme.successMessage}>{succMsg}</Text>
        <Text style={Theme.errorMessage}>{errMsg}</Text>
      </View>
      <View style={{ paddingVertical: 20 }}>
        <Button
          title="Send Invite"
          onPress={handleSubmit(sendInvite)}
          disabled={sendMutation.isLoading}
        />
      </View>
    </ScreenContainer>
  );
}

export function AddMemberScreenHeader() {
  return (
    <BaseHeader
      title="Members"
      leftIcon="back"
      rightIcon="notifications"
      color="blue"
    />
  );
}

const styles = StyleSheet.create({
  header: {
    ...Theme.h1,
    textAlign: "center",
  },
  subheader: {
    textAlign: "center",
    marginHorizontal: "auto",
    maxWidth: 300,
    marginTop: 8,
    marginBottom: 16,
  },
  spacer: {
    height: 36,
  },
});
