import { StyleSheet } from "react-native";

import { View, Text } from "../../components/Themed";
import { BaseHeader } from "../../components/BaseHeader";
import { Button } from "../../components/Button";
import { SuccessScreenProps } from "../../navigation/types";
import useCheckInit from "../../hooks/useCheckInit";
import Style from "../../constants/CreateAccountStyles";

export default function SuccessScreen({ navigation }: SuccessScreenProps) {
  useCheckInit();

  const launch = () => {
    navigation.getParent()?.navigate("Unauth");
  };

  return (
    <View style={Style.page}>
      <View style={Style.headerContainer}>
        <Text style={Style.header}>Congratulations!</Text>
        <Text style={Style.subheading}>
          You've successfully created an account with Zephur. Click the button
          below to head to the login screen and officially start using the app!
        </Text>
      </View>
      <Button
        title="Launch Zephur"
        variant="large"
        style={styles.launchBtn}
        onPress={launch}
      />
    </View>
  );
}

export function SuccessScreenHeader() {
  return <BaseHeader leftIcon="none" />;
}

const styles = StyleSheet.create({
  launchBtn: {
    marginTop: 40,
  },
});
