import { StyleSheet } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm, useWatch, Control } from "react-hook-form";
import { useQuery } from "react-query";

import { BaseHeader } from "../components/BaseHeader";
import { Button } from "../components/Button";
import SelectInput from "../components/SelectInput";
import { Text, View } from "../components/Themed";
import Theme from "../constants/Theme";
import {
  RequestTripNavigationProp,
  RequestTripScreenProps,
} from "../navigation/types";
import { RootState } from "../store";
import { useUser } from "../hooks";
import { api } from "../api";
import { setTripInfo, TripInfo } from "../store/requestTrip";
import { TripType } from "../types/Trip";
import { useNavigation } from "@react-navigation/native";
import { required } from "../util/formRules";
import { UserType } from "../types/User";
import PreviewPicture from "../components/PreviewPicture";
import { useOrgAircraft } from "../hooks/useOrgAircraft";
import ScreenContainer from "../components/ScreenContainer";

function PilotPreview({ control }: { control: Control<TripInfo, any> }) {
  const aircraftId = useWatch({ control, name: "aircraftId" });
  const { data: aircraft } = useQuery(
    ["aircraft", aircraftId],
    () => api.getAircraft(aircraftId),
    { enabled: !!aircraftId }
  );
  const pilot = aircraft?.pilot;

  return (
    <View style={{ marginTop: 20 }}>
      {pilot && (
        <PreviewPicture
          url={pilot.photoUrl}
          title={`${pilot.firstName} ${pilot.lastName}`}
        />
      )}
    </View>
  );
}

export default function RequestTripScreen({
  navigation,
}: RequestTripScreenProps) {
  const tripInfo = useSelector(
    (state: RootState) => state.requestTrip.tripInfo
  );
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: tripInfo,
  });
  const { user } = useUser();
  const orgId = user?.type === UserType.MEMBER && user.organization;
  const { aircraft } = useOrgAircraft(orgId);

  // Only show aircraft that have a pilot assigned.
  const tailNumbers = aircraft
    ?.filter((a) => !!a.pilot)
    .map((a) => ({
      label: a.tailNumber,
      value: a.id,
    }));
  const tripTypes = [
    { label: "Drop off", value: TripType.DROP_OFF },
    { label: "Standby", value: TripType.STANDBY },
  ];

  const next = (d: TripInfo) => {
    dispatch(setTripInfo(d));
    navigation.push("DepartInfo");
  };

  return (
    <ScreenContainer>
      <View>
        <Text style={styles.header}>
          What plane would you like to{"\n"}use for this trip?
        </Text>

        {tailNumbers && (
          <Controller
            name="aircraftId"
            control={control}
            rules={required}
            render={({ field }) => (
              <SelectInput
                {...field}
                label="Tail Number"
                items={tailNumbers}
                error={errors.aircraftId}
              />
            )}
          />
        )}
        {tailNumbers && tailNumbers.length === 0 && (
          <Text style={Theme.errorMessage}>
            Your organization doesn't have any pilots!
          </Text>
        )}
        <PilotPreview control={control} />

        <View style={Theme.spacerLarge} />
        <Text style={styles.header}>What type of trip is this?</Text>
        <Controller
          name="tripType"
          control={control}
          render={({ field }) => (
            <SelectInput
              {...field}
              label="Trip Type"
              items={tripTypes}
              error={errors.tripType}
            />
          )}
        />
      </View>
      <View>
        <Button
          title="Next"
          style={styles.nextBtn}
          onPress={handleSubmit(next)}
        />
        <View style={styles.spacer} />
      </View>
    </ScreenContainer>
  );
}

export function RequestTripScreenHeader() {
  const navigation = useNavigation<RequestTripNavigationProp>();

  const onBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.getParent()?.navigate("Home");
    }
  };

  return (
    <BaseHeader
      title="Request a Trip"
      leftIcon="back"
      rightIcon="none"
      onBack={onBack}
    />
  );
}

export const styles = StyleSheet.create({
  header: {
    ...Theme.h2,
    textAlign: "center",
    marginBottom: 16,
  },
  spacer: {
    ...Theme.spacer,
  },
  nextBtn: {
    marginLeft: "auto",
    minWidth: 100,
  },
});
