import * as React from "react";
import { StyleSheet } from "react-native";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";

import Theme from "../constants/Theme";
import Colors from "../constants/Colors";
import { BaseHeader } from "../components/BaseHeader";
import { Button } from "../components/Button";
import { View, Text } from "../components/Themed";
import { useAuth } from "../hooks";
import CtlTextInput from "../components/ControlledInputs/CtlTextInput";
import { required } from "../util/formRules";
import ScreenContainer from "../components/ScreenContainer";
import { UpdatePasswordScreenProps } from "../navigation/types";
import { Ionicons } from "@expo/vector-icons";

type NewPasswordData = {
	email: string;
	password: string;
	confirmPassword: string;
};

export default function NewPasswordScreen({ navigation, route }: UpdatePasswordScreenProps) {
	if (!route.params)	throw new Error("Email not provided");
	const { email } = route.params;
	const { control, handleSubmit, reset } = useForm<NewPasswordData>();
	const newPasswordMutation = useMutation((d: NewPasswordData) => resetPassword(d));
	const [errMsg, setErrMsg] = React.useState("");
	const [securePass, setSecurePass] = React.useState(true);
	const [secureConfirmPass, setSecureConfirmPass] = React.useState(true);
	const { resetPassword } = useAuth();

	const passwordToggle = ( pass: Boolean ) => {
		if (!pass) setSecureConfirmPass(!secureConfirmPass);
		else setSecurePass(!securePass);
	};

	const callNewPassword = async ( d: NewPasswordData ) => {
		try {
			setErrMsg( "" );
			d.email = email;
			if ( d.password !== d.confirmPassword )	setErrMsg( "Passwords do not match" );
			await newPasswordMutation.mutateAsync( d );
			reset();
			navigation.navigate( "Login" );
		} catch ( err ) {
			if ( errMsg ) true;
			setErrMsg( "There was an error resetting your password." || err );
		}
	};

	return (
		<ScreenContainer>
			<View style={styles.main}>
				<Text style={styles.title}>New Password</Text>
				<CtlTextInput
					name="password"
					control={control}
					rules={required}
					containerStyle={styles.textInput}
					centerText
					label="Password"
					placeholder="Password"
					textContentType="password"
					secureTextEntry={securePass}
				></CtlTextInput>
				<Text
					style={styles.paragraph}
					onPress={() => passwordToggle(true)}
				>
					<Ionicons
						name={securePass ? "eye" : "eye-off"}
						size={24}
						color={Colors.light.tint}
						style={styles.icon}
					/>
					view password
				</Text>
				<CtlTextInput
					name="confirmPassword"
					control={control}
					rules={required}
					containerStyle={styles.textInput}
					centerText
					label="Confirm Password"
					placeholder="Confirm Password"
					textContentType="password"
					secureTextEntry={secureConfirmPass}
				></CtlTextInput>
				<Text
					style={styles.paragraph}
					onPress={() => passwordToggle(false)}
				>
					<Ionicons
						name={secureConfirmPass ? "eye" : "eye-off"}
						size={24}
						color={Colors.light.tint}
						style={styles.icon}
					/>
					view password
				</Text>
				<Text style={styles.errMsg}>{errMsg}</Text>
				<Button
					title="Reset Password"
					variant="large"
					onPress={handleSubmit(callNewPassword)}
					style={styles.button}
					disabled={newPasswordMutation.isLoading}
				/>
			</View>
		</ScreenContainer>
	)
}

export function UpdatePasswordHeader({ navigation }: NativeStackHeaderProps) {

	return <BaseHeader	leftIcon="login" />;
}

const styles = StyleSheet.create({
  container: {
    ...Theme.page,
  },
  contentContainer: {
    flex: 1,
    justifyContent: "space-between",
  },
  main: {
    alignItems: "center",
  },
  pretitle: {
    color: Colors.theme.primary,
    fontWeight: "600",
    fontSize: 16,
  },
  title: {
    color: Colors.theme.primary,
    fontSize: 48,
    fontWeight: "bold",
    marginBottom: 24,
  },
  textInput: {
    alignSelf: "stretch",
    marginTop: 16,
  },
  errMsg: {
    ...Theme.errorMessage,
  },
  button: {
    marginTop: 25,
    paddingHorizontal: 32,
  },
  signInBtn: {
    marginTop: 15,
    paddingHorizontal: 48,
  },
	paragraph: {
		color: Colors.light.tint,
		marginTop: 15,
		fontSize: 16,
		fontWeight: "bold",
		marginBottom: 24,
		display: "flex",
	},
	icon: {
		marginRight: 10,
	},
});