import { ScrollView, StyleSheet, TouchableOpacity } from "react-native";
import { useQuery } from "react-query";

import Theme from "../../constants/Theme";
import { Text, View } from "../../components/Themed";
import { api } from "../../api";
import { BaseHeader } from "../../components/BaseHeader";
import { ViewPilotScreenProps } from "../../navigation/types";
import { Button } from "../../components/Button";
import BioHeading from "../../components/BioHeading";
import { displayUserRole } from "../../util/utils";
import TextInput from "../../components/TextInput";
import { useMember } from "../../hooks/useUser";
import { MemberRole } from "../../types/User";

export default function ViewPilotScreen({
  route,
  navigation,
}: ViewPilotScreenProps) {
  const pilotId = route.params.id;
  const { data: pilot } = useQuery(["users", pilotId], () =>
    api.getUser(pilotId)
  );
  const { user } = useMember();

  const showDeleteBtn = user && user.role !== MemberRole.GENERAL;

  const deletePilot = () => {
    navigation.push( "ConfirmDeletePilot", { id: route.params.id } );
  }

  return (
    <ScrollView style={Theme.page} contentContainerStyle={Theme.pageContent}>
      <View>
        {pilot && (
          <BioHeading
            url={pilot.photoUrl}
            title={`${pilot.firstName} ${pilot.lastName}`}
            subtitle={displayUserRole(pilot)}
          />
        )}
        <TextInput
          name="cell"
          value={pilot?.phone || ""}
          label="Cell"
          editable={false}
        />
        <TextInput
          name="email"
          value={pilot?.email || ""}
          label="Email"
          editable={false}
          containerStyle={{ marginTop: 16 }}
        />
      </View>
      {showDeleteBtn && (
        <Button title="Delete Pilot" onPress={deletePilot} color="danger" />
      )}
    </ScrollView>
  );
}

export function ViewPilotScreenHeader() {
  return (
    <BaseHeader
      title="Pilots"
      leftIcon="back"
      rightIcon="notifications"
      color="blue"
    />
  );
}
