import * as React from "react";
import { StyleSheet } from "react-native";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useForm, Controller } from "react-hook-form";

import { BaseHeader } from "../../components/BaseHeader";
import Theme from "../../constants/Theme";
import { View, Text } from "../../components/Themed";
import { Button } from "../../components/Button";
import { api } from "../../api";
import { MemberRole } from "../../types/User";
import { ChangeRoleScreenProps } from "../../navigation/types";
import BioHeading from "../../components/BioHeading";
import RadioGroup from "../../components/RadioGroup";
import { required } from "../../util/formRules";
import Colors from "../../constants/Colors";
import ScreenContainer from "../../components/ScreenContainer";
import { useMember } from "../../hooks/useUser";

type ChangeRoleForm = {
  role: MemberRole | null;
};

export default function ChangeRoleScreen({
  route,
  navigation,
}: ChangeRoleScreenProps) {
  const { user } = useMember();
  const memberId = route.params.id;

  //! RBAC
  React.useEffect(() => {
    if (!user?.role) return;
    if(!([MemberRole.SUPERADMIN].includes(user?.role))) {
      //* Replace screen on stack to prevent returning to this screen on back button
      navigation.replace('NotFound')
    }
  }, [])
  //!
  
  const { data: member, isLoading: loadingMember } = useQuery(
    ["users", memberId],
    () => api.getUser(memberId),
    {
      enabled: !!memberId, // What if user navigates here straight from URL?
    }
  );
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (role: MemberRole) => api.updateMemberRole(memberId, role),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        queryClient.invalidateQueries(["organizations"]);
      },
    }
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangeRoleForm>({
    defaultValues: {
      role: null,
    },
  });
  const [isFetching, setIsFetching] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");

  const changeRole = async (d: ChangeRoleForm) => {
    if (!d.role) return;
    // console.log("Change role", d);
    try {
      setIsFetching(true);
      setErrMsg("");
      await mutation.mutateAsync(d.role);
      navigation.goBack();
      // navigation.goBack("ViewMember", { id: memberId });
    } catch (err) {
      setErrMsg("There was a problem changing this role.");
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <ScreenContainer>
      <View>
        {member && (
          <BioHeading
            url={member.photoUrl}
            title={`${member.firstName} ${member.lastName}`}
          />
        )}
        <View style={styles.border} />
        <View style={styles.content}>
          <Text style={styles.header}>Select a Role</Text>
          <Text style={styles.subheading}>
            Tell us what role you would like the new member to have.
          </Text>
          <Controller
            name="role"
            control={control}
            rules={required}
            render={({ field }) => (
              <RadioGroup
                {...field}
                items={[
                  { label: "Super Admin", value: MemberRole.SUPERADMIN },
                  { label: "Admin User", value: MemberRole.ADMIN },
                  { label: "General User", value: MemberRole.GENERAL },
                ]}
                error={errors.role}
              />
            )}
          />
          <Text style={styles.errMsg}>{errMsg}</Text>
        </View>
      </View>
      <View style={{ paddingVertical: 20 }}>
        <Button
          title="Change Role"
          disabled={isFetching}
          onPress={handleSubmit(changeRole)}
        />
      </View>
    </ScreenContainer>
  );
}

export function ChangeRoleScreenHeader() {
  return (
    <BaseHeader
      title="Members"
      leftIcon="back"
      rightIcon="notifications"
      color="blue"
    />
  );
}

const styles = StyleSheet.create({
  header: {
    ...Theme.h1,
    textAlign: "center",
  },
  subheading: {
    textAlign: "center",
    maxWidth: 300,
    marginHorizontal: "auto",
    marginTop: 8,
  },
  border: {
    borderTopColor: "rgba(0, 0, 0, 0.2)",
    borderTopWidth: 1,
    marginHorizontal: -20,
  },
  content: {
    paddingTop: 32,
  },
  errMsg: {
    color: Colors.theme.danger,
    textAlign: "center",
  },
});
