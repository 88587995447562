import { useQuery } from "react-query";
import * as React from "react";
import { BaseHeader } from "../../components/BaseHeader";
import { View } from "../../components/Themed";
import { api } from "../../api";
import { ViewMemberScreenProps } from "../../navigation/types";
import { Button } from "../../components/Button";
import BioHeading from "../../components/BioHeading";
import { displayUserRole } from "../../util/utils";
import TextInput from "../../components/TextInput";
import { hasPermission } from "../../util/permissions";
import { useMember } from "../../hooks/useUser";
import { MemberRole } from "../../types/User";
import ScreenContainer from "../../components/ScreenContainer";
import { useAuth } from "../../hooks";

export default function ViewMemberScreen({
  route,
  navigation,
}: ViewMemberScreenProps) {
  const { user } = useMember();
  const memberId = route.params.id;

  const { data: member, isLoading } = useQuery(
    ["users", memberId],
    () => api.getUser(memberId),
    {
      enabled: !!memberId, // What if user navigates here straight from URL?
    }
  );

  const canChangeRole =
    hasPermission(user, "changeMemberRoles") && user?.id !== memberId;

  const showDeleteBtn =
    user?.role === MemberRole.SUPERADMIN && user.id !== route.params.id;

  const changeRole = () => {
    navigation.push("ChangeRole", { id: memberId });
  };

  const deleteUser = () => {
    navigation.push("ConfirmDeleteMember", { id: route.params.id });
  };

  return (
    <ScreenContainer>
      <View>
        {member && (
          <BioHeading
            url={member.photoUrl}
            title={`${member.firstName} ${member.lastName}`}
            subtitle={displayUserRole(member)}
          />
        )}
        {canChangeRole && (
          <Button
            title="Change Role"
            onPress={changeRole}
            style={{ alignSelf: "center", marginBottom: 20 }}
          />
        )}
        <TextInput
          name="cell"
          value={member?.phone || ""}
          label="Cell"
          editable={false}
        />
        <TextInput
          name="email"
          value={member?.email || ""}
          label="Email"
          editable={false}
          containerStyle={{ marginTop: 16 }}
        />
      </View>
      {showDeleteBtn && (
        <Button title="Delete User" onPress={deleteUser} color="danger" />
      )}
    </ScreenContainer>
  );
}

export function ViewMemberScreenHeader() {
  return (
    <BaseHeader
      title="Members"
      leftIcon="back"
      rightIcon="notifications"
      color="blue"
    />
  );
}
