import * as React from "react";
import { useSelector } from "react-redux";

import { Button } from "../../components/Button";
import { Text, View } from "../../components/Themed";
import Style from "../../constants/CreateAccountStyles";
import { useCheckInit, useUser } from "../../hooks";
import { RootState } from "../../store";
import { ConfirmInvScreenProps } from "../../navigation/types";
import { api } from "../../api";
import { InvitationType } from "../../types/Invitation";
import LoadingIndicator from "../../components/LoadingIndicator";
import { BaseHeader } from "../../components/BaseHeader";

enum ScreenState {
  PROMPT,
  LOADING,
  SUCCESS,
}

export default function ConfirmInvScreen({
  navigation,
}: ConfirmInvScreenProps) {
  useCheckInit();
  useUser();
  const inv = useSelector((state: RootState) => state.createAccount.invitation);
  const [screen, setScreen] = React.useState<ScreenState>(ScreenState.PROMPT);

  let subheading = "";
  switch (inv?.invitationType) {
    case InvitationType.SUBCONTRACTOR:
      subheading = `${inv.referrer.firstName} ${inv.referrer.lastName} is inviting you to be their subcontractor. Do you agree?`;
      break;
    case InvitationType.ORGANIZATION:
      subheading = `${inv.referrer.firstName} ${inv.referrer.lastName} is wanting to join your organization. Do you agree?`;
      break;
    case InvitationType.PILOT_TAIL_NUMBER:
      subheading = `${inv.referrer.firstName} ${inv.referrer.lastName} is inviting you to operate tail number ${inv.aircraft?.tailNumber}. Do you agree?`;
      break;
    default:
      // Should never happen.
      break;
  }

  const accept = async () => {
    if (!inv) return;
    try {
      await api.acceptInvitation(inv.id);
      setScreen(ScreenState.SUCCESS);
    } catch (err) {
      setScreen(ScreenState.PROMPT);
    }
  };

  const deny = async () => {
    if (!inv) return;
    try {
      await api.rejectInvitation(inv.id);
      setScreen(ScreenState.SUCCESS);
    } catch (err) {
      setScreen(ScreenState.PROMPT);
    }
  };

  const backToHome = () => {
    navigation.getParent()?.navigate("Auth");
  };

  return (
    <View style={Style.page}>
      {screen === ScreenState.PROMPT && (
        <>
          <View style={Style.headerContainer}>
            <Text style={Style.header}>Confirm Invitation</Text>
            <Text style={Style.subheading}>{subheading}</Text>
          </View>
          <View style={Style.spacer} />
          <Button title="Accept" onPress={accept} />
          <View style={Style.spacer} />
          <Button title="Deny" color="danger" onPress={deny} />
        </>
      )}
      {screen === ScreenState.LOADING && <LoadingIndicator />}
      {screen === ScreenState.SUCCESS && (
        <>
          <View style={Style.headerContainer}>
            <Text style={Style.header}>Success</Text>
          </View>
          <Button title="Back to Home" onPress={backToHome} />
        </>
      )}
    </View>
  );
}
