import * as React from "react";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { RefreshControl } from "react-native";
import Theme from "../constants/Theme";

// type ScreenContainerProps = {
//   children?: JSX.Element | JSX.Element[];
// };
type ScreenContainerProps = React.PropsWithChildren<{}>;

export default function ScreenContainer(props: ScreenContainerProps) {

  const [refreshing, setRefreshing] = React.useState(false);

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    setTimeout(() => setRefreshing(false), 2000);
  }, []);

  return (
    <KeyboardAwareScrollView
      style={Theme.page}
      contentContainerStyle={Theme.pageContent}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
      {...props}
    />
  );
}
