import {
  ButtonProps as DefaultButtonProps,
  TouchableOpacity,
  StyleSheet,
  Button,
  ViewStyle,
} from "react-native";

import { Text } from "../components/Themed";

interface SubtleButtonProps extends DefaultButtonProps {
  style?: ViewStyle;
}

export default function SubtleButton(props: SubtleButtonProps) {
  return (
    <TouchableOpacity style={props.style} onPress={props.onPress}>
      <Text style={styles.text}>{props.title}</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  text: {
    textDecorationLine: "underline",
    textAlign: "center",
  },
});
