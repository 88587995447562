import { useQuery } from "react-query";
import { Falsy } from "react-native";

import { api } from "../api";

export default function useOrgUsers(orgId: string | Falsy) {
  const { data: users, ...rest } = useQuery(
    ["organizations", orgId, "users"],
    () => api.getOrgUsers(orgId as string),
    { enabled: !!orgId }
  );

  return { users, ...rest };
}
