import * as React from "react";
import { ScrollView, StyleSheet } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm, FieldError } from "react-hook-form";

import Style from "../../constants/CreateAccountStyles";
import TextInput from "../../components/TextInput";
import { View, Text } from "../../components/Themed";
import { Button } from "../../components/Button";
import RadioGroup from "../../components/RadioGroup";
import FileInput from "../../components/FileInput";
import { required } from "../../util/formRules";
import { PilotInformationScreenProps } from "../../navigation/types";
import useCheckInit from "../../hooks/useCheckInit";
import { RootState } from "../../store";
import { PilotInfo, setPilotInfo } from "../../store/createAccount";

export default function PilotInformationScreen({
  navigation,
}: PilotInformationScreenProps) {
  useCheckInit();
  const pilotInfo = useSelector(
    (state: RootState) => state.createAccount.pilotInfo
  );
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: pilotInfo,
  });

  const certLevels = [
    { label: "Commercial Pilot", value: "commercial" },
    { label: "Airline Transportation Pilot", value: "airline_transportation" },
  ];

  const submit = (data: PilotInfo) => {
    dispatch(setPilotInfo(data));
    navigation.push("TermsAndInfo");
  };

  return (
    <ScrollView style={Style.page} contentContainerStyle={Style.pageContent}>
      <View>
        <View style={Style.headerContainer}>
          <Text style={Style.header}>Pilot Information</Text>
          <Text style={Style.subheading}>Tell us your qualifications.</Text>
        </View>
        <View style={Style.spacer} />
        <Controller
          name="certLevel"
          control={control}
          rules={{
            required: { value: true, message: "This field is required." },
          }}
          render={({ field }) => (
            <RadioGroup
              {...field}
              label="Choose your certificate level:"
              items={certLevels}
              error={errors.certLevel}
            />
          )}
        />
        <View style={Style.spacer} />
        <Controller
          name="certNo"
          control={control}
          rules={required}
          render={ ( { field } ) => (
            <TextInput
              {...field}
              label="Pilot Certificate #"
              placeholder="123456780"
              keyboardType="numeric"
              error={errors.certNo}
            />
          )}
        />
        <View style={Style.spacer} />
        <Controller
          name="resume"
          control={control}
          rules={{
            required: { value: true, message: "This field is required." },
          }}
          render={({ field }) => (
            <FileInput
              value={field.value}
              onChange={field.onChange}
              label="Upload Resume"
              mimeTypes={["application/pdf", "image/png", "image/jpeg"]}
              error={errors.resume as FieldError | undefined}
            />
          )}
        />
        <View style={Style.spacer} />
        <Controller
          name="certImage"
          control={control}
          rules={{
            required: { value: true, message: "This field is required." },
          }}
          render={({ field }) => (
            <FileInput
              value={field.value}
              onChange={field.onChange}
              label="Upload Image of Certificate"
              mimeTypes={["image/png", "image/jpeg"]}
              error={errors.certImage as FieldError | undefined}
            />
          )}
        />
      </View>
      <View>
        <Button
          title="Submit"
          style={Style.nextBtn}
          onPress={handleSubmit(submit)}
        />
        <View style={Style.spacer} />
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
