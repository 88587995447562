import { Image, ImageSourcePropType, StyleSheet } from "react-native";

import Theme from "../../constants/Theme";
import { Button } from "../../components/Button";
import { Text, View } from "../../components/Themed";
import Colors from "../../constants/Colors";
import { TripScheduledScreenProps } from "../../navigation/types";

const checkmark: ImageSourcePropType = {
  uri: require("../../assets/images/checkmark.png"),
};

export default function TripScheduledModal({
  route,
  navigation,
}: TripScheduledScreenProps) {
  const { tripId } = route.params;

  const onPress = () => {
    navigation.navigate("Trip", { tripId });
  };

  return (
    <View style={styles.container}>
      <Image style={styles.checkmark} source={checkmark} />
      <Text style={styles.header}>Trip Scheduled!</Text>
      <Button
        title="Return to Dashboard"
        onPress={onPress}
        variant="large"
        style={styles.returnBtn}
        textStyle={{ color: Colors.theme.primary }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    ...Theme.page,
    backgroundColor: Colors.theme.primary,
    justifyContent: "center",
    alignItems: "center",
  },
  checkmark: {
    width: 64,
    height: 64,
    marginBottom: 24,
    marginTop: 96,
  },
  header: {
    ...Theme.h1,
    color: "#fff",
  },
  returnBtn: {
    backgroundColor: "#fff",
    marginTop: "auto",
  },
});
