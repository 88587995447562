import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PlacesValue } from "../components/GooglePlacesInput";
import { DetailType, TripDetail, TripType } from "../types/Trip";

export type LocationData = PlacesValue;

export type DetailFormData = {
  location: LocationData;
  airport: string;
  date: string;
  time: string;
  preferredFBO: string;
  detailType: DetailType;
};

export interface TripInfo {
  aircraftId: string;
  pilotId: string;
  tripType: TripType;
}

export interface MoreDetails {
  passengers: string;
  bags: string;
  notes: string;
}
export interface RequestTripState {
  initialized: boolean;
  tripInfo: TripInfo;
  departureInfo: DetailFormData;
  arrivalInfo: DetailFormData;
  returnInfo: DetailFormData;
  moreDetails: MoreDetails;
}


const makeEmptyDetailFormData = (detailType: DetailType) => ({
  location: {
    city: "",
    state: "",
    country: "",
    street: "",
    zip: "",
    placesText: "",
  },
  airport: "",
  date: "",
  time: "Anytime",
  preferredFBO: "",
  detailType,
});



// const makeFilledDetailFormData = (detailType: DetailType) => ({
//   location: {
//     city: "",
//     state: "",
//     country: "",
//     street: "",
//     zip: "",
//     placesText: "",
//   },
//   airport: "",
//   date: "",
//   time: "",
//   preferredFBO: "",
//   detailType,
// });

const initialState: RequestTripState = {
  initialized: false,
  tripInfo: {
    aircraftId: "",
    pilotId: "",
    tripType: TripType.STANDBY,
  },
  departureInfo: makeEmptyDetailFormData(DetailType.DEPARTURE),
  arrivalInfo: makeEmptyDetailFormData(DetailType.ARRIVAL),
  returnInfo: makeEmptyDetailFormData(DetailType.RETURN),
  moreDetails: {
    passengers: "",
    bags: "",
    notes: "",
  },
};

// Useful for testing.
// const filledInitialState: RequestTripState = {
//   initialized: true,
//   tripInfo: {
//     aircraftId: "",
//     pilotId: "",
//     tripType: TripType.STANDBY,
//   },
//   departureInfo: makeFilledDetailFormData(DetailType.DEPARTURE),
//   arrivalInfo: makeFilledDetailFormData(DetailType.ARRIVAL),
//   returnInfo: makeFilledDetailFormData(DetailType.RETURN),
//   moreDetails: {
//     passengers: "2",
//     bags: "3",
//     notes: "Don't crash the plane please.",
//   },
// };

const requestTrip = createSlice({
  name: "requestTrip",
  initialState, //filledInitialState
  reducers: {
    initialize: (state) => {
      state.initialized = true;
    },
    setTripInfo: (state, action: PayloadAction<TripInfo>) => {
      state.tripInfo = action.payload;
    },
    setDetail: (
      state,
      action: PayloadAction<{ detailType: DetailType; data: DetailFormData }>
    ) => {
      const { detailType, data } = action.payload;
      switch (detailType) {
        case DetailType.DEPARTURE:
          state.departureInfo = data;
          break;
        case DetailType.ARRIVAL:
          state.arrivalInfo = data;
          break;
        case DetailType.RETURN:
          state.returnInfo = data;
          break;
      }
    },
    setMoreDetail: (state, action: PayloadAction<MoreDetails>) => {
      state.moreDetails = action.payload;
    },
    clearRequestTrip: () => initialState,
  },
});
const ClearTrip = createSlice({
  name: "ClearTrip",
  initialState:initialState , 
  reducers: {
    initialize: (state) => {
      state.initialized = true;
    },
    setTripInfo: (state, action: PayloadAction<TripInfo>) => {
      state.tripInfo = action.payload;
    },
    setDetail: (
      state,
      action: PayloadAction<{ detailType: DetailType; data: DetailFormData }>
    ) => {
      const { detailType, data } = action.payload;
      switch (detailType) {
        case DetailType.DEPARTURE:
          state.departureInfo = data;
          break;
        case DetailType.ARRIVAL:
          state.arrivalInfo = data;
          break;
        case DetailType.RETURN:
          state.returnInfo = data;
          break;
      }
    },
    setMoreDetail: (state, action: PayloadAction<MoreDetails>) => {
      state.moreDetails = action.payload;
    },
  },
});
export const { initialize, setTripInfo, setDetail, setMoreDetail, clearRequestTrip } = requestTrip.actions;
export default requestTrip;
