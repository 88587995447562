import { Aircraft } from "./Aircraft";
import { Organization } from "./Organization";
import { Member, Pilot, User } from "./User";

export type Location = {
  city: string;
  state: string;
  country: string;
  street: string;
  zip: string;
  placesText: string;
};

export enum TripStatus {
  PENDING = "pending",
  ACCEPTED = "accepted",
  DECLINED = "declined",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
  BOOKED = "booked",
  PENDING_PAYMENT = "pending_payment",
}

export enum TripType {
  STANDBY = "standby",
  DROP_OFF = "drop_off",
}

export enum DetailType {
  DEPARTURE = "departure",
  ARRIVAL = "arrival",
  RETURN = "return",
}

export type TripDetail = {
  id: string;
  location: Location;
  airport: string;
  hobbs: string;
  /** ISO 8601 date formatted string. */
  date?: string;
  time: string;
  detailType: DetailType;
  // textInput?: string; // Used for GooglePlacesInput form control.
};

export enum PilotStatus {
  PENDING = "pending",
  SUBCONTRACTED = "subcontracted",
  ACCEPTED = "accepted",
  DECLINED = "declined",
}

export type TripPilot = {
  pilotStatus: PilotStatus;
  subcontractedFrom?: Pilot;
  pilot: Pilot;
  trip: string;
  createdAt: string;
};

export type Trip = {
  id: string;
  pointOfContact: Member;
  organization: Organization;
  aircraft: Aircraft;
  tripType: TripType;
  status: TripStatus;
  tripDetails: TripDetail[];
  tripPilots: TripPilot[];
  preferredFBO: string;
  bags: number;
  passengers: number;
  notes: string;
  startHobbs: string;
  endHobbs: string;
  changeRequest?: ChangeRequest;
  changeRequestBy?: User;
};

/**
 * Trip entity format for editing.
 */
export type UpdateTripDto = Omit<Trip, "aircraft"> & {
  aircraftId: string;
  departureInfo?: TripDetail;
  arrivalInfo?: TripDetail;
  returnInfo?: TripDetail;
};

export type ChangeRequest = Partial<UpdateTripDto>;
