import * as React from "react";
import { ScrollView, StyleSheet } from "react-native";
import * as DocumentPicker from "expo-document-picker";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm, FieldError } from "react-hook-form";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import PhotoInput from "../../components/PhotoInput";
import TextInput from "../../components/TextInput";
import { View, Text } from "../../components/Themed";
import { Button } from "../../components/Button";
import RadioGroup from "../../components/RadioGroup";
import FileInput from "../../components/FileInput";
import { PilotInformationScreenProps } from "../../navigation/types";
import useCheckInit from "../../hooks/useCheckInit";
import {
  OrganizationInfo,
  setOrganizationInfo,
} from "../../store/createAccount";
import { RootState } from "../../store";
import Style from "../../constants/CreateAccountStyles";
import { required } from "../../util/formRules";

export default function OrganizationInfoScreen({
  navigation,
}: PilotInformationScreenProps) {
  useCheckInit();
  const dispatch = useDispatch();
  const organizationInfo = useSelector(
    (state: RootState) => state.createAccount.organizationInfo
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: organizationInfo,
  });

  const submit = (data: OrganizationInfo) => {
    dispatch(setOrganizationInfo(data));
    navigation.push("BasicInfo");
  };

  return (
    <KeyboardAwareScrollView
      style={Style.page}
      contentContainerStyle={Style.pageContent}
    >
      <View>
        <View style={Style.headerContainer}>
          <Text style={Style.header}>Organization Info</Text>
          <Text style={Style.subheading}>
            Tell us a little about your organization.
          </Text>
          <View style={Style.spacer} />
          <Controller
            name="photo"
            control={control}
            rules={required}
            render={({ field }) => (
              <PhotoInput
                value={field.value}
                onChange={field.onChange}
                label="Upload Profile Photo or Company Logo"
                error={errors.photo as FieldError | undefined}
              />
            )}
          />
        </View>
        <View style={Style.spacer} />
        <Controller
          name="name"
          control={control}
          rules={required}
          render={({ field }) => (
            <TextInput
              label="Organization Name"
              {...field}
              error={errors.name}
            />
          )}
        />
        <View style={Style.spacer} />
        <Controller
          name="address1"
          control={control}
          rules={required}
          render={({ field }) => (
            <TextInput label="Address 1" {...field} error={errors.address1} />
          )}
        />
        <View style={Style.spacer} />
        <Controller
          name="address2"
          control={control}
          render={({ field }) => (
            <TextInput
              label="Address 1"
              optional
              {...field}
              error={errors.address2}
            />
          )}
        />
      </View>
      <View>
        <Button
          title="Add Basic Info"
          style={Style.nextBtn}
          onPress={handleSubmit(submit)}
        />
        <View style={Style.spacer} />
      </View>
    </KeyboardAwareScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
