import { StyleSheet } from "react-native";

import Colors from "./Colors";

const PAGE_PADDING = 20;

const Style = {
  pagePadding: PAGE_PADDING,
  page: {
    flex: 1,
    backgroundColor: "#fff",
    padding: PAGE_PADDING,
  },
  pageContent: {
    flex: 1,
    justifyContent: "space-between",
  },
  spacer: {
    height: 20,
    backgroundColor: "none",
  },
  spacerLarge: {
    height: 32,
    backgroundColor: "none",
  },
  dropShadow: {},
  h1: {
    fontSize: 28,
    fontWeight: "600",
  },
  h2: {
    fontSize: 20,
    fontWeight: "600",
  },
  roundedImage: {
    borderRadius: 999,
    borderWidth: 3,
    borderColor: "#000",
  },
  successMessage: {
    color: Colors.theme.success,
    textAlign: "center",
    fontWeight: "600",
  },
  errorMessage: {
    color: Colors.theme.danger,
    textAlign: "center",
    marginVertical: 16,
  },
} as const;

export default Style;
