import { StyleSheet } from "react-native";

import Theme from "../../constants/Theme";
import { Text, View } from "../../components/Themed";
import { Button } from "../../components/Button";
import { RequestTripScreenProps } from "../../navigation/types";
import { BaseHeader } from "../../components/BaseHeader";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export default function RequestTripSuccessScreen({
  navigation,
}: RequestTripScreenProps) {
  const requestTrip = useSelector((state: RootState) => state.requestTrip);

  const backToDashboard = () => {
    navigation.getParent()?.navigate("Home");
  };

  return (
    <View style={Theme.page}>
      <Text style={styles.header}>Trip Requested!</Text>
      <Text style={styles.subheader}>
        You have successfully booked a trip. You will be notified when a pilot
        accepts or declines your request.
      </Text>
      <Button
        title="Back to Dashboard"
        style={styles.backBtn}
        onPress={backToDashboard}
      />
    </View>
  );
}

export function RequestTripSuccessScreenHeader() {
  return <BaseHeader title="Request a Trip" />;
}

const styles = StyleSheet.create({
  header: {
    ...Theme.h1,
    textAlign: "center",
    marginBottom: 16,
  },
  subheader: {
    textAlign: "center",
  },
  backBtn: {
    marginTop: 40,
  },
});
