import { StyleSheet } from "react-native";
import { Ionicons } from "@expo/vector-icons";

import { Text, View } from "./Themed";
import { Trip } from "../types/Trip";
import Colors from "../constants/Colors";
import {
  formatTripType,
  getDestination,
  getDeparture,
  getReturn,
} from "../util/trips";
import { formatDate } from "../util/utils";

type TripCardProps = {
  trip: Trip;
  textColor?: "primary" | "blueishBlack";
  highlightColor?: "gold" | "blueishBlack";
};

export default function TripCard({
  trip,
  textColor = "primary",
  highlightColor = "gold",
}: TripCardProps) {
  const destination = getDestination(trip);
  const departure = getDeparture(trip);
  const tripReturn = getReturn(trip);
  const date = departure && departure.date && formatDate(departure.date);
  const tripType = formatTripType(trip.tripType);

  const color = {
    color: {
      primary: Colors.theme.primary,
      blueishBlack: Colors.theme.blueishBlack,
    }[textColor],
  };

  const hColor = {
    backgroundColor: {
      gold: Colors.theme.gold,
      blueishBlack: Colors.theme.blueishBlack,
    }[highlightColor],
  };

  return (
    <View style={styles.container}>
      <View style={styles.dateContainer}>
        <Text style={StyleSheet.flatten([styles.date, color])}>{date}</Text>
        <Text style={StyleSheet.flatten([styles.status, color])}>
          {tripType}
        </Text>
      </View>
      <View style={styles.mainContainer}>
        <Text style={StyleSheet.flatten([styles.location, color])}>
          {`${departure?.location.city}, ${departure?.location.state} >> ${destination?.location.city}, ${destination?.location.state} >> ${tripReturn?.location.city}, ${tripReturn?.location.state}`}
        </Text>
        <Text style={StyleSheet.flatten([styles.location])}>
          {trip.preferredFBO}
        </Text>
        <Text style={StyleSheet.flatten([styles.orgName, color])}>
          {trip.organization.name}
        </Text>
      </View>
      <View style={StyleSheet.flatten([styles.chevronContainer, hColor])}>
        <Ionicons name="chevron-forward" size={20} color="#fff" />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "rgba(0, 0, 0, 0.2)",
    overflow: "hidden",
    elevation: 2,
    shadowColor: "#000",
    shadowRadius: 4,
    shadowOpacity: 0.2,
    shadowOffset: {
      width: 0,
      height: 2,
    },
  },
  dateContainer: {
    padding: 12,
  },
  date: {
    fontWeight: "600",
    marginBottom: 4,
  },
  status: {},
  mainContainer: {
    flex: 1,
    marginVertical: 8,
    paddingHorizontal: 8,
    borderLeftWidth: 1,
    borderLeftColor: "rgba(0, 0, 0, 0.2)",
  },
  location: {
    fontWeight: "600",
    fontSize: 20,
    // marginBottom: 2,
  },
  orgName: {},
  chevronContainer: {
    flexBasis: 50,
    backgroundColor: Colors.theme.gold,
    justifyContent: "center",
    alignItems: "center",
  },
});
