import * as React from "react";
import {
  FieldValues,
  FieldPath,
  useController,
  useFormContext,
} from "react-hook-form";

import GooglePlacesInput, {
  GooglePlacesInputProps,
} from "../GooglePlacesInput";
import { ControlledInputProps } from "../../types/ControlledInput";
import { required } from "../../util/formRules";

export default function CtlGooglePlacesInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  rules,
  ...rest
}: ControlledInputProps<TFieldValues, TName> & GooglePlacesInputProps) {
  const {
    field,
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    name,
    control,
    rules,
  });

  return (
    <GooglePlacesInput
      {...field}
      // value={field.value}
      // onChange={field.onChange}
      error={error}
      {...rest}
    />
  );
}
