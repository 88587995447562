import * as React from "react";
import { useQuery } from "react-query";

import { useAuth } from ".";
import { api } from "../api";
import { Member, User, UserType } from "../types/User";
import { authService } from "../util/authService";

export default function useUser() {
  const {
    data: user,
    isLoading,
    isError,
  } = useQuery<User>("whoami", () => api.whoami(), {
    retry: 1,
  });

  React.useEffect(() => {
    if (isError) {
      authService.clearCredentials();
    } else if (user) {
      authService.setUser(user);
    }
  }, [user, isError]);

  return { user, isLoading };
}

export function useMember() {
  const { user, isLoading } = useUser();

  React.useEffect(() => {
    if (user && user.type !== UserType.MEMBER) {
      authService.clearCredentials();
    }
  }, [user]);

  const u = user as Member | undefined;
  return { user: u, isLoading };
}
