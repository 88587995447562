export enum UserType {
  PILOT = "pilot",
  MEMBER = "member",
}

export enum MemberRole {
  SUPERADMIN = "superadmin",
  ADMIN = "admin",
  GENERAL = "general",
}

export type Permissions = Record<
  | "addMembers"
  | "accessStripe"
  | "addAircrafts"
  | "removeAircrafts"
  | "viewAllTrips"
  | "changeMemberRoles",
  boolean
>;

export enum PilotCertLevel {
  COMMERCIAL = "commercial",
  AIRLINE_TRANSPORTATION = "airline_transportation",
}

export type BaseUser = {
  id: string;
  type: UserType;
  firstName: string;
  lastName: string;
  photoUrl: string;
  phone: string;
  email: string;
};

export type Member = BaseUser & {
  type: UserType.MEMBER;
  role: MemberRole;
  permissions: Permissions;
  organization: string;
};

export type Pilot = BaseUser & {
  type: UserType.PILOT;
  certNo: string;
  resumeUrl: string;
  certImageUrl: string;
  certLevel: PilotCertLevel;
  firstLogin: boolean;
};

export type User = Member | Pilot;
