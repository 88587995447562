import * as React from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
import * as DocumentPicker from "expo-document-picker";
import { FieldError } from "react-hook-form";
import { Ionicons } from "@expo/vector-icons";

import Colors from "../constants/Colors";
import { Button } from "./Button";
import { Text, View } from "./Themed";
import { FileData } from "../types/FileData";

type FileInputProps = {
  label: string;
  value: FileData | null;
  onChange?: (v: DocumentPicker.DocumentResult) => void;
  mimeTypes?: string | string[];
  error?: FieldError | undefined;
};

export default function FileInput(props: FileInputProps) {
  const getFile = async () => {
    const doc = await DocumentPicker.getDocumentAsync({
      type: props.mimeTypes,
    });
    if (doc.type !== "success") {
      return;
    }
    const { type, mimeType, name, uri, size }: FileData = doc;
    props.onChange &&
      props.onChange({
        type,
        mimeType,
        name,
        uri,
        size,
      });
  };

  return (
    <View>
      <TouchableOpacity style={styles.btn} onPress={getFile}>
        {props.value ? (
          <Ionicons name="checkmark-outline" color="#fff" size={24} />
        ) : (
          <Ionicons name="add-outline" color="#fff" size={24} />
        )}
        <Text style={styles.btnText}>{props.label}</Text>
      </TouchableOpacity>
      <Text style={styles.fileName}>{props.value?.name}</Text>
      <Text style={styles.errorText}>{props.error && props.error.message}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  btn: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.theme.primary,
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 5,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
    marginLeft: 24,
    fontSize: 16,
  },
  fileName: {},
  errorText: {
    color: Colors.theme.danger,
  },
});
