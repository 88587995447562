import { Image, StyleSheet } from "react-native";
import { Controller, useForm, FieldError } from "react-hook-form";

import PhotoInput from "./PhotoInput";
import { FileData } from "../types/FileData";
import { View, Text } from "./Themed";
import Theme from "../constants/Theme";
import Colors from "../constants/Colors";
import { required } from "../util/formRules";

type BioHeadingProps = {
  url: string;
  title: string;
  subtitle?: string;
  editing?: boolean;
};

export default function BioHeading({ url, title, subtitle, editing = false }: BioHeadingProps) {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm( { defaultValues: { photo: {uri: url} as FileData } } )
  return (
    <View style={styles.headerContainer}>
      { !editing && (
        <>
          <Image style={styles.image} source={{ uri: url }} />
          <Text style={styles.title}>{title}</Text>
          {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
        </>
      )}
      { editing && (
        <>
          <Controller
            name="photo"
            control={control}
            rules={required}
            render={({ field }) => (
              <PhotoInput
                value={field.value}
                onChange={field.onChange}
                label="Add a photo"
                error={errors.photo as FieldError | undefined}
              />
            )}
          />
          <View style={styles.spacer} />
          {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: "center",
    marginBottom: 36,
  },
  image: {
    ...Theme.roundedImage,
    width: 128,
    height: 128,
    marginBottom: 16,
  },
  title: {
    ...Theme.h1,
  },
  subtitle: {
    color: Colors.theme.gold,
    fontWeight: "600",
    marginTop: 8,
  },
  spacer: {
    height: 20,
    backgroundColor: "none",
  },
});
