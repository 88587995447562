import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItem,
} from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import {
  StyleSheet,
  ImageBackground,
  useWindowDimensions,
  Pressable,
  Image,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { useSafeAreaInsets } from "react-native-safe-area-context";
// LinearGradient only works on iOS/Android, so the web version needs
// a different package. Both have the same interface though.
// import LinearGradient from "react-native-linear-gradient";
import LinearGradient from "react-native-web-linear-gradient";

import { ZephurVersion } from "./ZephurVersion";
import { View, Text } from "../components/Themed";
import { useAuth, useUser } from "../hooks";
import Colors from "../constants/Colors";
import { AuthDrawerNavigationProp } from "../navigation/types";
import { MemberRole, UserType } from "../types/User";

const image = { uri: require("../assets/images/background_large.png") };
const pilotsIcon = { uri: require("../assets/images/icon_pilots.png") };
const stripeIcon = { uri: require("../assets/images/icon_stripe.png") };

export function AuthDrawerContent({ ...props }: DrawerContentComponentProps) {
  const { user } = useUser();
  const { logout } = useAuth();
  const navigation = useNavigation<AuthDrawerNavigationProp>();
  const insets = useSafeAreaInsets();
  const window = useWindowDimensions();

  const isPilot = user?.type === UserType.PILOT;
  const headerColor = isPilot ? Colors.theme.gold : Colors.theme.primary;
  const borderColor = isPilot ? "rgb(255, 171, 74)" : "#fff";
  const gradientColor = isPilot
    ? ["#E68416", "rgba(230, 132, 22, 0.47)"]
    : ["#084996", "rgba(8, 73, 150, 0.47)"];

  let menuItems: Array<Record<string, any>> = [
    {
      icon: "home-outline",
      to: { screen: "HomeStack", nested: "Home" },
      label: "Dashboard",
    },
    {
      icon: "reload",
      to: { screen: "HomeStack", nested: "PastTrips" },
      label: "Past Trips",
    },
  ];

  const pilotItems = [
    {
      icon: "people-outline",
      to: { screen: "ContactsStack", nested: "Contacts" },
      label: "Contacts",
    },
    {
      icon: "mail-outline",
      to: { screen: "InviteStack", nested: "Invite" },
      label: "Invite",
    },
  ] as const;

  const orgItems = [
    {
      icon: "people-outline",
      to: { screen: "MembersStack", nested: "Members" },
      label: "Members",
    },
    {
      iconUri: pilotsIcon,
      to: { screen: "PilotsStack", nested: "Pilots" },
      label: "Pilots",
    },
    {
      icon: "airplane-outline",
      to: { screen: "AircraftStack", nested: "Aircraft" },
      label: "Aircraft",
      subLabel: "*Admin",
    },
  ] as const;

  const stripeItem = {
    iconUri: stripeIcon,
    to: { screen: "Stripe" },
    label: "Stripe",
    // Don't show the sub label for pilots.
    subLabel: user?.type === UserType.MEMBER ? "*Super Admin" : "",
  };

  if (user?.type === UserType.PILOT) {
    menuItems = menuItems.concat( [...pilotItems, stripeItem] );
  } else {
    menuItems = menuItems.concat(orgItems);
  }

  //! not needed because of the if statement above
  // if (user?.type === UserType.PILOT) { //! || user?.role === MemberRole.SUPERADMIN
  //   menuItems = menuItems.concat(stripeItem);
  // }

  const navigate = (to: any) => {
    navigation.navigate(to.screen, { screen: to.nested });
  };

  const onLogout = () => {
    logout();
  };

  const closeDrawer = () => {
    navigation.goBack();
  };

  return (
    <DrawerContentScrollView
      {...props}
      style={styles.container}
      contentContainerStyle={{
        paddingTop: insets.top, // Weird padding on top otherwise.
        paddingBottom: insets.bottom,
      }}
    >
      <ImageBackground
        source={image}
        style={StyleSheet.flatten([
          styles.image,
          { minHeight: window.height - insets.top },
        ])}
        resizeMode="cover"
      >
        <View
          style={StyleSheet.flatten([
            styles.headerContainer,
            { backgroundColor: headerColor },
          ])}
        >
          <Ionicons name="close" size={35} color="#fff" onPress={closeDrawer} />
          <View style={styles.photoAndName}>
            {/* <Pressable
              key={user?.id}
              onPress={() => navigate({ screen: "Profile" })}
            > */}
              <Image
                style={StyleSheet.flatten([styles.photo, { borderColor }])}
                source={{ uri: user?.photoUrl }}
              />
              <Text style={styles.headerText}>
                {`${user?.firstName} ${user?.lastName}`}
              </Text>
            {/* </Pressable> */}
          </View>
        </View>

        {/* TODO: Need to figure out what to do with typing for react-native-web-linear-gradient */}
        {/* @ts-ignore */}
        <LinearGradient colors={gradientColor} style={styles.content}>
          {menuItems.map(({ icon, iconUri, to, label, subLabel }) => (
            <Pressable
              key={label}
              style={styles.navItem}
              onPress={() => navigate(to)}
            >
              {icon && <Ionicons name={icon as any} size={30} color="#fff" />}
              {iconUri && (
                <Image
                  source={iconUri}
                  style={{ width: 30, height: "100%", maxHeight: 30 }}
                  resizeMode="contain"
                />
              )}
              <Text style={styles.navItemText}>
                {label}
                <Text style={styles.navItemTextLabel}>{subLabel}</Text>
              </Text>
            </Pressable>
          ))}
          <Pressable key={"logout"} style={styles.navItem} onPress={onLogout}>
            <Ionicons name="log-out-outline" size={30} color="#fff" />
            <Text style={styles.navItemText}>Sign Out</Text>
          </Pressable>
          <ZephurVersion textStyle={{ color: "#fff" }} />
        </LinearGradient>
      </ImageBackground>
    </DrawerContentScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  image: {
    // flex: 1,
  },
  headerContainer: {
    padding: 12,
    zIndex: 1,
    elevation: 2,
    shadowColor: "#000",
    shadowRadius: 12,
    shadowOpacity: 0.4,
  },
  photoAndName: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "none",
    marginTop: 12,
  },
  photo: {
    width: 60,
    height: 60,
    borderRadius: 999,
    // borderColor: "rgb(255, 171, 74)",
    borderWidth: 2,
  },
  headerText: {
    fontWeight: "600",
    color: "#fff",
    fontSize: 20,
    marginLeft: 8,
  },
  content: {
    flex: 1,
    padding: 20,
  },
  navItem: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
  },
  navItemText: {
    color: "#fff",
    marginLeft: 16,
    fontSize: 16,
    fontWeight: "600",
  },
  navItemTextLabel: {
    fontStyle: "italic",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: 12,
    fontWeight: "400",
    marginLeft: 8,
  },
});
