import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useNotifs } from "../hooks/notifications";
import React from "react";
import {
  ViewStyle,
  ImageBackground,
  StyleSheet,
  LayoutChangeEvent,
  Pressable,
} from "react-native";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useAuth } from "../hooks";
import { View, Text } from "./Themed";

// const image = { uri: require("../assets/images/background_blue_tree.png") };
const image = { uri: require("../assets/images/background_large.png") };

/**
 * NOTE: BaseHeader assumes that it exists within two drawer navigators, the child
 * being app navigation and the parent being notifications. This is only used
 * if no explicit callback to onMenu/onBack is passed.
 */
export function BaseHeader({
  color = "blue",
  ...props
}: {
  children?: React.ReactNode;
  title?: string;
  hideBack?: boolean;
  leftIcon?: "menu" | "back" | "none" | "logout" | "login";
  onMenu?: (...args: [any]) => any;
  onBack?: (...args: [any]) => any;
  rightIcon?: "notifications" | "edit" | "none";
  onEdit?: (...args: [any]) => any;
  style?: ViewStyle;
  color?: "blue" | "orange" | "black";
  renderBelow?: () => React.ReactNode;
}) {
  // TODO: How to solve navigation typing issue here. For now, it's
  // just a drawer.
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const { notifs: allNotifs } = useNotifs();
  const newNotifs = allNotifs?.filter((n) => !n.read);
  const [belowMargin, setBelowMargin] = React.useState(0);

  // Set default prop values.
  const leftIcon = props.leftIcon || "none";
  const rightIcon = props.rightIcon || "none";

  const onMenu =
    props.onMenu ||
    function () {
      navigation.toggleDrawer();
    };
  const onBack =
    props.onBack ||
    function () {
      if (navigation.canGoBack()) navigation.goBack();
      else navigation.navigate("Home");
    };
  const onEdit = props.onEdit || function () {};

  const openNotifications = () => {
    navigation
      .getParent<DrawerNavigationProp<any>>()
      .getParent<DrawerNavigationProp<any>>()
      .toggleDrawer();
  };

  const bgColor = {
    blue: "rgba(8, 73, 150, 0.8)",
    orange: "rgba(229, 132, 22, 0.4)",
    black: "rgba(43, 48, 52, 0.8)",
  }[color];

  const onBelowLayout = (ev: LayoutChangeEvent) => {
    const { height } = ev.nativeEvent.layout;
    setBelowMargin(-height / 2);
  };

  const { logout } = useAuth();

  const onLogout = () => {
    logout();
  };

  return (
    <View
      style={StyleSheet.flatten([
        styles.container,
        props.style,
        belowMargin ? { paddingBottom: -belowMargin } : null,
      ])}
    >
      <ImageBackground source={image} style={styles.image}>
        <View
          style={StyleSheet.flatten([
            styles.container,
            styles.content,
            { backgroundColor: bgColor },
          ])}
        >
          <View style={styles.iconsContainer}>
            {(leftIcon === "menu" && (
              <Ionicons name="menu" color="#fff" size={36} onPress={onMenu} />
            )) ||
              (leftIcon === "back" && (
                <Ionicons
                  name="arrow-back"
                  color="#fff"
                  size={36}
                  onPress={onBack}
                />
              )) ||
              (leftIcon === "logout" && (
                <Pressable
                  key={"logout"}
                  style={styles.navItem}
                  onPress={onLogout}
                >
                  <Ionicons name="log-out-outline" color="#fff" size={30} />
                  <Text style={styles.navItemText}>Logout</Text>
                </Pressable>
              )) ||
              (leftIcon === "login" && (
                <Pressable
                  key={"login"}
                  style={styles.navItem}
                  onPress={() => navigation.navigate("Login")}
                >
                  <Text style={styles.navItemTextBack}>Back To Login</Text>
                </Pressable>
              )) ||
              null}
            {(rightIcon === "notifications" && (
              <View style={styles.notifContainer}>
                <Ionicons
                  name="notifications"
                  color="#fff"
                  size={36}
                  style={styles.rightIcon}
                  onPress={openNotifications}
                />
                {newNotifs && newNotifs.length > 0 && (
                  <View style={styles.notifCount}>
                    <Text style={styles.notifCountText}>
                      {newNotifs.length}
                    </Text>
                  </View>
                )}
              </View>
            )) ||
              (rightIcon === "edit" && (
                <Ionicons
                  name="pencil"
                  color="#fff"
                  size={38}
                  onPress={onEdit}
                />
              )) ||
              null}
          </View>
          {props.title && <Text style={styles.title}>{props.title}</Text>}
          {props.children ? props.children : null}
          {props.renderBelow && props.renderBelow() && (
            <View
              style={{
                backgroundColor: "none",
                marginTop: 16,
                marginBottom: belowMargin,
              }}
              onLayout={onBelowLayout}
            >
              {props.renderBelow()}
            </View>
          )}
        </View>
      </ImageBackground>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
  },
  image: {
    flex: 1,
    minHeight: 80,
  },
  content: {
    padding: 14,
    paddingBottom: 4,
    flex: 1,
  },
  iconsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: 36,
  },
  rightIcon: {
    marginLeft: "auto",
  },
  backArrow: {},
  title: {
    color: "#fff",
    textAlign: "center",
    fontWeight: "600",
    fontSize: 20,
  },
  navItem: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
  },
  navItemText: {
    color: "#fff",
    marginLeft: 16,
    fontSize: 16,
    fontWeight: "600",
  },
  navItemTextBack: {
    color: "red",
    fontWeight: "700",
    fontSize: 18,
  },
  belowContainer: {
    marginBottom: -20,
  },
  notifContainer: {
    position: "relative",
  },
  notifCount: {
    position: "absolute",
    display: "flex",
    top: -4,
    right: -4,
    backgroundColor: "#e68416",
    borderRadius: 10,
    width: 20,
    height: 20,
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 3px 13px 2px #000",
  },
  notifCountText: {
    color: "#fff",
    fontSize: 12,
    fontWeight: "600",
  },
});
