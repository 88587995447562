import { StyleSheet, TouchableOpacity } from "react-native";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { useQuery } from "react-query";

import { BaseHeader } from "../../components/BaseHeader";
import { Text, View } from "../../components/Themed";
import { Button } from "../../components/Button";
import { useUser } from "../../hooks";
import { api } from "../../api";
import PreviewPicture from "../../components/PreviewPicture";
import LoadingIndicator from "../../components/LoadingIndicator";
import { SubcontractScreenProps } from "../../navigation/types";
import { Pilot } from "../../types/User";
import ScreenContainer from "../../components/ScreenContainer";

export default function SubcontractTripScreen({
  route,
  navigation,
}: SubcontractScreenProps) {
  const { tripId } = route.params;
  const { user } = useUser();
  const { data: pilots, isLoading } = useQuery(
    ["pilots", user?.id, "contractors"],
    () => api.getPilotContractors(user?.id as string),
    { enabled: !!user }
  );

  const markUnavailable = () => {
    navigation.push("SubcontractConfirm", {
      tripId,
      type: "unavailable",
    });
  };

  const sendToPilot = (p: Pilot) => {
    navigation.push("SubcontractConfirm", {
      tripId,
      type: "recipient",
      recipientId: p.id,
    });
  };

  return (
    <ScreenContainer>
      <View>
        <Text style={styles.label}>No one available to fly this trip?</Text>
        <Button
          title={`Mark this trip as "Unavailable"`}
          style={styles.unavailableBtn}
          color="blueishBlack"
          onPress={markUnavailable}
        />
        <View style={styles.divider} />
        <Text style={styles.label}>
          Or select a pilot from your Contact List to send the request to:
        </Text>
        {isLoading && <LoadingIndicator />}
        {pilots &&
          pilots.map((p) => (
            <TouchableOpacity key={p.id} onPress={() => sendToPilot(p)}>
              <PreviewPicture
                url={p.photoUrl}
                title={`${p.firstName} ${p.lastName}`}
                style={styles.previewPic}
              />
            </TouchableOpacity>
          ))}
      </View>
    </ScreenContainer>
  );
}

export function SubcontractTripScreenHeader({
  navigation,
}: NativeStackHeaderProps) {
  const onBack = () => {
    if (navigation.canGoBack()) navigation.goBack();
    else navigation.replace("Home");
  };

  return (
    <BaseHeader
      title="Subcontract this Trip"
      leftIcon="back"
      color="black"
      onBack={onBack}
    />
  );
}

const styles = StyleSheet.create({
  label: {
    textAlign: "center",
    marginBottom: 20,
  },
  unavailableBtn: {
    marginHorizontal: 20,
  },
  divider: {
    marginHorizontal: -20,
    marginVertical: 36,
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.2)",
  },
  previewPic: {
    marginBottom: 12,
  },
});
