import { useQuery } from "react-query";
import { api } from "../api";
import { UserType } from "../types/User";
import useUser from "./useUser";
import { TripNotification } from "../types/Notification";

export function useNotifs() {
    const { user } = useUser();

    const { data: notifs } = useQuery(
        [`notifs`, user?.id],
        () => api.getAppNotifications(user?.id as string),
        { enabled: !!user?.id, refetchInterval: 30000, refetchIntervalInBackground: true }
    );

    return {
        notifs,
    };
}
