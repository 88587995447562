import { StyleSheet } from "react-native";

import { Text, View } from "./Themed";
import { TripStatus } from "../types/Trip";
import { Button } from "./Button";
import { mapTripStatusToColor } from "../util/trips";

export type TripStatusCardProps =
  | {
      status: TripStatus.PENDING;
      name: string;
    }
  | {
      status: TripStatus.CANCELLED;
    }
  | {
      status: TripStatus.DECLINED;
      onSendToNew?: () => void;
      subtext?: string;
    }
  | { status: TripStatus.ACCEPTED; name: string }
  | { status: TripStatus.IN_PROGRESS }
  | { status: TripStatus.COMPLETED }
  | { status: TripStatus.BOOKED }
  | { status: TripStatus.PENDING_PAYMENT };

export default function TripStatusCard(props: TripStatusCardProps) {
  const m = mapTripStatusToColor;
  const statusColorMap = {
    pending: {
      borderColor: "rgba(0, 0, 0, 0.32)",
      backgroundColor: m(TripStatus.PENDING),
    },
    declined: {
      borderColor: "rgba(0, 0, 0, 0.32)",
      backgroundColor: m(TripStatus.DECLINED),
    },
    cancelled: {
      borderColor: "rgba(0, 0, 0, 0.32",
      backgroundColor: m(TripStatus.CANCELLED)
    },
    accepted: {
      borderColor: "rgba(0, 0, 0, 0.32)",
      backgroundColor: m(TripStatus.ACCEPTED),
    },
    in_progress: {
      borderColor: "rgba(0, 0, 0, 0.32)",
      backgroundColor: m(TripStatus.IN_PROGRESS),
    },
    completed: {
      borderColor: "rgba(0, 0, 0, 0.32)",
      backgroundColor: m(TripStatus.COMPLETED),
    },
    booked: {
      borderColor: "rgba(0, 0, 0, 0.32",
      backgroundColor: m(TripStatus.BOOKED),
    },
    pending_payment: {
      borderColor: "rgba(0, 0, 0, 0.32",
      backgroundColor: m(TripStatus.PENDING_PAYMENT),
    }
  };

  const colors = statusColorMap[props.status];
  const containerStyle = StyleSheet.flatten([styles.container, colors]);

  switch (props.status) {
    case TripStatus.PENDING:
      return (
        <View style={containerStyle}>
          <Text style={styles.header}>Status: Pending</Text>
          <Text style={styles.subheader}>
            Waiting for a response from{" "}
            <Text style={styles.underline}>{props.name}</Text>
          </Text>
        </View>
      );
    case TripStatus.CANCELLED:
      return (
        <View style={containerStyle}>
          <Text style={styles.header}>Status: Cancelled</Text>
          <Text style={styles.subheader}>This Trip was cancelled</Text>
        </View>
      )
    case TripStatus.DECLINED:
      if (props.onSendToNew) {
      }
      return (
        <View style={containerStyle}>
          <Text style={styles.header}>Status: Declined</Text>
          {props.onSendToNew ? (
            <Button title="Send to New Pilot" onPress={props.onSendToNew} />
          ) : (
            <Text style={styles.subheader}>{props.subtext}</Text>
          )}
        </View>
      );
    case TripStatus.ACCEPTED:
      return (
        <View style={containerStyle}>
          <Text style={styles.header}>Status: Accepted</Text>
          <Text style={styles.subheader}>
            Accepted by <Text style={styles.underline}>{props.name}</Text>
          </Text>
        </View>
      );
    case TripStatus.IN_PROGRESS:
      return (
        <View style={containerStyle}>
          <Text style={styles.header}>Status: In Progress</Text>
        </View>
      );
    case TripStatus.COMPLETED:
      return (
        <View style={containerStyle}>
          <Text style={styles.header}>Trip Completed</Text>
        </View>
      );
    case TripStatus.BOOKED:
      return (
        <View style={containerStyle}>
          <Text style={styles.header}>Trip has been Booked!</Text>
        </View>
      );
    case TripStatus.PENDING_PAYMENT:
      return (
        <View style={containerStyle}>
          <Text style={styles.header}>Trip is Pending Payment</Text>
        </View>
      ) 
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 12,
    borderRadius: 5,
    borderWidth: 1,
  },
  header: {
    color: "#fff",
    fontSize: 28,
    fontWeight: "600",
    textAlign: "center",
  },
  subheader: {
    color: "#fff",
    fontWeight: "600",
    textAlign: "center",
  },
  underline: {
    textDecorationLine: "underline",
    color: "#fff",
  },
});
