const tintColorLight = "#2f95dc";
const tintColorDark = "#fff";

export default {
  theme: {
    primary: "#0062BA", // blue
    gold: "#E68416", // gold
    lightGray: "#BCC8D3",
    lightBlue: "#6BAFFF",
    lightestBlue: "#E4EEF5",
    success: "#24C78C",
    danger: "#CC3D00",
    dangerLight: "rgba(255, 255, 255, 0.8)",
    subtle: "#8196AA",
    blueishBlack: "#2B3034",
  },
  light: {
    text: "#000",
    backgroundColor: "#fff",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: "#fff",
    backgroundColor: "#000",
    tint: tintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
  },
} as const;
