import { Image, ScrollView, StyleSheet, TouchableOpacity } from "react-native";
import { useQuery } from "react-query";
import { useNavigation } from "@react-navigation/native";

import { BaseHeader } from "../components/BaseHeader";
import { View, Text } from "../components/Themed";
import { useUser } from "../hooks";
import { ViewOrganizationScreenProps } from "../navigation/types";
import { User } from "../types/User";
import { api } from "../api";
import LoadingIndicator from "../components/LoadingIndicator";
import { styles } from "./ViewUserScreen";
import Theme from "../constants/Theme";
import PreviewPicture from "../components/PreviewPicture";

export default function ViewOrganizationScreen({
  route,
  navigation,
}: ViewOrganizationScreenProps) {
  const { organizationId } = route.params;
  const { data: org, isLoading } = useQuery(
    `/organizations/${organizationId}`,
    () => api.getOrganization(organizationId)
  );
  const { data: members } = useQuery(
    `/organizations/${organizationId}/members`,
    () => api.getOrgUsers(organizationId)
  );

  const viewUser = (u: User) => {
    navigation.push("ViewUser", { userId: u.id });
  };

  return (
    <ScrollView style={Theme.page} contentContainerStyle={Theme.pageContent}>
      <View>
        {isLoading && <LoadingIndicator />}
        <View style={styles.headerContainer}>
          <Image style={styles.image} source={{ uri: org?.photoUrl }} />
          <Text style={styles.title}>{org && org.name}</Text>
          <Text
            style={StyleSheet.flatten([styles.subtitle, { color: "#000 " }])}
          >
            {org && org.address1}
          </Text>
        </View>
        <Text style={pageStyles.orgMembers}>Organization Members</Text>
        {members &&
          members.map((user) => (
            <TouchableOpacity key={user.id} onPress={() => viewUser(user)}>
              <PreviewPicture
                url={user.photoUrl}
                title={`${user.firstName} ${user.lastName}`}
                style={{ marginTop: 16 }}
              />
            </TouchableOpacity>
          ))}
      </View>
    </ScrollView>
  );
}

export function ViewOrganizationScreenHeader() {
  return (
    <BaseHeader
      title="Contacts"
      leftIcon="back"
      rightIcon="notifications"
      color="blue"
    />
  );
}

const pageStyles = StyleSheet.create({
  orgMembers: {
    ...Theme.h2,
    textAlign: "center",
  },
});
