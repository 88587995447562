import * as React from "react";
import { Picker } from "@react-native-picker/picker";
import { FieldError } from "react-hook-form";

import Colors from "../constants/Colors";
import { View, Text } from "./Themed";
import { StyleSheet, ViewStyle } from "react-native";

export type SelectInputProps = {
  value?: string;
  onChange?: (val: string) => void;
  label?: string;
  error?: FieldError | undefined;
  containerStyle?: ViewStyle;
  items: { label: string; value: string }[];
};

export default React.forwardRef<any, SelectInputProps>(
  (props, ref): React.ReactElement => {
    const { value, onChange, label, items, containerStyle, error } = props;

    React.useEffect(() => {
      // If current value doesn't exist in our items, then find the first
      // available item's value and set it with onChange.
      if (
        onChange &&
        items.length > 0 &&
        !items.find(({ value: v }) => v === value)
      ) {
        onChange(items[0].value);
      }
    }, [value, items]);

    return (
      <View style={containerStyle}>
        {label && (
          <View style={styles.labelContainer}>
            <Text style={styles.label}>{label}</Text>
          </View>
        )}
        <Picker
          ref={ref}
          style={styles.picker}
          selectedValue={value}
          onValueChange={onChange}
        >
          {items.map((item) => (
            <Picker.Item
              key={item.value}
              label={item.label}
              value={item.value}
            />
          ))}
        </Picker>
        <Text style={styles.errMsg}>{error && error.message}</Text>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  labelContainer: {
    flexDirection: "row",
    paddingLeft: 24,
  },
  label: {
    fontWeight: "bold",
    marginBottom: 4,
  },
  picker: {
    backgroundColor: Colors.theme.lightestBlue,
    padding: 16,
    borderRadius: 5,
    borderColor: "#fff",
  },
  errMsg: {
    color: Colors.theme.danger,
  },
});
