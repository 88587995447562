import * as React from "react";
import { useController, FieldValues, FieldPath } from "react-hook-form";

import { TextInputProps } from "../TextInput";
import { ControlledInputProps } from "../../types/ControlledInput";
import TimeInput from "../TimeInput/TimeInput";

export default function CtlTimeInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  rules,
  ...rest
}: ControlledInputProps<TFieldValues, TName> & TextInputProps) {
  const {
    field,
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    name,
    control,
    rules,
  });

  return <TimeInput {...field} error={error} {...rest} />;
}
