import { User } from "./User";
import { Aircraft } from "./Aircraft";

export enum InvitationType {
  SUBCONTRACTOR = "subcontractor",
  PILOT_TAIL_NUMBER = "pilot_tail_number",
  ORGANIZATION = "organization",
  GENERAL_USER = "general_user",
  ADMIN_USER = "admin_user",
}

export type Invitation = {
  id: string;
  inviteeEmail?: string;
  invitationType: InvitationType;
  url: string;
  referrer: User;
  organization?: { id: string };
  aircraft?: Aircraft;
  temporary: boolean;
};
