import { DeviceEventEmitter } from 'react-native';
import AsyncStorage from "@react-native-async-storage/async-storage";
// import EventEmitter from "EventEmitter";
import { User } from "../types/User";

const USER_KEY = "user";
const ACCESS_TOKEN_KEY = "accessToken";
const REFRESH_TOKEN_KEY = "refreshToken";
const AUTH_UPDATE_EVENT = "accessToken";

class AuthService {
  // private authEmitter = new EventEmitter();

  constructor() {}
  
  /**
   * Adds a handler to be called whenever User is set.
   */
  addEventListener(handler: (user: User | null) => any) {
    return DeviceEventEmitter.addListener(AUTH_UPDATE_EVENT, handler);
  }

  clearListeners() {
    DeviceEventEmitter.removeAllListeners();
  }

  async storeCredentials({
    user,
    accessToken,
    refreshToken,
  }: {
    user: User;
    accessToken: string;
    refreshToken?: string;
  }) {
    await this.clearCredentials();
    await AsyncStorage.multiSet([
      [USER_KEY, JSON.stringify(user)],
      [ACCESS_TOKEN_KEY, accessToken],
    ]);
    if (refreshToken) {
      await AsyncStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    }
    DeviceEventEmitter.emit(AUTH_UPDATE_EVENT, user);
  }

  async clearCredentials() {
    await AsyncStorage.clear();
    DeviceEventEmitter.emit(AUTH_UPDATE_EVENT, null);
  }

  async getUser(): Promise<User | null> {
    const usr = await AsyncStorage.getItem(USER_KEY);
    if (!usr) return null;
    return JSON.parse(usr) as User;
  }

  async setUser(user: User) {
    await AsyncStorage.setItem(USER_KEY, JSON.stringify(user));
    DeviceEventEmitter.emit(AUTH_UPDATE_EVENT, user);
  }

  async getAccessToken() {
    try {
      return await AsyncStorage.getItem(ACCESS_TOKEN_KEY);
    } catch (err) {
      console.error("Error fetching access token:", err);
      return null;
    }
  }

  async setAccessToken(tok: string) {
    return AsyncStorage.setItem(ACCESS_TOKEN_KEY, tok);
  }

  async getRefreshToken() {
    try {
      return await AsyncStorage.getItem(REFRESH_TOKEN_KEY);
    } catch (err) {
      console.error("Error fetching refresh token:", err);
      return null;
    }
  }
}

export const authService = new AuthService();
