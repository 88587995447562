import {
  DetailType,
  PilotStatus,
  Trip,
  TripPilot,
  TripStatus,
  TripType,
} from "../types/Trip";

export const getDetail = (trip: Trip, t: DetailType) =>
  trip.tripDetails.find((d) => d.detailType === t);

export function getDestination(trip: Trip) {
  return trip.tripDetails.find((td) => td.detailType === DetailType.ARRIVAL);
}

export function getDeparture(trip: Trip) {
  return trip.tripDetails.find( ( td ) => td.detailType === DetailType.DEPARTURE );
}

export function getReturn(trip: Trip) {
  return trip.tripDetails.find( ( td ) => td.detailType === DetailType.RETURN );
}

/**
 * Determines the primary pilot for a trip.
 */
export function getPilotForTrip(
  tripPilots: TripPilot[]
): TripPilot | undefined {
  for (const tp of tripPilots) {
    if ([PilotStatus.PENDING, PilotStatus.ACCEPTED].includes(tp.pilotStatus)) {
      return tp;
    }
  }

  // Otherwise, just return the first pilot that was selected for this trip.
  return tripPilots.find((tp) => !tp.subcontractedFrom);
}

export const airportCodeRegex = /([a-zA-Z0-9]{3})/i;

/**
 * extractAirportCodeFromName takes a user-defined airport name and
 * returns the airport code if it exists. Searches for the code inside
 * parentheses. Note that the code MUST be three characters.
 *
 * @example
 * // returns "FAT"
 * extractAirportCodeFromName("(FAT) Fresno Yosemite");
 *
 * // returns "". Code isn't three characters.
 * extractAirportCodeFromName("(FT) Fresno Yosemite");
 *
 * // returns "", code not in parentheses.
 * extractAirportCodeFromName("FAT - Fresno Yosemite");
 */
export function extractAirportCodeFromName(name: string) {
  const result = name.match(airportCodeRegex);
  if (!result || typeof result.index !== "number") return "";
  return name.toUpperCase();
}

export const formatTripType = (t: TripType): string => {
  switch (t) {
    case TripType.DROP_OFF:
      return "Drop-off";
    case TripType.STANDBY:
      return "Standby";
  }
};

export const formatTripStatus = (t: TripStatus): string => {
  switch (t) {
    case TripStatus.PENDING:
      return "Pending";
    case TripStatus.DECLINED:
      return "Declined";
    case TripStatus.ACCEPTED:
      return "Accepted";
    case TripStatus.IN_PROGRESS:
      return "In Progress";
    case TripStatus.COMPLETED:
      return "Completed";
    case TripStatus.CANCELLED:
      return "Cancelled"
    case TripStatus.BOOKED:
      return "Booked"
    case TripStatus.PENDING_PAYMENT:
      return "Pending Payment"
  }
};

export const mapTripStatusToColor = (t: TripStatus): string => {
  switch (t) {
    case TripStatus.PENDING:
    case TripStatus.PENDING_PAYMENT:
      return "#8196AA";
    case TripStatus.CANCELLED:
    case TripStatus.DECLINED:
      return "#CC3D00";
    case TripStatus.ACCEPTED:
    case TripStatus.BOOKED:
      return "#24C78C";
    case TripStatus.IN_PROGRESS:
      return "#6BAFFF";
    case TripStatus.COMPLETED:
      return "#24C78C";

  }
};
