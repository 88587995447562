import * as React from "react";
import { StyleSheet } from "react-native";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { useQuery, useMutation, useQueryClient } from "react-query";

import Theme from "../../constants/Theme";
import Colors from "../../constants/Colors";
import { useUser } from "../../hooks";
import { BaseHeader } from "../../components/BaseHeader";
import { Text, View } from "../../components/Themed";
import { Button } from "../../components/Button";
import { api } from "../../api";
import LoadingIndicator from "../../components/LoadingIndicator";
import { SubcontractConfirmScreenProps } from "../../navigation/types";
import BioHeading from "../../components/BioHeading";
import SubtleButton from "../../components/SubtleButton";
import ScreenContainer from "../../components/ScreenContainer";

type SuccessContentProps = {
  message: string;
  btnText: string;
  btnOnPress: () => void;
};

function SuccessContent({ message, btnText, btnOnPress }: SuccessContentProps) {
  return (
    <View>
      <Text style={sucStyles.header}>Success</Text>
      <Text style={sucStyles.subheader}>{message}</Text>
      <Button title={btnText} onPress={btnOnPress} style={sucStyles.btn} />
    </View>
  );
}

const sucStyles = StyleSheet.create({
  header: {
    ...Theme.h1,
    textAlign: "center",
  },
  subheader: {
    marginVertical: 20,
    textAlign: "center",
  },
  btn: {
    alignSelf: "center",
  },
});

export default function SubcontractConfirmTripScreen({
  route,
  navigation,
}: SubcontractConfirmScreenProps) {
  const { user } = useUser();
  const isRecipient = route.params.type === "recipient";
  const { tripId } = route.params as any;
  const pilotId =
    route.params.type === "recipient" ? route.params.recipientId : "";
  const title = isRecipient ? "Confirm Recipient" : "Confirm Unavailability";
  const subtitle = isRecipient
    ? "Are you sure you would like to send this trip to:"
    : 'Are you sure you would like to mark this trip as "Unavailable"?';
  const succMsg = isRecipient
    ? "You have succesfully subcontracted this trip."
    : 'You have marked this trip as "unavailable."';

  const [showSuccess, setShowSuccess] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");

  const { data: pilot, isLoading } = useQuery(
    ["pilots", pilotId],
    () => api.getPilot(pilotId),
    { enabled: !!pilotId }
  );

  const goBack = () => {
    // Navigation state gets weird when back button is pressed, so for simplification,
    // just go back to Home.
    navigation.popToTop();
  };

  const queryClient = useQueryClient();

  const sendTripMutation = useMutation(
    (r: string) => api.subcontractTrip( tripId, r ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries( ["pilots", user?.id, "subcontractor-trips"] );
        queryClient.invalidateQueries( ["pilots", user?.id, "trips"] );
        queryClient.invalidateQueries( ["users", user?.id, "trips"] );
        queryClient.invalidateQueries( ['notifs', user?.id] );
      },
    }
  );

  const unavailableMutation = useMutation(
    () => api.declineTrip(tripId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries( ["pilots", user?.id, "subcontractor-trips"] );
        queryClient.invalidateQueries( ["pilots", user?.id, "trips"] );
        queryClient.invalidateQueries( ['users', user?.id, 'trips'] );
        queryClient.invalidateQueries( ['notifs', user?.id] );
      }
    }
  );

  const sendTrip = async () => {
    if ( !isRecipient || isFetching ) return;
    try {
      setIsFetching( true );
      // recipientId must exist since we know that isRecipient is true!
      const { recipientId } = route.params as any;
      await sendTripMutation.mutateAsync( recipientId );
      setShowSuccess( true );
    } catch ( err ) {
      setErrorMsg("An error occurred processing this request.");
    } finally {
      setIsFetching( false );
    }
  };

  const markUnavailable = async () => {
    if ( isFetching ) return;
    try {
      setIsFetching( true );
      await unavailableMutation.mutateAsync(tripId);
      setShowSuccess( true );
    } catch ( err ) {
      setErrorMsg("An error occurred processing this request.");
    } finally {
      setIsFetching( false );
    }
  };

  const RecipientContent = () => (
    <>
      {isLoading && <LoadingIndicator />}
      {pilot && (
        <>
          <BioHeading
            url={pilot.photoUrl}
            title={`${pilot.firstName} ${pilot.lastName}`}
          />
          <Button
            title="Send Trip"
            color="success"
            style={{ marginHorizontal: "auto" }}
            onPress={sendTrip}
          />
        </>
      )}
    </>
  );

  const UnavailableContent = () => (
    <>
      <Button
        title={`Unavailable for Trip`}
        style={styles.unavailableBtn}
        color="danger"
        variant="large"
        onPress={markUnavailable}
      />
      <SubtleButton
        title={`Oops! I don't want to mark this as "Unavailable"`}
        onPress={() => navigation.goBack()}
      />
    </>
  );

  return (
    <ScreenContainer>
      <View>
        {showSuccess ? (
          <SuccessContent
            message={succMsg}
            btnText={"Go Back"}
            btnOnPress={goBack}
          />
        ) : (
          <>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.subtitle}>{subtitle}</Text>
            {isRecipient ? <RecipientContent /> : <UnavailableContent />}
            <Text style={styles.errorMsg}>{errorMsg}</Text>
          </>
        )}
      </View>
    </ScreenContainer>
  );
}

export function SubcontractConfirmTripScreenHeader({
  navigation,
}: NativeStackHeaderProps) {
  const onBack = () => {
    if (navigation.canGoBack()) navigation.goBack();
    else navigation.replace("Home");
  };

  return (
    <BaseHeader
      title="Subcontract this Trip"
      leftIcon="back"
      color="black"
      onBack={onBack}
    />
  );
}

const styles = StyleSheet.create({
  title: {
    ...Theme.h1,
    textAlign: "center",
    marginBottom: 8,
  },
  subtitle: {
    textAlign: "center",
    marginBottom: 40,
  },
  unavailableBtn: {
    marginHorizontal: "auto",
    marginBottom: 40,
  },
  errorMsg: {
    color: Colors.theme.danger,
    textAlign: "center",
    marginTop: 40,
  },
});
