import Colors from "./Colors";
import Theme from "./Theme";

const Style = {
  page: {
    ...Theme.page,
  },
  pageContent: {
    ...Theme.pageContent,
  },
  headerContainer: {
    alignItems: "center",
  },
  header: {
    ...Theme.h1,
    textAlign: "center",
    marginBottom: 8,
  },
  subheading: {
    textAlign: "center",
    marginHorizontal: 8,
  },
  smallHeader: {
    fontWeight: "600",
    fontSize: 16,
  },
  spacer: {
    height: 20,
    backgroundColor: "none",
  },
  spacerSmall: {
    height: 10,
    backgroundColor: "none",
  },
  spacerTiny: {
    height: 0,
    backgroundColor: "none",
  },
  disclaimerText: {
    color: Colors.theme.subtle,
    textAlign: "center",
    marginTop: 16,
    marginBottom: 8,
  },
  nextBtn: {
    marginLeft: "auto",
    marginTop: 24,
  },
  liStyle: {
    ...Theme.h2
  },
  ulStyleNone: {
    listStyleType: "none",
  },
  ulStyleSquare: {
    listStyleType: "square",
  },
  addressBold: {
    fontWeight: "600"
  },
  errMsg: {
    ...Theme.errorMessage,
  },
} as const;

export default Style;
