import React from "react";
import { AuthProvider } from "./auth";
import { QueryProvider } from "./query";
import { StoreProvider } from "./store";

export function AppProviders(props: { children?: React.ReactNode }) {
  return (
    <QueryProvider>
      <StoreProvider>
        <AuthProvider>
          {props.children}
        </AuthProvider>
      </StoreProvider>
    </QueryProvider>
  );
}

export { AuthProvider, QueryProvider };
