import * as React from "react";
import { StyleSheet } from "react-native";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";

import Theme from "../../constants/Theme";
import { Text, View } from "../../components/Themed";
import CtlTextInput from "../../components/ControlledInputs/CtlTextInput";
import { required } from "../../util/formRules";
import { Button } from "../../components/Button";
import { BaseHeader } from "../../components/BaseHeader";
import { EnterHobbsScreenProps } from "../../navigation/types";
import { api } from "../../api";
import { useUser } from "../../hooks";
import SubtleButton from "../../components/SubtleButton";

type EnterHobbsForm = {
  hobbs: string;
};

type ConfirmationProps = {
  onConfirm: () => void;
  onCancel: () => void;
  errMsg?: string;
};

function Confirmation({ onConfirm, onCancel, errMsg }: ConfirmationProps) {
  return (
    <View>
      <Text style={styles.header}>Confirmation</Text>
      <Text style={styles.text}>
        Ending this trip will begin the payment{"\n"}transfer process.
      </Text>
      <Text style={styles.text}>
        Payments can be viewed and tracked in{"\n"}your Stripe account.
      </Text>
      <Text style={styles.errMsg}>{errMsg}</Text>
      <Button
        title="End Trip"
        onPress={onConfirm}
        color="success"
        style={styles.endTripBtn}
      />
      <SubtleButton title="Oops! Don't end this trip yet." onPress={onCancel} />
    </View>
  );
}

export default function EnterHobbsScreen({
  route,
  navigation,
}: EnterHobbsScreenProps) {
  const { user } = useUser();
  const { tripId, isStart } = route.params;
  const [showConfirm, setShowConfirm] = React.useState(false);
  const queryClient = useQueryClient();
  const updateHobbsMutation = useMutation(
    (hobbs: string) =>
      isStart
        ? api.startTrip(tripId, { hobbs })
        : api.endTrip(tripId, { hobbs }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["trips", tripId]);
        queryClient.invalidateQueries(["pilots", user?.id, "trips"]);
        queryClient.invalidateQueries( ['notifs', user?.id] );
      },
    }
  );
  const { getValues, control, handleSubmit } = useForm({
    defaultValues: { hobbs: "" },
  });
  const [errMsg, setErrMsg] = React.useState("");

  const confirmHobbs = ({ hobbs }: EnterHobbsForm) => {
    if (!isStart) {
      setShowConfirm(true);
      return;
    }
    doMutation();
  };

  const cancelConfirm = () => {
    setShowConfirm(false);
  };

  const doMutation = async () => {
    const hobbs = getValues("hobbs");
    try {
      setErrMsg("");
      await updateHobbsMutation.mutateAsync(hobbs);
      navigation.navigate("Trip", { tripId });
    } catch (err) {
      setErrMsg("A problem occurred updating this hobbs time.");
    }
  };

  return (
    <View style={Theme.page}>
      {showConfirm ? (
        <Confirmation
          onConfirm={doMutation}
          onCancel={cancelConfirm}
          errMsg={errMsg}
        />
      ) : (
        <>
          <Text style={styles.header}>Hobbs Time</Text>
          <Text style={styles.subheader}>
            Enter in the time indicated on your Hobbs Meter.
          </Text>
          <CtlTextInput
            name="hobbs"
            control={control}
            rules={required}
            containerStyle={styles.hobbsInput}
            placeholder="08552"
            keyboardType="decimal-pad"
            centerText
          />
          <Text style={styles.errMsg}>{errMsg}</Text>
          <Button
            title="Confirm Time"
            onPress={handleSubmit(confirmHobbs)}
            color="success"
            style={styles.confirmBtn}
          />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    ...Theme.h1,
    textAlign: "center",
    marginBottom: 8,
  },
  subheader: {
    textAlign: "center",
  },
  hobbsInput: {
    marginVertical: 40,
  },
  text: {
    textAlign: "center",
    marginBottom: 12,
  },
  errMsg: {
    ...Theme.errorMessage,
  },
  confirmBtn: {
    alignSelf: "center",
  },
  endTripBtn: {
    alignSelf: "center",
    marginBottom: 20,
  },
});

export function EnterHobbsScreenHeader({
  route,
  navigation,
}: NativeStackHeaderProps) {
  // No way to type-infer header props.
  const { tripId } = route.params as any;

  const onBack = () => {
    if (navigation.canGoBack()) navigation.goBack();
    else navigation.navigate("Trip", { tripId });
  };

  return <BaseHeader leftIcon="back" onBack={onBack} />;
}
