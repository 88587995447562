import * as React from "react";
import { useNavigation } from "@react-navigation/native";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";

import { BaseHeader } from "../components/BaseHeader";
import { View, Text } from "../components/Themed";
import {
  CreateAccountNavigationProp,
  CreateAccountScreenProps,
} from "../navigation/types";
import { initialize, reset } from "../store/createAccount";
import { api } from "../api";
import { Invitation, InvitationType } from "../types/Invitation";
import Style from "../constants/CreateAccountStyles";
import LoadingIndicator from "../components/LoadingIndicator";
import { useUser } from "../hooks";
import { makeCancelable, makeCancelableError } from "../util/utils";

// TODO: Rename to "HandleInviteScreen"
export default function CreateAccountScreen({
  route,
  navigation,
}: CreateAccountScreenProps) {
  const { user, isLoading: userLoading } = useUser();
  const dispatch = useDispatch();
  const [inv, setInv] = React.useState<Invitation | null>(null);
  const [invLoading, setInvLoading] = React.useState(true);
  const [invErr, setInvErr] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");
  const code = route.params?.code;

  const isLoading = userLoading || invLoading;

  React.useEffect(() => {
    // If for whatever reason the params weren't passed, we should just navigate
    // to login.
    if (!code) {
      navigation.getParent()?.navigate("Unauth");
      return;
    }
  }, []);

  React.useEffect(() => {
    const { promise: response, cancel } = makeCancelable(
      api.exchangeCreateCode(code)
    );
    async function fetchInv() {
      try {
        const res = await response;
        // TODO: This makeCancelable thing is still throwing a warning
        // about updating an unmounted component. What should be done?
        setInv(res);
        setInvLoading(false);
      } catch (err: makeCancelableError | any) {
        console.error("Error fetching code:", err);
        if (err.isCanceled) {
          return;
        }
        setInvErr(true);
        setErrMsg("There was an error loading this invitation or it may no longer be valid.");
        setInvLoading(false);
      }
    }
    fetchInv();
    return () => cancel();
  }, [code, setInv, setErrMsg, setInvLoading]);

  React.useEffect(() => {
    if (!inv || isLoading) {
      return;
    }

    if (user && inv && user.email === inv.referrer.email) {
      navigation.getParent()?.navigate("Unauth");
      return;
    }

    dispatch(initialize(inv));

    if (user) {
      // For when an invitation is directed at a user already on the app.
      // Don't go through the whole "create a new account" flow, just need to show
      // a specific question screen.
      navigation.replace("ConfirmInv");
    } else {
      // Need to create a new account.
      if (inv.invitationType === InvitationType.ORGANIZATION) {
        navigation.replace("OrganizationInfo");
      } else {
        navigation.replace("BasicInfo");
      }
    }
  }, [code, user, isLoading, inv]);

  return (
    <View style={Style.page}>
      {!invErr ? (
        <LoadingIndicator />
      ) : (
        <Text style={Style.errMsg}>{errMsg}</Text>
      )}

    </View>
  );
}

export function CreateAccountScreenHeader() {
  const navigation = useNavigation<CreateAccountNavigationProp>();
  const onBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.getParent()?.navigate("Unauth");
    }
  };

  return <BaseHeader leftIcon="back" onBack={onBack} />;
}
