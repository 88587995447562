import { useController, FieldValues, FieldPath } from "react-hook-form";

import TextInput, { TextInputProps } from "../TextInput";
import { ControlledInputProps } from "../../types/ControlledInput";

export default function CtlTextInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  rules,
  ...rest
}: ControlledInputProps<TFieldValues, TName> & TextInputProps) {
  const {
    field,
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    name,
    control,
    rules,
  });

  return <TextInput {...field} error={error} {...rest} />;
}
