import * as React from "react";
import { ScrollView, StyleSheet } from "react-native";
import { Ionicons } from "@expo/vector-icons";

import Style from "../../constants/CreateAccountStyles";
import { Text, View } from "../../components/Themed";
import { Button } from "../../components/Button";
import LoadingIndicator from "../../components/LoadingIndicator";
import { TermsAndInfoScreenProps } from "../../navigation/types";
import useCheckInit from "../../hooks/useCheckInit";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { InvitationType } from "../../types/Invitation";
import { api, AcceptInvitationPayload } from "../../api";
import Colors from "../../constants/Colors";
import { BasicInfo } from "../../store/createAccount";
import { dataURItoBlob } from "../../util/utils";

export default function TermsAndInfoScreen({
  navigation,
}: TermsAndInfoScreenProps) {
  useCheckInit();
  const createAccount = useSelector((state: RootState) => state.createAccount);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const acceptedTerms = createAccount.acceptedTerms;
  const acceptedOther = createAccount.acceptedOther;
  const invType = createAccount.invitation?.invitationType;

  const viewTerms = () => {
    navigation.push("TermsAndConditions");
  };

  const viewOther = () => {
    navigation.push("OtherInfo");
  };

  const submit = async () => {
    try {
      setLoading(true);
      setError(false);
      const payload = new FormData();
      if ( !createAccount.basicInfo.photo ) throw new Error( 'There is no user Photo' )
      if ( !createAccount.invitation ) throw new Error( 'There is no Invitation' );
      payload.append(
        "userPhoto",
        dataURItoBlob(createAccount.basicInfo.photo.uri)
      );

      // * the process below is needed to get the user info photo out of the accept payload since
      // * it will cause an error when trying to send it's 205 thousand characters through the api.

      const basicInfo = {
        ...createAccount.basicInfo,
      } as any;
      
      delete basicInfo.photo;
      basicInfo.password = createAccount.password;
      const userInfo = basicInfo as AcceptInvitationPayload["userInfo"];
      const acceptPayload: AcceptInvitationPayload = {
        // We know it will never be undefined here because of useCheckInit.
        code: createAccount.invitation.id,
        userInfo,
      };

      if ( !invType ) throw new Error( 'There is no invitationType present' );

      if ( [InvitationType.SUBCONTRACTOR, InvitationType.PILOT_TAIL_NUMBER].includes( invType ) ) {
        if ( !createAccount.pilotInfo.resume || !createAccount.pilotInfo.certImage ) throw new Error( 'There is no resume or cert image in pilotInfo.' );
        const resumeUri = createAccount.pilotInfo.resume.uri;
        const certImageUri = createAccount.pilotInfo.certImage.uri;
        payload.append("resume", dataURItoBlob(resumeUri));
        payload.append("certImage", dataURItoBlob(certImageUri));
        acceptPayload.pilotInfo = {
          certLevel: createAccount.pilotInfo.certLevel,
          certNo: createAccount.pilotInfo.certNo,
        };
      } else if ( [InvitationType.ORGANIZATION].includes( invType ) ) {
        if ( !createAccount.organizationInfo.photo ) throw new Error( 'There is no Organization Image in organizationInfo.' );
        const orgPhotoUri = createAccount.organizationInfo.photo.uri;
        payload.append("orgPhoto", dataURItoBlob(orgPhotoUri));
        // * the process below is needed to get the organization info photo out of the accept payload since
        // * it will cause an error when trying to send it's 205 thousand characters through the api.
        const orgInfo = {
          ...createAccount.organizationInfo,
        } as any;
        orgInfo.photo = null;
        const organizationInfo = orgInfo as AcceptInvitationPayload['organizationInfo']
        acceptPayload.organizationInfo = organizationInfo;
      }

      payload.append("payload", JSON.stringify(acceptPayload));

      await api.acceptInvitation(acceptPayload.code, payload);
      navigation.navigate("Success");
    } catch (err: any) {
      setError(true);
      console.error( err );
    } finally {
      setLoading(false);
    }
  };

  return (
    <ScrollView style={Style.page} contentContainerStyle={Style.pageContent}>
      <View>
        <View style={Style.headerContainer}>
          <Text style={Style.header}>Terms and Conditions</Text>
          <Text style={Style.subheading}>
            You must click both buttons below and scroll to the bottom to click
            “I Agree” before launching Zephur.
          </Text>
        </View>

        <View style={Style.spacer} />

        <View style={styles.btnsContainer}>
          {acceptedTerms ? (
            <AcceptedButton title="Terms and Conditions" />
          ) : (
            <Button
              title="Terms and Conditions"
              color="lightBlue"
              onPress={viewTerms}
            />
          )}
          <View style={Style.spacer} />
          {acceptedOther ? (
            <AcceptedButton title="Other important info" />
          ) : (
            <Button
              title="Other important info"
              color="lightBlue"
              onPress={viewOther}
            />
          )}
        </View>
      </View>

      {loading && <LoadingIndicator />}
      {error && (
        <Text style={styles.errorText}>
          There was an error submitting your application.
        </Text>
      )}
      <Button
        title="Submit"
        onPress={submit}
        style={Style.nextBtn}
        disabled={loading || !acceptedTerms || !acceptedOther}
      />
    </ScrollView>
  );
}

const AcceptedButton = ({ title }: { title: string }) => {
  return (
    <View style={btnStyles.container}>
      <Text style={btnStyles.text}>{title}</Text>
      <Ionicons
        name="checkmark-outline"
        color={Colors.theme.success}
        size={24}
      />
    </View>
  );
};

const btnStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: "auto",
    padding: 16,
  },
  text: {
    color: Colors.theme.success,
    fontWeight: "600",
    fontSize: 16,
    marginRight: 12,
  },
});

const styles = StyleSheet.create({
  btnsContainer: {
    alignItems: "center",
  },
  errorText: {
    color: Colors.theme.danger,
    textAlign: "center",
  },
});
