import * as React from "react";
import { useQueryClient } from "react-query";

import { User } from "../types/User";
import { authService } from "../util/authService";
import { api } from "../api";
import { LoginArgs } from "../api/api";

type AuthContextValue = {
  user: User | null;
  login: ( creds: LoginArgs ) => Promise<void>;
  logout: () => void;
  forgotPassword: ( dto: ForgotPasswordData ) => Promise<void>;
  resetPassword: ( dto: NewPasswordData ) => Promise<void>;
  confirmEmail: ( dto: ConfirmEmailData ) => Promise<void>;
};

type ForgotPasswordData = {
  email: string;
};

type NewPasswordData = {
  email: string;
  password: string;
  confirmPassword: string;
};

type ConfirmEmailData = {
	email: string;
	emailOtp: string;
};

// This will never be null in the actual app.
const AuthContext = React.createContext<AuthContextValue>(
  {} as unknown as AuthContextValue
);

function AuthProvider(props: { children?: React.ReactNode }) {
  const [user, setUser] = React.useState<User | null>(null);
  const queryClient = useQueryClient();

  React.useEffect(() => {
    const sub = authService.addEventListener((user) => {
      setUser(user);
      if (!user) {
        queryClient.clear();
      }
    });
    return () => sub.remove();
  }, []);

  const login = async (creds: LoginArgs) => {
    const res = await api.login(creds);
    await authService.storeCredentials(res);
  };

  const logout = () => {
    queryClient.clear();
    return authService.clearCredentials();
  };

  const forgotPassword = async ( dto : ForgotPasswordData ) => {
    await api.forgotPassword( dto.email );
  };

  const resetPassword = async ( dto : NewPasswordData ) => {
    if ( dto.password !== dto.confirmPassword ) {
      throw new Error( "Passwords do not match" );
    }
    await api.resetPassword( dto );
  };

  const confirmEmail = async ( dto : ConfirmEmailData ) => {
    await api.confirmEmail( dto );
  };


  return <AuthContext.Provider value={{ user, login, logout, forgotPassword, resetPassword, confirmEmail }} {...props} />;
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
