import { StyleSheet, TouchableOpacity } from "react-native";

import Theme from "../constants/Theme";
import { Text, View } from "../components/Themed";
import PreviewPicture from "../components/PreviewPicture";
import { ZephurVersion } from "../components/ZephurVersion";
import { User, UserType } from "../types/User";
import LoadingIndicator from "../components/LoadingIndicator";
import { BaseHeader } from "../components/BaseHeader";
import { PilotsScreenProps } from "../navigation/types";
import ScreenContainer from "../components/ScreenContainer";
import useOrgUsers from "../hooks/useOrgUsers";
import { useMember } from "../hooks/useUser";

export default function PilotsScreen({ navigation }: PilotsScreenProps) {
  const { user } = useMember();
  const { users } = useOrgUsers(user?.organization);

  const pilots = users?.filter((m) => m.type === UserType.PILOT);

  const viewPilot = (p: User) => {
    navigation.push("ViewPilot", { id: p.id });
  };

  return (
    <ScreenContainer>
      <View>
        <Text style={styles.header}>Your Organization's Pilots</Text>
        {!pilots && <LoadingIndicator />}
        {pilots &&
          pilots.map((p) => (
            <TouchableOpacity key={p.id} onPress={() => viewPilot(p)}>
              <PreviewPicture
                url={p.photoUrl}
                title={`${p.firstName} ${p.lastName}`}
                style={{ marginBottom: 16 }}
              />
            </TouchableOpacity>
          ))}
      </View>
      <ZephurVersion />
    </ScreenContainer>
  );
}

export function PilotsScreenHeader() {
  return (
    <BaseHeader title="Pilots" leftIcon="menu" rightIcon="notifications" />
  );
}

const styles = StyleSheet.create({
  header: {
    ...Theme.h2,
    marginBottom: 16,
  },
});
