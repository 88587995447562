import { StyleSheet, TouchableOpacity, ViewStyle } from "react-native";

import Colors from "../constants/Colors";
import { Text, View } from "./Themed";

type RadioButtonProps = {
  label: string;
  selected?: boolean;
  onSelect?: (v: boolean) => void;
  style?: ViewStyle;
};

export default function RadioButton(props: RadioButtonProps) {
  const onSelect = () => {
    if (!props.onSelect) return;
    props.onSelect(!props.selected);
  };

  return (
    <TouchableOpacity onPress={onSelect}>
      <View style={StyleSheet.flatten([props.style, styles.container])}>
        <View style={styles.circleContainer}>
          {props.selected && <View style={styles.circle} />}
        </View>
        <Text style={styles.text}>{props.label}</Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  circleContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: 24,
    height: 24,
    padding: 4,
    borderRadius: 99,
    borderWidth: 3,
    borderColor: Colors.theme.primary,
  },
  circle: {
    backgroundColor: Colors.theme.primary,
    borderRadius: 99,
    width: 12,
    height: 12,
  },
  text: {
    color: Colors.theme.primary,
    fontWeight: "600",
    fontSize: 16,
    marginLeft: 8,
  },
});
