import * as React from "react";
import { useWindowDimensions } from "react-native";
import {
  TabView,
  SceneMap,
  TabBar as DefaultTabBar,
  NavigationState,
  SceneRendererProps,
} from "react-native-tab-view";
import { useNavigation } from "@react-navigation/native";

import Colors from "../constants/Colors";
import { BaseHeader } from "../components/BaseHeader";
import { useUser } from "../hooks";
import {
  HomeStackNavigationProp,
  PastTripsScreenProps,
} from "../navigation/types";
import { UserType } from "../types/User";
import { useSubcontractorTrips, useTrips, useOrgTrips } from "../hooks/trips";
import TripList from "../components/TripList";
import { Trip, TripStatus } from "../types/Trip";
import ScreenContainer from "../components/ScreenContainer";
import { getDeparture } from "../util/trips";

const validStatuses = [TripStatus.COMPLETED, TripStatus.DECLINED, TripStatus.CANCELLED];

const useViewTrip = () => {
  const navigation = useNavigation<HomeStackNavigationProp>();
  return (t: Trip) => {
    navigation.push("Trip", { tripId: t.id });
  };
};

function YourTrips() {
  const { trips: allTrips } = useTrips();
  const viewTrip = useViewTrip();

  const trips = allTrips?.filter((t) =>
    validStatuses.includes(t.status)
  ).sort( ( a, b ) =>
    new Date( getDeparture( a )?.date as string ).getTime() - new Date( getDeparture( b )?.date as string ).getTime()
  );

  return (
    <ScreenContainer>
      <TripList
        trips={trips}
        headerEmpty="No trips available"
        onPress={viewTrip}
      />
    </ScreenContainer>
  );
}

function SubcontractorTrips() {
  const { subcontractorTrips } = useSubcontractorTrips();
  const viewTrip = useViewTrip();

  const subberTrips = subcontractorTrips?.filter( ( st ) => 
  validStatuses.includes( st.status )
  ).sort( ( a, b ) => 
    new Date( getDeparture( a )?.date as string ).getTime() - new Date( getDeparture( b )?.date as string ).getTime()
  );

  return (
    <ScreenContainer>
      <TripList
        trips={subberTrips}
        headerEmpty="No trips available"
        onPress={viewTrip}
      />
    </ScreenContainer>
  );
}

function AllMembersTrips() {
  const { orgTrips } = useOrgTrips();
  const viewTrip = useViewTrip();

  // console.log( 'orgTrips', orgTrips );

  const sortOrgTrips = orgTrips?.filter( ( ot ) =>
  validStatuses.includes( ot.status )
  ).sort( ( a, b ) =>
    new Date( getDeparture( a )?.date as string ).getTime() - new Date( getDeparture( b )?.date as string ).getTime()
  );

  return (
    <ScreenContainer>
      <TripList
        trips={sortOrgTrips}
        headerEmpty="No trips available"
        onPress={viewTrip}
      />
    </ScreenContainer>
  );
}

const renderScene = SceneMap({
  your_trips: YourTrips,
  subcontractor_trips: SubcontractorTrips,
  all_members_trips: AllMembersTrips,
});

type Route = {
  key: "your_trips" | "subcontractor_trips" | "all_members_trips";
  title: string;
};

const TabBar = (
  props: SceneRendererProps & { navigationState: NavigationState<Route> }
) => {
  return (
    <DefaultTabBar
      {...props}
      style={{ backgroundColor: "#fff" }}
      labelStyle={{
        textTransform: "capitalize",
        color: Colors.theme.subtle,
        fontWeight: "600",
        fontSize: 16,
      }}
      activeColor={Colors.theme.primary}
      indicatorStyle={{ backgroundColor: Colors.theme.primary, height: 4 }}
    />
  );
};

export default function PastTripsScreen({ navigation }: PastTripsScreenProps) {
  const { user } = useUser();
  const { width } = useWindowDimensions();
  const [index, setIndex] = React.useState(0);

  let routes: Route[] = [{ key: "your_trips", title: "Your Trips" }];
  if (!user) {
    // Do nothing.
  } else if (user.type === UserType.PILOT) {
    routes = routes.concat({
      key: "subcontractor_trips",
      title: "Subcontractor Trips",
    });
  } else if (user.role !== 'general') {
    routes = routes.concat({
      key: "all_members_trips",
      title: "All Member's Trips",
    });
  }

  return (
    <TabView
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      renderTabBar={TabBar}
      initialLayout={{ width, height: 0 }}
    />
  );
}

export function PastTripsScreenHeader() {
  return (
    <BaseHeader
      title="Past Trips"
      leftIcon="menu"
      rightIcon="notifications"
      color="blue"
    />
  );
}
