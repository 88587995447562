import * as React from "react";
import { StyleSheet } from "react-native";
import {
  GooglePlaceData,
  GooglePlaceDetail,
  GooglePlacesAutocomplete,
  GooglePlacesAutocompleteRef,
} from "react-native-google-places-autocomplete";
import { FieldError } from "react-hook-form";

import Colors from "../constants/Colors";
import { Text, View } from "./Themed";
import { getAddressFromPlace } from "../util/places";
import { Location } from "../types/Trip";

export type PlacesValue = Location;

export interface GooglePlacesInputProps {
  label?: string;
  error?: FieldError | undefined;
  value?: PlacesValue;
  onChange?: (d: PlacesValue) => void;
}

export default React.forwardRef<
  GooglePlacesAutocompleteRef,
  GooglePlacesInputProps
>((props, ref): React.ReactElement => {
  const { value, onChange, label, error } = props;
  const _ref = React.useRef<GooglePlacesAutocompleteRef>(null);

  // Not entirely sure what's going on with the ref's here. I'm having
  // to do this, but I think it's working.
  if (typeof ref === "function") {
    ref(_ref.current);
  }

  React.useEffect(() => {
    if (!value) return;
    _ref?.current?.setAddressText(value.placesText);
  }, [value]);

  const onPress = (
    data: GooglePlaceData,
    details: GooglePlaceDetail | null
  ) => {
    if (!details || !onChange) return;
    const addr = getAddressFromPlace(details);
    onChange({
      placesText: _ref?.current?.getAddressText() || "",
      city: addr.city,
      state: addr.state,
      country: addr.country,
      street: addr.street,
      zip: addr.zip,
    });
  };

  return (
    <View>
      {label && (
        <View style={styles.labelContainer}>
          <Text style={styles.label}>{label}</Text>
        </View>
      )}
      <GooglePlacesAutocomplete
        ref={_ref} // TypeScript being weird here?
        placeholder="Begin typing city..."
        onPress={onPress}
        fetchDetails={true}
        query={{
          key: "AIzaSyA3nF7Wgu0cPkUsr6hSeR3j7yRh1yIj38E",
          language: "en",
          type: "(cities)",
        }}
        requestUrl={{
          useOnPlatform: "web",
          url: "https://app.flyzephur.com/gpa"
        }}
        styles={{
          textInput: {
            backgroundColor: Colors.theme.lightestBlue,
            paddingVertical: 16,
            paddingHorizontal: 24,
          },
          row: {
            backgroundColor: Colors.theme.lightestBlue,
          },
        }}
      />
      <Text style={styles.errorMsg}>{error && error.message}</Text>
    </View>
  );
});

const styles = StyleSheet.create({
  labelContainer: {
    flexDirection: "row",
    paddingLeft: 24,
  },
  label: {
    fontWeight: "bold",
    marginBottom: 4,
  },
  errorMsg: {
    color: Colors.theme.danger,
  },
});
