import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";
import Toast from "react-native-toast-message";

import useCachedResources from "./hooks/useCachedResources";
import useColorScheme from "./hooks/useColorScheme";
import SplashScreen from "./screens/SplashScreen";
import Navigation from "./navigation";
import { AppProviders } from "./providers";
import { useUser } from "./hooks";

function LoadingApp() {

  //* User must be loaded here to stay signed in on refresh
  const { user } = useUser();
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  if (!isLoadingComplete) {
    return <SplashScreen />;
  } else {
    return (
    	<SafeAreaProvider>
          <Navigation colorScheme={colorScheme}/>
          <StatusBar />
          <Toast />
      </SafeAreaProvider>
    );
  }
}

export default function App() {
  return (
    <AppProviders>
      <LoadingApp />
    </AppProviders>
  );
}
