import { StyleSheet } from "react-native";
import { Controller, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import Style from "../../constants/CreateAccountStyles";
import { View, Text } from "../../components/Themed";
import { Button } from "../../components/Button";
import { SetPasswordScreenProps } from "../../navigation/types";
import useCheckInit from "../../hooks/useCheckInit";
import { RootState } from "../../store";
import { setPassword } from "../../store/createAccount";
import { InvitationType } from "../../types/Invitation";
import TextInput from "../../components/TextInput";

export default function SetPasswordScreen({
  navigation,
}: SetPasswordScreenProps) {
  useCheckInit();
  const dispatch = useDispatch();
  const password = useSelector(
    (state: RootState) => state.createAccount.password
  );
  const invType = useSelector(
    (state: RootState) => state.createAccount.invitation?.invitationType
  );
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: { password, confirmPassword: password },
  });

  const isMakingPilot = [
    InvitationType.SUBCONTRACTOR,
    InvitationType.PILOT_TAIL_NUMBER,
  ].includes(invType as InvitationType);

  const nextBtnText = isMakingPilot ? "Add Pilot Info" : "Terms and Conditions";

  const submit = ({
    password,
    confirmPassword,
  }: {
    password: string;
    confirmPassword: string;
  }) => {
    if (password !== confirmPassword) {
      setError("confirmPassword", {
        message: "This must be equal to password.",
      });
      return;
    }
    if (password.length > 70) {
      setError("confirmPassword", {
        message: "Password must be between 8 and 70 characters."
      })
      return
    }
    if (password.length < 8) {
      setError("confirmPassword", {
        message: "Password must be between 8 and 70 characters."
      })
      return
    }
    dispatch(setPassword(password));
    if (isMakingPilot) {
      navigation.push("PilotInformation");
    } else {
      navigation.push("TermsAndInfo");
    }
  };

  return (
    <KeyboardAwareScrollView
      style={Style.page}
      contentContainerStyle={Style.pageContent}
    >
      <View>
        <View style={Style.headerContainer}>
          <Text style={Style.header}>Set Account Password</Text>
          <Text style={Style.subheading}>
            This will be the password used to log in to your account. Make sure
            to remember it!
          </Text>
        </View>
        <View style={Style.spacer} />
        <Controller
          name="password"
          control={control}
          rules={{
            required: { value: true, message: "This field is required." },
          }}
          render={({ field }) => (
            <TextInput
              label="Password"
              textContentType="password"
              secureTextEntry={true}
              {...field}
              error={errors.password}
            />
          )}
        />
        <View style={Style.spacer} />
        <Controller
          name="confirmPassword"
          control={control}
          rules={{
            required: { value: true, message: "This field is required." },
          }}
          render={({ field }) => (
            <TextInput
              label="Confirm Password"
              textContentType="password"
              secureTextEntry={true}
              {...field}
              error={errors.confirmPassword}
            />
          )}
        />
      </View>
      <View>
        <Button
          title={nextBtnText}
          onPress={handleSubmit(submit)}
          style={Style.nextBtn}
        />
        <View style={Style.spacer} />
      </View>
    </KeyboardAwareScrollView>
  );
}
