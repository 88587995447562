import * as React from "react";
import { useNavigation } from "@react-navigation/native";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";

import { BaseHeader } from "../components/BaseHeader";
import { useTrip, useUser } from "../hooks";
import { TripScreenProps, HomeStackNavigationProp } from "../navigation/types";
import TripChangeForm, { OnSaveHandler } from "../components/TripChangeForm";
import LoadingIndicator from "../components/LoadingIndicator";
import { useTripPilots } from "../hooks/trips";
import { useMutation, useQueryClient } from "react-query";
import { api } from "../api";
import { ChangeRequest } from "../types/Trip";

export default function EditTripScreen({ navigation, route }: TripScreenProps) {
  const { user } = useUser();
  const { tripId } = route.params;
  const { trip, isLoading: tripLoading } = useTrip(tripId);
  const { tripPilots, isLoading: tripPilotsLoading } = useTripPilots(tripId);
  const queryClient = useQueryClient();
  const changeReqMutation = useMutation(
    (d: ChangeRequest) => api.makeChangeRequest(tripId, d),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["trips", tripId]);
        queryClient.invalidateQueries( ['notifs', user?.id] );
      },
    }
  );
  const [errMsg, setErrMsg] = React.useState("");

  const isLoading = tripLoading || tripPilotsLoading;

  const goBack = () => {
    navigation.navigate("Trip", { tripId });
  };

  const onSave: OnSaveHandler = async (d, { isDirty, changedFields }) => {
    if (!isDirty) {
      // If not dirty, then just navigate back to the trip screen.
      return goBack();
    }

    // console.log("onSave test", isDirty, changedFields);
    try {
      setErrMsg("");
      await changeReqMutation.mutateAsync(changedFields);
      goBack();
    } catch (err) {
      setErrMsg("There was a problem saving your edits.");
    }
  };

  const onCancel = () => {
    navigation.push( "ConfirmCancelTrip", { tripId } );
  }

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {trip && tripPilots && (
        <TripChangeForm
          trip={trip}
          tripPilots={tripPilots}
          saveBtnLabel="Save Edits"
          cancelBtnLabel="Cancel Trip"
          onSave={onSave}
          onCancel={onCancel}
          errMsg={errMsg}
        />
      )}
    </>
  );
}

export function EditTripScreenHeader({ route }: NativeStackHeaderProps) {
  // No way to type-infer header props.
  const { tripId } = route.params as any;
  const navigation = useNavigation<HomeStackNavigationProp>();

  const onBack = () => {
    if (navigation.canGoBack()) navigation.goBack();
    else navigation.navigate("Trip", { tripId });
  };

  return (
    <BaseHeader
      title="Edit"
      leftIcon="back"
      rightIcon="none"
      color="blue"
      onBack={onBack}
    />
  );
}
