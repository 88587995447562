import * as React from "react";
import { FieldError } from "react-hook-form";
import MaskInput, { Masks } from "react-native-mask-input";
import {
  View,
  Text,
  TextInputProps as DefaultTextInputProps,
  StyleSheet,
  ViewStyle,
} from "react-native";

import Colors from "../constants/Colors";

export interface TextInputProps extends DefaultTextInputProps {
  name?: string;
  label?: string;
  optional?: boolean;
  containerStyle?: ViewStyle;
  centerText?: boolean;
  error?: FieldError | undefined;
  mask?: "MMDDYYYY" | "numeric" | "decimal-pad";
}

export default React.forwardRef<any, TextInputProps>(
  (props, ref): React.ReactElement => {
    const {
      value ="",
      label,
      optional,
      containerStyle,
      centerText,
      error,
      mask,
      ...textInputProps
    } = props;

    // Adjust text centering/labeling depending on props.centerText
    const textAlign: {
      justifyContent: "center" | "flex-start";
      textAlign: "center" | "left";
      paddingLeft?: number;
    } = {
      justifyContent: centerText ? "center" : "flex-start",
      textAlign: centerText ? "center" : "left",
    };
    if (!centerText) textAlign.paddingLeft = 24;

    const maskOpts = (function () {
      switch (mask) {
        case "MMDDYYYY":
          return {
            mask: Masks.DATE_MMDDYYYY,
            placeholder: "MM/DD/YYYY",
          };
        case "numeric":
          return {
            mask: new Array(16).fill(/[0-9]/),
            placeholder: "123",
          };
        case "decimal-pad":
          return {
            mask: new Array(16).fill(/[0-9]/),
            placeholder: "123",
          }
      }
    })();

    return (
      <View style={containerStyle}>
        {label && (
          <View
            style={StyleSheet.flatten([inputStyles.labelContainer, textAlign])}
          >
            <Text style={inputStyles.label}>{label}</Text>
            {optional && <Text style={inputStyles.optionalText}>Optional</Text>}
          </View>
        )}
        <MaskInput
          ref={ref}
          value={value}
          autoCapitalize="none"
          style={StyleSheet.flatten([inputStyles.input, textAlign])}
          placeholderTextColor="rgba(43, 48, 52, 0.3)"
          {...maskOpts}
          {...textInputProps}
        />
        <Text style={inputStyles.errorMsg}>{error && error.message}</Text>
      </View>
    );
  }
);

const inputStyles = StyleSheet.create({
  labelContainer: {
    flexDirection: "row",
  },
  label: {
    fontWeight: "bold",
    marginBottom: 4,
  },
  optionalText: {
    marginLeft: 8,
    fontStyle: "italic",
    color: Colors.theme.gold,
  },
  input: {
    backgroundColor: Colors.theme.lightestBlue,
    borderRadius: 5,
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  errorMsg: {
    color: Colors.theme.danger,
  },
});
