import * as React from "react";
import { StyleSheet } from "react-native";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";

import Theme from "../../constants/Theme";
import Colors from "../../constants/Colors";
import { api } from "../../api";
import { required, isEmail } from "../../util/formRules";
import { BaseHeader } from "../../components/BaseHeader";
import { View, Text } from "../../components/Themed";
import CtlTextInput from "../../components/ControlledInputs/CtlTextInput";
import CtlSelectInput from "../../components/ControlledInputs/CtlSelectInput";
import { Button } from "../../components/Button";
import LoadingIndicator from "../../components/LoadingIndicator";
import { EditAircraftScreenProps } from "../../navigation/types";
import { EditableAircraft } from "../../types/Aircraft";
import useOrgUsers from "../../hooks/useOrgUsers";
import { UserType } from "../../types/User";
import { aircraftToEditable } from "../../util/aircraft";
import ScreenContainer from "../../components/ScreenContainer";
import { useMember } from "../../hooks/useUser";
import { MemberRole } from "../../types/User";

export default function EditAircraftScreen({
  route,
  navigation,
}: EditAircraftScreenProps) {
  const { id: aircraftId } = route.params;
  const { user } = useMember();
  const { users: members } = useOrgUsers(user?.organization);
  const queryClient = useQueryClient();
  const editMutation = useMutation(
    (d: EditableAircraft) =>
      api.editAircraft(aircraftId, { ...d, dayRate: parseInt(d.dayRate) }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["aircraft", aircraftId]);
      },
    }
  );
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<EditableAircraft>();
  const [errMsg, setErrMsg] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  //! RBAC
  React.useEffect( () => {
    if ( !user?.role ) return;
    if( !( [MemberRole.SUPERADMIN, MemberRole.ADMIN].includes( user?.role ) ) ) {
      //* Replace screen on stack to prevent returning to this screen on back button
      navigation.replace( 'Aircraft' );
    }
  }, [] )
  //!

  const pilotItems =
    members &&
    [{ label: "No Pilot", value: "" }].concat(
      members
        ?.filter((m) => m.type === UserType.PILOT)
        .sort((m1, m2) => m1.firstName.localeCompare(m2.firstName))
        .map((p) => ({
          label: `${p.firstName} ${p.lastName}`,
          value: p.id || "",
        }))
    );

  React.useEffect(() => {
    async function initAircraft() {
      try {
        const a = await api.getAircraft(aircraftId);
        reset(aircraftToEditable(a));
      } catch (err) {
        setErrMsg("There was an error getting this aircraft");
      } finally {
        setLoading(false);
      }
    }
    initAircraft();
  }, []);

  const goBack = () => {
    navigation.navigate("Aircraft");
  };

  const onSubmit = async (d: EditableAircraft) => {
    if (!isDirty) {
      goBack();
    }
    try {
      setErrMsg("");
      await editMutation.mutateAsync(d);
      goBack();
    } catch (err) {
      setErrMsg("There was an error editing this aircraft.");
    }
  };

  return (
    <ScreenContainer>
      <View>
        <CtlTextInput
          name="dayRate"
          control={control}
          rules={required}
          label="Day Rate (USD)"
          keyboardType="numeric"
          mask="numeric"
          placeholder="$700"
        />
        <View style={styles.spacer} />
        <CtlTextInput
          name="homeAirportCode"
          control={control}
          rules={required}
          label="Home Airport"
        />
        <View style={styles.spacer} />
        <CtlTextInput
          name="currentAirportCode"
          control={control}
          rules={required}
          label="Current Airport"
        />
        <View style={styles.spacer} />
        {!pilotItems && <LoadingIndicator />}
        {pilotItems && (
          <CtlSelectInput
            name="pilotId"
            control={control}
            label="Pilot"
            items={pilotItems}
          />
        )}
        <Text style={styles.fancyOr}>OR</Text>
        <Text style={styles.enterEmailText}>
          Enter the pilot's email below.
        </Text>
        <View style={styles.spacer} />
        <CtlTextInput
          name="inviteeEmail"
          control={control}
          rules={isEmail}
          label="Email"
        />
        <Text style={styles.errorMsg}>{errMsg}</Text>
      </View>
      <Button
        title="Save Edits"
        style={styles.addBtn}
        variant="large"
        onPress={handleSubmit(onSubmit)}
        disabled={editMutation.isLoading}
      />
      <View style={styles.spacer} />
    </ScreenContainer>
  );
}

export function EditAircraftScreenHeader({ route }: NativeStackHeaderProps) {
  const { id: aircraftId } = route.params as any;
  const { data: aircraft } = useQuery(["aircraft", aircraftId], () =>
    api.getAircraft(aircraftId)
  );
  const [title, setTitle] = React.useState("Edit");

  React.useEffect(() => {
    if (aircraft) {
      setTitle(`Edit #${aircraft.tailNumber}`);
    }
  }, [aircraft]);

  return <BaseHeader leftIcon="back" title={title} />;
}

const styles = StyleSheet.create({
  spacer: {
    ...Theme.spacer,
  },
  header: {
    ...Theme.h1,
    textAlign: "center",
  },
  subheader: {
    maxWidth: 240,
    marginHorizontal: "auto",
    textAlign: "center",
    marginTop: 8,
  },
  fancyOr: {
    color: Colors.theme.gold,
    fontSize: 24,
    fontWeight: "600",
    textTransform: "uppercase",
    textAlign: "center",
    marginTop: 24,
  },
  enterEmailText: {
    fontWeight: "600",
    fontSize: 16,
    textAlign: "center",
    marginTop: 24,
  },
  errorMsg: {
    ...Theme.errorMessage,
    marginTop: 24,
    marginBottom: 12,
  },
  addBtn: {
    marginTop: "auto",
    alignSelf: "center",
  },
});
